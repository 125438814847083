import { Grid, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import useDebounce from "../../../hooks/useDebounce"
import { CityRiddlerNumberField } from "../../../themes/cityRiddlerNumberField"
import { CityRiddlerFormLabel } from "../../../themes"
import { TooltipIcon } from "../info/infoIcon"

export interface TextInputTheme {}

interface NumberInputProperties {
  id: string
  error: boolean
  handleChange: any
  value: number | null
  min?: number
  max?: number
  required?: boolean
  label: string
  infoText: string
  index?: any
  rows?: any
}

export const NumberInput = (props: NumberInputProperties) => {
  const { error, handleChange, value, min, max, label, infoText, id, required, index, rows } = props
  const [numVal, setNumVal] = useState<number | null>(value)
  const debouncedNumVal = useDebounce(numVal, 500)

  const onChange = (event: any) => {
    setNumVal(event.target.value)
  }

  useEffect(() => {
    if (index !== null || index !== undefined) {
      handleChange(numVal, index)
    } else {
      handleChange(numVal)
    }
  }, [debouncedNumVal])

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <CityRiddlerFormLabel required={required} focused={false} sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ display: "flex", alignItems: "center" }}>
            <TooltipIcon text={infoText} />
            {label}
          </Typography>
        </CityRiddlerFormLabel>
      </Grid>
      <Grid item xs={12}>
        <CityRiddlerNumberField
          id={id}
          error={error}
          onChange={onChange}
          value={numVal}
          rows={rows}
          type="number"
          InputProps={{
            inputProps: {
              max: min,
              min: max,
            },
          }}
          size="small"
        />
      </Grid>
    </Grid>
  )
}
