import { Divider, styled } from "@mui/material"
import { Colors } from "../colors"

export const CitiyRiddlerDivider = styled(Divider, {
  name: "CitiyRiddlerDivider",
})({
  background: Colors.BackgroundLight,
  height: "0.3vw",
  borderRadius: "20px",
})
