import { Button } from "@mui/material"
import { styled } from "@mui/system"
import { Colors } from "../colors"

export const CityRiddlerSubmitButton = styled(Button, {
  name: "CityRiddlerSubmitButton",
})({
  background: Colors.Primary,
  "&:hover": {
    background: Colors.PrimaryDark,
  },
})
