import { initializeApp } from "firebase/app"

import {
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
} from "firebase/auth"

import { doc, getFirestore, setDoc } from "firebase/firestore"

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)

const logInWithEmailAndPassword = async (email: any, password: any) => {
  try {
    const response = await signInWithEmailAndPassword(auth, email, password)
    const token = await response.user.getIdToken()

    return { response, token }
  } catch (error: any) {
    return error.message
  }
}

interface registrationData {
  firstName: string
  lastName: string
  email: string
  city: string
  phone: string
  password: string
  confirmPassword: string
  userAgreement: boolean
  newsletter: boolean
}

const registerWithEmailAndPassword = async (userData: registrationData): Promise<any> => {
  try {
    const response = await createUserWithEmailAndPassword(auth, userData.email, userData.password)
    const user = response.user
    const result = await setDoc(doc(db, "users", user.uid), {
      firstName: userData.firstName,
      lastName: userData.lastName,
      authProvider: "local",
      email: userData.email,
      city: userData.city,
      phone: userData.phone,
      userAgreement: userData.userAgreement,
      newsletter: userData.newsletter,
      admin: false,
    })
    await updateProfile(user, {
      displayName: userData.firstName + " " + userData.lastName,
    })

    const token = await user.getIdToken()
    return { response, token }
  } catch (error: any) {
    return error.message
  }
}

const sendPasswordReset = async (email: any) => {
  try {
    await sendPasswordResetEmail(auth, email)
    alert("Password reset link sent!")
  } catch (err) {
    console.error(err)
  }
}

const logout = () => {
  signOut(auth)
}

export { app, auth, db, logInWithEmailAndPassword, registerWithEmailAndPassword, sendPasswordReset, logout }
