import { InputLabel } from "@mui/material"
import { styled } from "@mui/system"
import { Colors } from "../colors"

export const CityRiddlerFormInputLabel = styled(InputLabel, {
  name: "CityRiddlerFormInputLabel",
})({
  color: Colors.Black,
  fontSize: "20px",
  fontWeight: "bold",
})
