import { Card, CardContent, FormControlLabelProps, Typography, useRadioGroup } from "@mui/material"
import { Colors } from "../../../../app/colors"

interface RiddleTypeSelectorFieldProperties extends FormControlLabelProps {
  value: string
}

export const RiddleTypeSelectorField = (props: RiddleTypeSelectorFieldProperties) => {
  const { value, label } = props
  const radioGroup = useRadioGroup()

  const onClick = (event: any) => {
    if (radioGroup) {
      event.target.value = value
      radioGroup.onChange(event, value)
    }
  }

  return (
    <Card
      onClick={onClick}
      sx={{
        backgroundColor: value === radioGroup?.value ? Colors.SecondaryLight : Colors.BackgroundLight,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderStyle: "solid",
        borderColor: value === radioGroup?.value ? Colors.PrimaryDark : Colors.Grey,
        borderRadius: "20px",
        height: "6vw",
        maxWidth: "75%",
        "&:hover": {
          cursor: "pointer",
        },
      }}
      elevation={0}>
      <CardContent
        sx={{
          textAlign: "center",
        }}>
        <Typography variant="h4" sx={{ fontSize: "1.5vw" }}>
          {label}
        </Typography>
      </CardContent>
    </Card>
  )
}
