export enum Colors {
  Primary = "#10A8AB",
  PrimaryLight = "#5ddadd",
  PrimaryDark = "#00787c",
  TiffanyBlue = "#BCECE0",
  BackgroundGreen = "#EFFFFF",
  BackgroundLight = "#F9FFFF",
  Secondary = "#FDA740",
  SecondaryLight = "#ffd970",
  SecondaryDark = "#c57804",
  Black = "#000000",
  Grey = "#B0B0B0",
  Red = "#d32f2f",
}
