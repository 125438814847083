import { createEntityAdapter, createSlice } from "@reduxjs/toolkit"

export interface RiddlesData {
  id: string
  riddleName: string
  city: string
  players: number
  rating: number
  income: string
  status: 0 | 1
}

const riddlesAdapter = createEntityAdapter<RiddlesData>()

const preloadState = riddlesAdapter.getInitialState()

const riddlesSlice = createSlice({
  name: "riddles",
  initialState: preloadState,
  reducers: {},
})

export default riddlesSlice.reducer
