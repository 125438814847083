import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { SelectFlagNote, SelectIsError, setFlagNote } from "../riddleFormSlice"
import { RiddleFormText } from "../../../i18"
import { TextInput } from "../../../app/utils/components/input/textInput"

export const RiddleFlagNote = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isFormError = useAppSelector(SelectIsError)
  const flagNote = useAppSelector(SelectFlagNote)
  const isError = isFormError

  const onChange = (text: string): void => {
    dispatch(setFlagNote(text))
  }

  return (
    <>
      <TextInput
        id="joinCode"
        required={false}
        error={isError}
        label={`${t(RiddleFormText.flagNote)}`}
        infoText={`${t(RiddleFormText.flagNoteInfo)}`}
        handleChange={onChange}
        multiline={false}
        value={flagNote}
      />
    </>
  )
}
