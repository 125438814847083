import { Card, CardContent, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Colors } from "../../../../app/colors"
import { CitiyRiddlerDivider } from "../../../../app/themes"
import { ProfileText } from "../../../../i18"

interface CreatorSettingsProperties {
  showOptions: boolean
  setShowOptions: any
}

export const CreatorSettings = (props: CreatorSettingsProperties) => {
  const { t } = useTranslation()
  const { showOptions, setShowOptions } = props
  const navigate = useNavigate()

  const handleClick = () => {
    setShowOptions(!showOptions)
  }

  const goToStatiscsPage = () => {
    navigate(`/profile/riddlestatistics`)
  }

  return (
    <Card
      sx={{
        backgroundColor: Colors.BackgroundLight,
        width: "40vw",
        height: "12vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "20px",
        marginLeft: "10vw",
        marginTop: !showOptions ? "" : "10vw",
      }}
      elevation={1}>
      <CardContent
        sx={{
          textAlign: "left",
          width: "100%",
        }}>
        <Typography
          onClick={handleClick}
          sx={{
            cursor: "pointer",
            color: "black",
            textDecoration: "none",
            "&:hover": { textDecoration: "underline" },
            fontSize: "3vw",
          }}
          display="inline">
          {t(ProfileText.userData)}
        </Typography>
        <CitiyRiddlerDivider sx={{ background: Colors.SecondaryLight }} />
        <Typography
          onClick={goToStatiscsPage}
          sx={{
            cursor: "pointer",
            color: "black",
            textDecoration: "none",
            "&:hover": { textDecoration: "underline" },
            fontSize: "3vw",
          }}
          display="inline">
          {t(ProfileText.statistics)}
        </Typography>
      </CardContent>
    </Card>
  )
}
