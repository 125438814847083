import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { SelectIntroVideo, setIntroVideo } from "../experienceFormSlice"
import { SelectIsError } from "../../riddleForm/riddleFormSlice"
import { ExperienceFormText } from "../../../i18"
import { TextInput } from "../../../app/utils/components/input/textInput"

export const ExperienceIntroVideo = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isFormError = useAppSelector(SelectIsError)
  const introVideoUrl = useAppSelector(SelectIntroVideo)
  const isError = isFormError && !introVideoUrl

  const onChange = (text: string): void => {
    dispatch(setIntroVideo(text))
  }

  return (
    <>
      <TextInput
        id="introVideo"
        required={false}
        error={isError}
        label={`${t(ExperienceFormText.experienceIntroVideo)}`}
        infoText={`${t(ExperienceFormText.experienceIntroVideoInfo)}`}
        handleChange={onChange}
        multiline={false}
        value={introVideoUrl ?? ""}
      />
    </>
  )
}
