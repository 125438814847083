import { FormControl, Grid, Typography } from "@mui/material"
import { CityRiddlerFormLabel } from "../../../themes"
import { CheckboxInput } from "./checkboxInput"
import { TooltipIcon } from "../info/infoIcon"
import React, { ChangeEvent } from "react"

export type MultiselectOption = {
  id: string
  name: string
}

interface MultiselectCheckboxProperties {
  label: string
  infoText: string
  options: MultiselectOption[]
  selected: string[]
  space: number
  gridSpacing: number
  handleChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void
  error: boolean
}

export const MultiselectCheckbox = (props: MultiselectCheckboxProperties) => {
  const { label, options, selected, space, gridSpacing, handleChange, error } = props
  return (
    <FormControl error={error} sx={{ minWidth: "100%" }}>
      <Grid item xs={12}>
        <CityRiddlerFormLabel required focused={false} sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ display: "flex", alignItems: "center" }}>
            <TooltipIcon text={props.infoText} />
            {label}
          </Typography>
        </CityRiddlerFormLabel>
      </Grid>
      <Grid container item xs={12} spacing={gridSpacing}>
        {options.map((option) => {
          return (
            <Grid key={option.name} item xs={space}>
              <CheckboxInput
                name={`${option.id}-${option.name}`}
                helperTexts={[{ text: option.name, type: "text" }]}
                checked={selected.includes(option.id)}
                required={false}
                handleChange={handleChange}
              />
            </Grid>
          )
        })}
      </Grid>
    </FormControl>
  )
}
