import { CircularProgress } from "@mui/material"
import _ from "lodash"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { MultiselectCheckbox, MultiselectOption } from "../../../app/utils/components"
import { RiddleFormText } from "../../../i18"
import { SelectCategories, SelectIsError, setCategories } from "../riddleFormSlice"
import { Category, useGetAllCategoriesQuery } from "../../api/cityriddlerApi"

export const RiddleCategories = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isFormError = useAppSelector(SelectIsError)
  const { data, isLoading } = useGetAllCategoriesQuery({})
  const categories = useAppSelector(SelectCategories)
  const categoryIds = categories.map((category) => "" + category.id)
  const isError = isFormError && categoryIds.length === 0

  const handleChange = (event: any) => {
    const categoryId = parseInt(event.target.name.split("-")[0])
    const category = (data?.content ?? []).filter((c: Category) => c.id === categoryId)[0]
    let filteredCategories = _.filter(categories, (element) => element.id !== category.id)

    if (filteredCategories.length === categories.length) {
      filteredCategories.push(category)
    }

    dispatch(setCategories(filteredCategories))
  }

  const categoryOptions =
    data?.content?.map((c) => {
      return {
        id: "" + c.id,
        name: c.name,
      } as MultiselectOption
    }) ?? []

  if (isLoading) {
    return <CircularProgress />
  } else {
    return (
      <MultiselectCheckbox
        label={t(RiddleFormText.categories)}
        infoText={t(RiddleFormText.categoriesInfo)}
        options={categoryOptions}
        selected={categoryIds}
        space={3}
        gridSpacing={2}
        handleChange={handleChange}
        error={isError}
      />
    )
  }
}
