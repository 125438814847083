import { Grid } from "@mui/material"
import { AuthCredential, EmailAuthProvider, reauthenticateWithCredential, updatePassword } from "firebase/auth"
import { SyntheticEvent, useContext, useState } from "react"
import { CityRiddlerFormLabel, CityRiddlerSubmitButton, CityRiddlerTextField } from "../../../../../../app/themes"
import { AuthContext } from "../../../../../../firebase/authProvider"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import { useTranslation } from "react-i18next"
import { ProfileText } from "../../../../../../i18"

export const Password = () => {
  const { t } = useTranslation()
  const { user } = useContext(AuthContext)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [password, setPassword] = useState<string>("")
  const [oldpassword, setOldPassword] = useState<string>("")

  const handleClick = () => {
    setIsEdit(!isEdit)
  }

  const handleChange = (event: any) => {
    setPassword(event.target.value)
  }

  const handleOldPasswordChange = (event: any) => {
    setOldPassword(event.target.value)
  }

  const credential: AuthCredential = EmailAuthProvider.credential(user?.email ?? "", oldpassword)

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault()
    handleClick()
    if (user) {
      reauthenticateWithCredential(user, credential)
        .then(async () => {
          await updatePassword(user, password)
        })
        .catch((error) => {
          // An error ocurred
          // ...
        })
    }
  }

  const handleReset = (event: SyntheticEvent) => {
    handleClick()
    setPassword("")
  }

  return (
    <Grid item container component="form" onSubmit={handleSubmit} onReset={handleReset} spacing={1}>
      <Grid item xs={12}>
        <CityRiddlerFormLabel>{t(ProfileText.password)}</CityRiddlerFormLabel>
      </Grid>
      <Grid item xs={8}>
        <CityRiddlerTextField
          placeholder={"password"}
          value={password}
          onChange={handleChange}
          size="small"
          disabled={!isEdit}
          type="password"
        />
      </Grid>
      {isEdit ? (
        <>
          <Grid item xs={2}>
            <CityRiddlerSubmitButton type="submit" variant="contained">
              {t(ProfileText.edit)}
            </CityRiddlerSubmitButton>
          </Grid>
          <Grid item xs={2}>
            <CityRiddlerSubmitButton type="reset" variant="contained">
              {t(ProfileText.cancel)}
            </CityRiddlerSubmitButton>
          </Grid>
          <Grid item xs={12}>
            <CityRiddlerFormLabel>Old Password:</CityRiddlerFormLabel>
          </Grid>
          <Grid item xs={8}>
            <CityRiddlerTextField
              placeholder={"password"}
              value={oldpassword}
              onChange={handleOldPasswordChange}
              size="small"
              disabled={!isEdit}
              type="password"
            />
          </Grid>
        </>
      ) : (
        <Grid item xs={4}>
          <EditOutlinedIcon
            onClick={handleClick}
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
              height: "2vw",
              width: "2vw",
              zIndex: "100",
            }}
          />
        </Grid>
      )}
    </Grid>
  )
}
