import { array, object, TypeOf, z } from "zod"
import { FormProvider, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { FileUpload } from "./fileUpload"
import { useAppDispatch, useAppSelector } from "../../../hooks"
import { RootState } from "../../../store"

const imageUploadSchema = object({
  imageCover: z.instanceof(File),
  images: array(z.instanceof(File)),
})

type IUploadImage = TypeOf<typeof imageUploadSchema>

export interface FileInputProps {
  fileSelector: (state: RootState) => boolean
  errorSelector: (state: RootState) => boolean
  upload: (payload: File) => { payload: File; type: string }
  urlSelector: (state: RootState) => string | null
  label: string
  infoText: string
  required: boolean
}

export const FileInput = (props: FileInputProps) => {
  const methods = useForm<IUploadImage>({
    resolver: zodResolver(imageUploadSchema),
  })
  const dispatch = useAppDispatch()
  const isFormError = useAppSelector(props.errorSelector)
  const isFileAvailable = useAppSelector(props.fileSelector)
  const isError = isFormError && isFileAvailable

  const handleChange = (event: any) => {
    dispatch(props.upload(event.target.value))
  }

  return (
    <FormProvider {...methods}>
      <FileUpload
        limit={1}
        multiple={false}
        name={"image"}
        handleChange={handleChange}
        error={isError}
        urlSelector={props.urlSelector}
        label={props.label}
        infoText={props.infoText}
        required={props.required}
      />
    </FormProvider>
  )
}
