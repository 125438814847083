import { MailOutline, QuestionMark } from "@mui/icons-material"
import { Grid, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { Button } from "@react-email/button"
import logo from "../../../../images/logo_petrol.png"
import { Colors } from "../../../colors"

export const DesktopFooter = () => {
  const navigate = useNavigate()
  return (
    <Grid
      container
      alignItems="stretch"
      sx={{
        marginTop: "5%",
        maxWidth: "95%",
        marginLeft: "auto",
        marginRight: "auto",
      }}
      display={{ xs: "none", md: "flex" }}>
      <Grid container item md={6}>
        <Grid
          container
          item
          sm={12}
          spacing={1}
          alignContent="center"
          sx={{ "&:hover": { cursor: "pointer" } }}
          onClick={() => {
            navigate("/help")
          }}>
          <Grid item>
            <QuestionMark />
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">Hilfe</Typography>
          </Grid>
        </Grid>
        <Grid container item sm={12} spacing={1} alignContent="center" sx={{ "&:hover": { cursor: "pointer" } }}>
          <Button href="mailto:contact@cityriddler.com" style={{ color: Colors.Black }}>
            <Grid item container sm={12} spacing={2} alignContent="center">
              <Grid item>
                <MailOutline />
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">Kontakt</Typography>
              </Grid>
            </Grid>
          </Button>
        </Grid>
      </Grid>
      <Grid item md={6} alignContent="center" textAlign="right">
        <img src={logo} alt="Logo" />
      </Grid>
    </Grid>
  )
}
