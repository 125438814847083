import { DesktopFooter } from "./desktopFooter"
import { MobileFooter } from "./mobileFooter"

export const Footer = () => {
  return (
    <>
      <DesktopFooter />
      <MobileFooter />
    </>
  )
}
