import { useTranslation } from "react-i18next"
import { SelectFinishImageUrl, SelectIsFinishImageFileAvailable, setFinishImageFile } from "../experienceFormSlice"
import { SelectIsError } from "../../riddleForm/riddleFormSlice"
import { ExperienceFormText } from "../../../i18"
import { FileInput } from "../../riddleForm/formComponents"

export const ExperienceFinishImage = () => {
  const { t } = useTranslation()

  return (
    <FileInput
      errorSelector={SelectIsError}
      fileSelector={SelectIsFinishImageFileAvailable}
      upload={setFinishImageFile}
      urlSelector={SelectFinishImageUrl}
      label={t(ExperienceFormText.experienceFinishImage)}
      infoText={t(ExperienceFormText.experienceFinishImageInfo)}
      required={false}
    />
  )
}
