import { createEntityAdapter, createSlice } from "@reduxjs/toolkit"

export interface ExperienceData {
  id: string
  riddleName: string
  city: string
  players: number
  rating: number
  income: string
  status: 0 | 1
}

const riddlesAdapter = createEntityAdapter<ExperienceData>()

const preloadState = riddlesAdapter.getInitialState()

const experiencesSlice = createSlice({
  name: "experiences",
  initialState: preloadState,
  reducers: {},
})

export default experiencesSlice.reducer
