import { MenuItem } from "@mui/material"
import { useTranslation } from "react-i18next"
import { CityRiddlerTextSelectField } from "../../../../app/themes/cityRiddlerSelectField"
import { ProfileText } from "../../../../i18"

interface RiddleSelectorProps {
  selected: number
  options: { id: string; name: string }[]
  riddleStateSetter: any
}

export const RiddleSelector = (props: RiddleSelectorProps) => {
  const { t } = useTranslation()
  const { selected, options, riddleStateSetter } = props
  const handleChange = (event: any) => {
    riddleStateSetter(event.target.value)
  }
  return (
    <CityRiddlerTextSelectField
      value={selected}
      onChange={handleChange}
      sx={{
        minWidth: "90%",
      }}>
      <MenuItem value={-1}>{t(ProfileText.allRiddles)}</MenuItem>
      {options.map((option) => {
        return (
          <MenuItem value={option.id} key={`${option.name} ${option.id}`}>
            {option.name}
          </MenuItem>
        )
      })}
    </CityRiddlerTextSelectField>
  )
}
