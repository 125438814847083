import { Grid, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import useDebounce from "../../../hooks/useDebounce"
import { CityRiddlerFormLabel, CityRiddlerTextField } from "../../../themes"
import { TooltipIcon } from "../info/infoIcon"

export interface TextInputTheme {}

interface TextInputProperties {
  id: string
  error: boolean
  handleChange: any
  value: string
  multiline: boolean
  required: boolean
  label?: string
  index?: any
  rows?: any
  infoText: string
}

export const TextInput = (props: TextInputProperties) => {
  const { error, handleChange, value, multiline, label, id, required, index, rows } = props
  const [text, setText] = useState<string>(value ? value : "")
  const debouncedText = useDebounce(text, 500)

  const onChange = (event: any) => {
    setText(event.target.value)
  }

  useEffect(() => {
    if (index !== null) {
      handleChange(text, index)
    } else {
      handleChange(text)
    }
  }, [debouncedText])

  return (
    <>
      {label && (
        <Grid item xs={12}>
          <CityRiddlerFormLabel required={required} focused={false} sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ display: "flex", alignItems: "center" }}>
              <TooltipIcon text={props.infoText} />
              {label}
            </Typography>
          </CityRiddlerFormLabel>
        </Grid>
      )}
      <CityRiddlerTextField
        id={id}
        error={error}
        onChange={onChange}
        value={text}
        multiline={multiline}
        rows={rows}
        size="small"></CityRiddlerTextField>
    </>
  )
}
