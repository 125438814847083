import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getAuth } from "firebase/auth"
import { app } from "./features/auth/firebase/firebaseSetup"

export const ProtectedRoute = (props: any) => {
  const navigate = useNavigate()
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const checkUserToken = () => {
    const user = getAuth(app).currentUser
    if (!user) {
      setIsLoggedIn(false)
      return navigate("/auth")
    }
    setIsLoggedIn(true)
  }
  useEffect(() => {
    checkUserToken()
  }, [isLoggedIn])
  return <>{isLoggedIn ? props.children : null}</>
}
