import { Grid, Typography } from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom"
import { setInitialState } from "../../../../features/riddleForm/riddleFormSlice"
import { useAppDispatch } from "../../../hooks"

interface NavLinkProps {
  label: string
  color?: string
  destination: string
  icon?: any
  onClick?: () => void
  iconColor?: string
  fontSize?: string
}

export const NavLink = (props: NavLinkProps) => {
  const { state } = useLocation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const onClick = () => {
    if (state) {
      window.history.replaceState(undefined, "/riddleForm")
      dispatch(setInitialState())
    }
    if (props.onClick) {
      props.onClick()
    }
    navigate(`/${props.destination}`)
  }
  return (
    <Grid item container spacing={2} xs={12}>
      <Grid item xs={2}>
        {props.icon ? <props.icon sx={{ color: props.iconColor ?? "default" }} /> : null}
      </Grid>
      <Grid item xs={10}>
        <Typography
          onClick={onClick}
          sx={{
            cursor: "pointer",
            color: props.color ? props.color : "black",
            textDecoration: "none",
            "&:hover": { textDecoration: "underline" },
            fontSize: props.fontSize ?? "1rem",
          }}
          display="inline">
          {props.label}
        </Typography>
      </Grid>
    </Grid>
  )
}
