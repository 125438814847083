import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material"
import { TooltipIcon } from "../info/infoIcon"
import { CityRiddlerFormLabel } from "../../../themes"
import React, { ChangeEvent } from "react"

export interface CrRadioGroupProps {
  label: string
  required: boolean
  infoText: string
  value: any
  onChange?: (event: ChangeEvent<HTMLInputElement>, value: string) => void
  radioItems: CrRadioValue[]
}

export interface CrRadioValue {
  value: any
  label: String
}

export const CrRadioGroup = (props: CrRadioGroupProps) => {
  return (
    <Grid container item xs={12} spacing={3}>
      <Grid item xs={12}>
        <CityRiddlerFormLabel required={props.required} focused={false} sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ display: "flex", alignItems: "center" }}>
            <TooltipIcon text={props.infoText} />
            {props.label}
          </Typography>
        </CityRiddlerFormLabel>
      </Grid>
      <Grid item xs={12}>
        <RadioGroup row aria-labelledby={props.label + "-label"} value={props.value} onChange={props.onChange}>
          <Grid container item xs={12} textAlign="center">
            <Grid item xs={3}></Grid>
            {props.radioItems.map((item, index) => (
              <Grid key={props.label + "" + index} item xs={2}>
                <FormControlLabel labelPlacement="top" value={item.value} control={<Radio />} label={item.label} />
              </Grid>
            ))}
            <Grid item xs={3}></Grid>
          </Grid>
        </RadioGroup>
      </Grid>
    </Grid>
  )
}
