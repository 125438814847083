import { Grid } from "@mui/material"
import { useAppSelector } from "../../../../app/hooks"
import { RiddleTypes } from "../../../../app/data"
import { SelectLanguages, SelectRiddleType } from "../../riddleFormSlice"
import { RiddleTextInputFields } from "./riddleTextInputFields"

export const RiddleTextInput = () => {
  const languages = useAppSelector(SelectLanguages)
  const riddleType = useAppSelector(SelectRiddleType)

  return (
    <Grid container item xs={12} spacing={2}>
      {languages.map((language) => {
        return (
          <RiddleTextInputFields
            key={`${language.id}-${language.languageCode}-${language.countryCode}`}
            language={language}
            multiSelect={riddleType === RiddleTypes.MultipleChoice}
          />
        )
      })}
    </Grid>
  )
}
