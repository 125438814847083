import * as React from "react"
import { useEffect, useState } from "react"
import { GridRowSelectionModel } from "@mui/x-data-grid/models/gridRowSelectionModel"
import { AppBar, Button, Dialog, Grid, IconButton, Slide, SlideProps, Toolbar, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { DisplayedRiddleData, SortableExperienceStep } from "../../../app/data"
import { RiddleFilter } from "../../../app/utils/components/riddleFilter/riddleFilter"
import { Riddle } from "../../api/cityriddlerApi"
import { mapDataFieldListToRiddleList, mapListToDataField } from "../experienceMapper"

const Transition = React.forwardRef((props: SlideProps, ref) => <Slide direction="up" ref={ref} {...props} />)
const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 50 },
  {
    field: "name",
    width: 150,
    headerName: "Rätselname",
  },
  {
    field: "description",
    width: 400,
    headerName: "Rätsel",
  },
  {
    field: "address",
    width: 200,
    headerName: "Addresse",
  },
  {
    field: "category",
    width: 100,
    headerName: "Category",
  },
]

export interface RiddleDialogProps {
  open: boolean
  onClose: (selectedRiddles: SortableExperienceStep[]) => void
  selectedRiddles: SortableExperienceStep[]
  riddles: DisplayedRiddleData[]
}

export function RiddleDialog(props: RiddleDialogProps) {
  const { onClose, open, riddles, selectedRiddles } = props
  const [selection, setSelection] = useState<SortableExperienceStep[]>(selectedRiddles)

  const [filteredData, setFilteredData] = useState<Riddle[]>(mapDataFieldListToRiddleList(riddles) ?? [])

  const handleClose = () => {
    console.log("handleClose")
    onClose(selection)
  }

  const onSelectionModelChange = (rowSelectionModel: GridRowSelectionModel) => {
    console.log("onSelectionModelChange")
    if (filteredData) {
      const selectedIDs = new Set(rowSelectionModel)
      const selectedRowData = mapListToDataField(filteredData)
        .filter((riddle) => selectedIDs.has(riddle.id))
        .map((data, index: number) => {
          return {
            id: data.id.toString(),
            name: data.name,
            riddle: data.riddle,
            stepIndex: index,
          } as SortableExperienceStep
        })
      setSelection(selectedRowData)
    }
  }

  useEffect(() => {
    console.log("riddles changed")
    console.log(riddles)
  }, [riddles])

  useEffect(() => {
    console.log("filteredData changed")
    console.log(filteredData)
  }, [filteredData])

  const handleFilterChange = (filtered: Riddle[]) => {
    console.log("handleFilterChange")
    setFilteredData(filtered)
  }

  return (
    <Dialog fullScreen onClose={handleClose} open={open} TransitionComponent={Transition}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Rätsel auswählen
          </Typography>
          <Button autoFocus color="inherit" onClick={handleClose}>
            save
          </Button>
        </Toolbar>
      </AppBar>
      <Grid
        container
        spacing={0}
        // marginTop={5}
        direction="column"
        alignItems="center"
        justifyContent="center">
        <Grid container maxWidth="95%" spacing={2} sx={{ margin: "auto" }}>
          <Grid item xs={12}>
            <RiddleFilter riddles={mapDataFieldListToRiddleList(riddles)} handleFilterChange={handleFilterChange} />
          </Grid>
          {riddles && (
            <Grid item xs={12}>
              <DataGrid
                rows={mapListToDataField(filteredData)}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                checkboxSelection
                onRowSelectionModelChange={onSelectionModelChange}
                rowSelectionModel={selection.map((r) => r.id)}
                // disableRowSelectionOnClick
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Dialog>
  )
}
