import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { MultiselectCheckbox, MultiselectOption } from "../../../app/utils/components"
import { RiddleFormText } from "../../../i18"
import {
  addDataForLanguage,
  removeDataForLanguage,
  SelectIsError,
  SelectLanguages,
  setLanguages,
} from "../riddleFormSlice"
import { availableLanguages } from "../../../app/service/languageService"

export const RiddleLanguageOptions = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isFormError = useAppSelector(SelectIsError)
  const options: MultiselectOption[] = [
    {
      id: "1",
      name: t(RiddleFormText.german),
    },
    {
      id: "2",
      name: t(RiddleFormText.english),
    },
  ]
  const languages = useAppSelector(SelectLanguages)
  const isError = isFormError && languages.length === 0

  const handleChange = (event: any) => {
    const idAndName = event.target.name.split("-")
    const partialLanguage = {
      id: idAndName[0],
      name: idAndName[1],
    }

    let filteredLanguages = languages.filter((element) => `${element.id}` !== partialLanguage.id)

    if (filteredLanguages.length === languages.length) {
      const languageToAdd = availableLanguages.find((element) => `${element.id}` === partialLanguage.id)
      filteredLanguages.push(languageToAdd!)
      dispatch(addDataForLanguage(languageToAdd!))
    } else {
      dispatch(removeDataForLanguage(partialLanguage.id))
    }

    dispatch(setLanguages(filteredLanguages))
  }

  return (
    <MultiselectCheckbox
      label={t(RiddleFormText.languageSelectLabel)}
      infoText={t(RiddleFormText.languageSelectLabelInfo)}
      options={options}
      selected={languages ? languages.map((language) => "" + language.id) : []}
      space={12}
      gridSpacing={0}
      handleChange={handleChange}
      error={isError}
    />
  )
}
