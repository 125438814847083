import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material"
import { visuallyHidden } from "@mui/utils"
import React, { Key, useState } from "react"
import { Colors } from "../../../colors"
import { DataFields, HeadCell } from "./dataTable"

interface TableDisplayProperties<T extends DataFields> {
  data: T[]
  headCells: HeadCell<T>[]
  icons?: IconField[]
  comparatorField: keyof T
  rowRenderer: (
    row: T,
    index: number,
    icons?: IconField[],
    iconRenderer?: (icons: IconField[], rowId: string) => JSX.Element[],
  ) => JSX.Element
  paging?: TablePageInfos
}

export interface TablePageInfos {
  totalCount: number
  currentPage: number
  currentPageSize: number
  useSlicer?: boolean
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  setCurrentPageSize: React.Dispatch<React.SetStateAction<number>>
}

export interface IconField {
  label: string
  icon: any
  iconColor: Colors
  id: string
  handleClick: any
}

type Order = "asc" | "desc"

interface EnhancedTableProps<T extends DataFields> {
  numSelected: number
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void
  order: Order
  orderBy: string
  rowCount: number
  headCells: HeadCell<T>[]
}

function convertIconsToHeadCells<T extends DataFields>(icons: IconField[]): HeadCell<T>[] {
  return icons.map((element) => {
    return {
      disablePadding: false,
      id: (element.id === "delete" ? "delete" : element.id === "copy" ? "copy" : "edit") as keyof T,
      label: element.label,
      numeric: false,
    }
  })
}

const renderIcons = (icons: IconField[], rowId: string) => {
  return icons.map((element) => {
    return (
      <TableCell
        key={`${element.id} ${rowId}`}
        id={`${element.id} ${rowId}`}
        align={"left"}
        sx={{
          fontSize: 17,
          borderBottom: "none",
          "&:hover": {
            cursor: "pointer",
            opacity: "50%",
          },
        }}
        onClick={element.handleClick}>
        <element.icon id={`${element.id} ${rowId}`} sx={{ color: element.iconColor }} />
      </TableCell>
    )
  })
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator<Key extends keyof DataFields>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort<T extends DataFields>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const TableHeadData = <T extends DataFields>(props: EnhancedTableProps<T>) => {
  const {
    //onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props
  const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {props.headCells.map((headCell) => {
          const color = Colors.BackgroundLight
          const width =
            headCell.id === "status"
              ? "15%"
              : headCell.id === "edit" || headCell.id === "delete" || headCell.id === "copy"
                ? "1%"
                : "15%"
          return (
            <TableCell
              key={headCell.id as Key}
              align={"left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{
                padding: "16px",
                fontSize: 20,
                borderBottom: "none",
                backgroundColor: color,
                width: width,
              }}>
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}>
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

export const TableDisplay = <T extends DataFields>(props: TableDisplayProperties<T>) => {
  const { data, headCells, icons, comparatorField, rowRenderer } = props
  const [order, setOrder] = useState<Order>("asc")
  const [orderBy, setOrderBy] = useState<keyof T>(comparatorField)
  const [selected, setSelected] = useState<readonly string[]>([])

  const { totalCount, currentPage, currentPageSize, setCurrentPage, useSlicer, setCurrentPageSize } = props.paging || {}
  const [internPage, setInternPage] = React.useState(currentPage ?? 0)
  const [internRowsPerPage, setInternRowsPerPage] = React.useState(currentPageSize ?? 25)

  const handleChangePage = (event: any, newPage: number) => {
    setInternPage(newPage)
    setCurrentPage?.(newPage)
  }

  const handleChangeRowsPerPage = (event: any) => {
    const rows = parseInt(event.target.value, 10)
    setInternRowsPerPage?.(rows)
    setCurrentPageSize?.(rows)
    setInternPage?.(0)
    setCurrentPage?.(0)
  }

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof T) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  let displayedHeadCells = headCells
  let displayIcons = undefined

  if (icons) {
    displayedHeadCells = [...props.headCells, ...convertIconsToHeadCells(icons)]
  }

  const pageSize = () => currentPageSize ?? internRowsPerPage
  const page = () => currentPage ?? internPage

  const getData = () => {
    const sorted = stableSort<T>(data, getComparator(order, orderBy as keyof DataFields))
    if (props.paging?.useSlicer) return sorted.slice(page() * pageSize(), page() * pageSize() + pageSize())
    else return sorted
  }

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHeadData
            numSelected={selected.length}
            order={order}
            orderBy={orderBy as string}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
            headCells={displayedHeadCells}
          />
          {data.length > 0 ? (
            <TableBody>
              {getData().map((row, index) => {
                if (icons) {
                  return rowRenderer(row, index + 1 + page() * pageSize(), icons, renderIcons)
                } else {
                  return rowRenderer(row, index + 1 + page() * pageSize(), icons)
                }
              })}
            </TableBody>
          ) : (
            <NoDataFound />
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalCount ?? data.length}
        rowsPerPage={pageSize()}
        page={page()}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        showFirstButton={true}
        showLastButton={true}
      />
    </Paper>
  )
}

export const NoDataFound = () => (
  <Stack height="100%" width="100%" alignItems="center" justifyContent="center">
    No data to display
  </Stack>
)
