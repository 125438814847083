import { Avatar, Box, Card, Grid, Typography } from "@mui/material"
import { Colors } from "../../../../app/colors"
import { RiddleStatuses } from "./riddleStatuses"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import { RiddleCount } from "../../../../app/utils/components"
import { useContext } from "react"
import { AuthContext } from "../../../../firebase/authProvider"
import { UserSettings } from "../userSettings"
import { useGetStatisticQuery, useGetUserStatisticsQuery, UserStatistic } from "../../../api/cityriddlerApi"

let voidConst: void

interface CreatorInfoProperties {
  showOptions: boolean
  setShowOptions: any
}

export const CreatorInfo = (props: CreatorInfoProperties) => {
  const { showOptions, setShowOptions } = props
  const { user } = useContext(AuthContext)

  const { data: userData, isLoading: userIsLoading } = useGetUserStatisticsQuery(
    { userId: user?.uid! },
    { skip: !user || user!.admin },
  )
  const { data: adminData, isLoading: adminIsLoading } = useGetStatisticQuery(voidConst, {
    skip: !user || !user!.admin,
  })

  const getData = (): UserStatistic | undefined => {
    if (user) {
      if (user.admin) {
        return adminData
      } else {
        return userData
      }
    }
  }

  const handleClick = () => {
    setShowOptions(!showOptions)
  }

  return (
    <>
      <Box sx={{ position: "fixed" }} />
      <Card
        sx={{
          backgroundColor: Colors.BackgroundLight,
          width: "40vw",
          height: !showOptions ? "16.5vw" : "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "20px",
          marginLeft: "10vw",
          marginTop: "5vw",
          marginBottom: "5vw",
        }}
        elevation={1}>
        <Grid item container xs={12}>
          <Grid item container xs={12} spacing={4}>
            <Grid item xs={4}>
              <Avatar
                alt="Riddle Creator"
                src=""
                sx={{
                  position: "absolute",
                  width: "15vw",
                  height: "15vw",
                  top: "3vw",
                  left: "25vw",
                }}
              />
            </Grid>
            <Grid item xs={8} position="absolute" sx={{ top: "8vw", left: "40vw" }}>
              <Typography
                variant="h3"
                sx={{
                  color: Colors.Black,
                  width: "40vw",
                  fontSize: "4vw",
                  fontWeight: "2vw",
                }}>
                {user?.displayName ?? "Riddle Creator"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              position="absolute"
              sx={{ top: "19vw", left: "28vw", zIndex: "100" }}
              onClick={handleClick}>
              <EditOutlinedIcon
                sx={{
                  color: Colors.Black,
                  width: "3vw",
                  height: "3vw",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              />
            </Grid>
            <Grid item xs={4} position="absolute" sx={{ top: "15vw", left: "37vw" }}>
              <RiddleCount count={getData()?.riddles.length ?? 0!} />
            </Grid>
            <Grid item xs={5} position="absolute" sx={{ top: "15vw", left: "50vw" }}>
              <RiddleStatuses />
            </Grid>
          </Grid>
          {showOptions && (
            <Grid item container xs={12} sx={{ marginTop: "20vw" }}>
              <UserSettings />
            </Grid>
          )}
        </Grid>
      </Card>
    </>
  )
}
