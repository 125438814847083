import i18next from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

export enum ForgottenPasswordText {
  link = "auth.forgottenPassword.link",
  label = "auth.forgottenPassword.label",
  submit = "auth.forgottenPassword.submit",
}

export enum LoginText {
  greetingPartOne = "auth.login.greetingPartOne",
  greetingPartTwo = "auth.login.greetingPartTwo",
  emailFieldLabel = "auth.login.emailFieldLabel",
  passwordFieldLabel = "auth.login.passwordFieldLabel",
  loginButtonLabel = "auth.login.loginButtonLabel",
  newUserQuestion = "auth.login.newUserQuestion",
  newUserRegistrationLink = "auth.login.newUserRegistrationLink",
}

export enum RegisterText {
  greetingPartOne = "auth.register.greetingPartOne",
  greetingPartTwo = "auth.register.greetingPartTwo",
  firstNameFieldLabel = "auth.register.firstNameFieldLabel",
  lastNameFieldLabel = "auth.register.lastNameFieldLabel",
  emailFieldLabel = "auth.register.emailFieldLabel",
  cityFieldLabel = "auth.register.cityFieldLabel",
  cityFieldHelperText = "auth.register.cityFieldHelperText",
  phoneFieldLabel = "auth.register.phoneFieldLabel",
  phoneFieldHelperText = "auth.register.phoneFieldHelperText",
  passwordFieldLabel = "auth.register.passwordFieldLabel",
  passwordFieldHelperText = "auth.register.passwordFieldHelperText",
  confirmPasswordFieldLabel = "auth.register.confirmPasswordFieldLabel",
  confirmPasswordFieldHelperText = "auth.register.confirmPasswordFieldHelperText",
  userAgreementFieldLabel = "auth.register.userAgreementFieldLabel",
  userAgreementFieldHelperTextPartOne = "auth.register.userAgreementFieldHelperTextPartOne",
  userAgreementFieldHelperTextPartTwo = "auth.register.userAgreementFieldHelperTextPartTwo",
  userAgreementFieldHelperTextPartThree = "auth.register.userAgreementFieldHelperTextPartThree",
  userAgreementFieldHelperTextPartFour = "auth.register.userAgreementFieldHelperTextPartFour",
  userAgreementFieldHelperTextPartFive = "auth.register.userAgreementFieldHelperTextPartFive",
  newsLetterFieldLabel = "auth.register.newsLetterFieldLabel",
  newsLetterFieldHelperText = "auth.register.newsLetterFieldHelperText",
  submitButtonLabel = "auth.register.submitButtonLabel",
}

export enum ExperienceFormText {
  german = "experience.form.languageOptions.de",
  english = "experience.form.languageOptions.en",
  experienceJoinCode = "experience.form.joinCode",
  experienceIsEvent = "experience.form.isEvent",
  experienceNeedsPayment = "experience.form.needsPayment",
  experienceIntroVideo = "experience.form.introVideo",
  experienceOutroVideo = "experience.form.outroVideo",
  experienceUsePoints = "experience.form.usePoints",
  experienceForceOffline = "experience.form.forceOffline",
  experienceMaxJoins = "experience.form.maxJoins",
  experienceOverviewImageInfo = "experience.form.overviewImageInfo",
  experienceOverviewImage = "experience.form.overviewImage",
  experienceFinishImage = "experience.form.finishImage",
  experienceFinishImageInfo = "experience.form.finishImageInfo",
  experienceCoverImage = "experience.form.coverImage",
  experienceCoverImageInfo = "experience.form.coverImageInfo",
  experienceTourId = "experience.form.tourId",
  introHeader = "experience.form.introHeader",
  introText = "experience.form.introText",
  nextButton = "experience.form.nextButton",
  submitButton = "experience.form.submitButton",
  formError = "experience.form.formError",
  languageSelectLabel = "riddles.form.languageSelectLabel",
  displayName = "experience.form.displayName",
  descriptionShort = "experience.form.descriptionShort",
  descriptionLong = "experience.form.descriptionLong",
  pay = "experience.form.pay",
  pay50 = "experience.form.pay50",
  pay100 = "experience.form.pay100",
  experienceIsEventInfo = "experience.form.isEventInfo",
  experienceIntroVideoInfo = "experience.form.introVideoInfo",
  experienceJoinCodeInfo = "experience.form.joinCodeInfo",
  experienceForceOfflineInfo = "experience.form.forceOfflineInfo",
  experienceOutroVideoInfo = "experience.form.outroVideoInfo",
  experienceNeedsPaymentInfo = "experience.form.needsPaymentInfo",
  experienceUsePointsInfo = "experience.form.usePointsInfo",
  languageSelectLabelInfo = "experience.form.languageSelectLabelInfo",
  experienceMaxJoinsInfo = "experience.form.experienceMaxJoinsInfo",
  introTextInfo = "experience.form.introTextInfo",
  introHeaderInfo = "experience.form.introHeaderInfo",
  requiredInfo = "experience.form.requiredInfo",
  riddleSelectButton = "experience.form.riddleSelectButton",
  riddleSelectInfo = "experience.form.riddleSelectInfo",
  riddleSelectLabel = "experience.form.riddleSelectLabel",
}

export enum ExperienceFormErrors {
  steps = "experience.form.error.steps",
  joinCode = "experience.form.error.joinCode",
}

export enum RiddleFormText {
  riddleName = "riddles.form.riddleName",
  riddleTypeLabel = "riddles.form.riddleTypeLabel",
  text = "riddles.form.riddleTypeOptions.text",
  multipleChoice = "riddles.form.riddleTypeOptions.multipleChoice",
  number = "riddles.form.riddleTypeOptions.number",
  languageSelectLabel = "riddles.form.languageSelectLabel",
  german = "riddles.form.languageOptions.de",
  english = "riddles.form.languageOptions.en",
  question = "riddles.form.question",
  solution = "riddles.form.solution",
  navigation = "riddles.form.navigation",
  answer = "riddles.form.answer",
  hint = "riddles.form.hint",
  funFact = "riddles.form.funFact",
  historicFact = "riddles.form.historicFact",
  address = "riddles.form.address",
  zip = "riddles.form.zip",
  streetNumber = "riddles.form.streetNumber",
  city = "riddles.form.city",
  country = "riddles.form.country",
  google = "riddles.form.google",
  riddleImage = "riddles.form.riddleImage",
  riddleImageInfo = "riddles.form.riddleImageInfo",
  locationImage = "riddles.form.locationImage",
  locationImageInfo = "riddles.form.locationImageInfo",
  navigationImage = "riddles.form.navigationImage",
  navigationImageInfo = "riddles.form.navigationImageInfo",
  categories = "riddles.form.categories",
  difficulty = "riddles.form.difficulty",
  flagNote = "riddles.form.flagNote",
  riddleActive = "riddles.form.active",
  riddleFlagged = "riddles.form.flagged",
  fame = "riddles.form.fame",
  submitButton = "riddles.form.submitButton",
  nextButton = "riddles.form.nextButton",
  prominenz1 = "riddles.form.prominenz1",
  prominenz2 = "riddles.form.prominenz2",
  prominenz3 = "riddles.form.prominenz3",
  prominenz1Example = "riddles.form.prominenz1Example",
  prominenz2Example = "riddles.form.prominenz2Example",
  prominenz3Example = "riddles.form.prominenz3Example",
  difficulty1 = "riddles.form.difficulty1",
  difficulty2 = "riddles.form.difficulty2",
  difficulty3 = "riddles.form.difficulty3",
  flagNoteInfo = "riddles.form.flagNoteInfo",
  fameInfo = "riddles.form.fameInfo",
  riddleTypeLabelInfo = "riddles.form.riddleTypeLabelInfo",
  googleInfo = "riddles.form.googleInfo",
  riddleActiveInfo = "riddles.form.riddleActiveInfo",
  categoriesInfo = "riddles.form.categoriesInfo",
  difficultyInfo = "riddles.form.difficultyInfo",
  riddleFlaggedInfo = "riddles.form.riddleFlaggedInfo",
  riddleNameInfo = "riddles.form.riddleNameInfo",
  funFactInfo = "riddles.form.funFactInfo",
  navigationInfo = "riddles.form.navigationInfo",
  solutionInfo = "riddles.form.solutionInfo",
  historicFactInfo = "riddles.form.historicFactInfo",
  languageSelectLabelInfo = "riddles.form.languageSelectLabelInfo",
  questionInfo = "riddles.form.questionInfo",
  hintInfo = "riddles.form.hintInfo",
  answerInfo = "riddles.form.answerInfo",
  requiredInfo = "riddles.form.requiredInfo",
}

export enum RiddleFormErrors {
  solutionType = "riddles.form.error.solutionType",
  multipleChoice = "riddles.form.error.multipleChoice",
  languages = "riddles.form.error.languages",
  gpsData = "riddles.form.error.gpsData",
  riddleImage = "riddles.form.error.riddleImage",
  locationImage = "riddles.form.error.locationImage",
  categories = "riddles.form.error.categories",
  difficulty = "riddles.form.error.difficulty",
  prominence = "riddles.form.error.prominence",
  pay1 = "riddles.form.difficulty3",
}

export enum RiddleMap {
  pageName = "riddleMap.pageName",
  label = "riddleMap.label",
  placeholder = "riddleMap.placeholder",
}

export enum ProfileText {
  emailLabel = "profile.emailLabel",
  displayName = "profile.displayName",
  password = "profile.password",
  edit = "profile.edit",
  cancel = "profile.cancel",
  riddle = "profile.riddle",
  active = "profile.active",
  inactive = "profile.inactive",
  userData = "profile.userData",
  statistics = "profile.statistics",
  allRiddles = "profile.allRiddles",
}

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    supportedLngs: ["de"],
    fallbackLng: "de",
    resources: {
      de: {
        translation: {
          auth: {
            forgottenPassword: {
              link: "Passwort vergessen?",
              label: "Passwort zurücksetzen",
              submit: "Abschicken",
            },
            login: {
              greetingPartOne: "Hallo!",
              greetingPartTwo: "Hier ist dein Login",
              emailFieldLabel: "E-Mail",
              passwordFieldLabel: "Passwort",
              loginButtonLabel: "EINLOGGEN",
              newUserQuestion: "Neu hier",
              newUserRegistrationLink: "NEUES KONTO ERSTELLEN",
            },
            register: {
              greetingPartOne: "Hi! ",
              greetingPartTwo: "Wir freuen uns dich dabei zu haben.",
              firstNameFieldLabel: "Vorname*",
              lastNameFieldLabel: "Nachname*",
              emailFieldLabel: "E-Mail Adresse*",
              cityFieldLabel: "Stadt/Städte",
              cityFieldHelperText:
                "Für welche Stadt/Städte möchest du Rätsel erstellen? Du kannst auch später weitere Städte hinzufügen.",
              phoneFieldLabel: "Telefonnummer",
              phoneFieldHelperText: "Bitte füge auch deine Landesvorwahl hinzu (z.B. +43 für Österreich)",
              passwordFieldLabel: "Passwort*",
              passwordFieldHelperText: "mindestens 12 Buchstaben lang, Groß- und Kleinbuchstaben un min 1 Zahl",
              confirmPasswordFieldLabel: "Passwort bestätigen*",
              confirmPasswordFieldHelperText: "Bitte bestägite hier dein Passwort",
              userAgreementFieldLabel: "User Agreement und AGBs*",
              userAgreementFieldHelperTextPartOne: "Ich stimme dem ",
              userAgreementFieldHelperTextPartTwo: "User Agreement",
              userAgreementFieldHelperTextPartThree: " und den ",
              userAgreementFieldHelperTextPartFour: "AGBs",
              userAgreementFieldHelperTextPartFive: " zu.",
              newsLetterFieldLabel: "Newsletter",
              newsLetterFieldHelperText: "Ich möchte den CityRiddler Newsletter erhalten.",
              submitButtonLabel: "KONTO ANLEGEN",
            },
          },
          riddles: {
            form: {
              error: {
                solutionType: "Keine Rätselart gewählt",
                multipleChoice: "Wenn Single-Choice gewählt wird, müssen mindestens 2 Lösungsoptionen angegeben werden",
                languages: "Es muss mindestens 1 Sprache ausgefüllt sein",
                gpsData: "Kein Standort gewählt",
                riddleImage: "Kein Rätselbild hochgeladen",
                locationImage: "Kein Lösungsbild hochgeladen",
                categories: "Es muss mindestens 1 Kategorie gewählt werden",
                difficulty: "Keine Schwierigkeit gewählt",
                prominence: "Keine Bekanntheit gewählt",
              },
              riddleName: "Rätselname",
              riddleTypeLabel: "Rätselart",
              riddleTypeOptions: {
                multipleChoice: "Single-Choice",
                text: "Text",
                number: "Zahl",
              },
              languageSelectLabel: "Sprache(n):",
              languageOptions: {
                de: "Deutsch",
                en: "Englisch",
              },
              question: "Rätselfrage",
              solution: "Lösung",
              navigation: "Navigation",
              answer: "Antwort Optionen",
              hint: "Hinweis",
              funFact: "Funfact",
              historicFact: "Historische Info",
              address: "Straße",
              streetNumber: "Nummer:",
              zip: "Postleitzahl:",
              city: "Stadt:",
              country: "Land:",
              google: "Standort: *",
              riddleImage: "Rätselbild",
              riddleImageInfo:
                "Dieses Bild siehst du direkt bei der Rätselfrage. Man muss das Rätsel damit finden, es darf aber nicht zu viel verraten.",
              locationImage: "Lösungsbild",
              locationImageInfo: "Dieses Bild siehst du, nachdem das Rätsel gelöst wurde.",
              navigationImage: "Navigationbild",
              navigationImageInfo: "Dieses Bild siehst du gemeinsam mit dem Navigationstext.",
              categories: "Kategorie(n):",
              difficulty: "Schwierigkeitsgrad:",
              fame: "Bekanntheit:",
              active: "Aktiv",
              flagNote: "Notiz (intern)",
              flagged: "markiert (intern)",
              submitButton: "ABSCHICKEN",
              nextButton: "WEITER",
              prominenz1: "Hauptattraktion",
              prominenz1Example: "(z.b. Stephansdom)",
              prominenz2: "Standard",
              prominenz2Example: "(z.b. Peterskirche)",
              prominenz3: "Verstecktes Juwel",
              prominenz3Example: "(z.b. Türkenkugel)",
              difficulty1: "Einfach",
              difficulty2: "Mittel",
              difficulty3: "Schwer",
              flagNoteInfo: "Info Text",
              fameInfo: "Info Text",
              riddleTypeLabelInfo: "Info Text",
              googleInfo: "Info Text",
              riddleActiveInfo: "Info Text",
              categoriesInfo: "Info Text",
              difficultyInfo: "Info Text",
              riddleFlaggedInfo: "Info Text",
              riddleNameInfo: "Info Text",
              funFactInfo: "Info Text",
              navigationInfo: "Info Text",
              solutionInfo: "Info Text",
              historicFactInfo: "Info Text",
              languageSelectLabelInfo: "Info Text",
              questionInfo: "Info Text",
              hintInfo: "Info Text",
              answerInfo: "Info Text",
              requiredInfo: "*Pflichtfelder",
            },
          },
          experience: {
            form: {
              error: {
                steps: "Keine Rätsel ausgewählt (min. 2)",
                joinCode: "Kein Beitrittscode",
              },
              joinCode: "Beitrittscode",
              introVideo: "Intro Video URL",
              outroVideo: "Outro Video URL",
              usePoints: "Nutze Punktsystem",
              forceOffline: "Erzwinge Offline-Mouds",
              maxJoins: "Maximale Beitritte",
              overviewImage: "Übersichtsbild",
              overviewImageInfo:
                "Dieses Bild sieht man, wenn man das Abenteuer schon heruntergeladen hat, aber bevor man das Abenteuer startet",
              finishImage: "Abschlussbild",
              finishImageInfo: "Dieses Bild sieht man, wenn man das Abenteuer abgeschlossen hat",
              coverImage: "Titelbild",
              coverImageInfo:
                "Dieses Bild sieht man in der Beschreibung des Abenteuers, bevor man dieses herunterladet.",
              tourId: "Payment ID",
              introHeader: "Intro Text Header",
              introText: "Intro Text",
              displayName: "Anzeigename",
              descriptionShort: "Beschreibung kurz",
              descriptionLong: "Beschreibung lang",
              pay: "Normal",
              pay50: "-50% Reduziert",
              pay100: "Gratis",
              isEvent: "Offen für andere Spieler",
              needsPayment: "Kostenpflichtig",
              languageSelectLabel: "Sprache(n):",
              languageOptions: {
                de: "Deutsch",
                en: "Englisch",
              },
              address: "Straße",
              streetNumber: "Nummer:",
              zip: "Postleitzahl:",
              city: "Stadt:",
              country: "Land:",
              submitButton: "ABSCHICKEN",
              nextButton: "WEITER",
              formError: "Eingabe nicht gültig",
              isEventInfo: "Info Text",
              introVideoInfo: "Info Text",
              joinCodeInfo: "Info Text",
              forceOfflineInfo: "Info Text",
              outroVideoInfo: "Info Text",
              needsPaymentInfo: "Info Text",
              usePointsInfo: "Info Text",
              languageSelectLabelInfo: "Info Text",
              experienceMaxJoinsInfo: "Info Text",
              introTextInfo: "Info Text",
              introHeaderInfo: "Info Text",
              requiredInfo: "*Pflichtfelder",
              riddleSelectButton: "Rätsel auswählen",
              riddleSelectLabel: "Rätsel auswählen",
              riddleSelectInfo: "Info Text",
            },
          },
          riddleMap: {
            pageName: "Karte",
            label: "Alle Rätsel",
            placeholder: "Select a Riddle",
          },
          profile: {
            emailLabel: "E-Mail",
            displayName: "Anzeigename",
            password: "Passwort",
            edit: "Berbaiten",
            cancel: "Stornieren",
            riddle: "Rätsel",
            active: "Aktiv",
            inactive: "Inaktiv",
            userData: "Meine Daten",
            statistics: "Statistiken",
            allRiddles: "Alles Rätsel",
          },
        },
      },
    },
  })
