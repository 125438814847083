import { createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import { getAuth } from "firebase/auth"
import { app } from "./firebase/firebaseSetup"

interface AuthData {
  id: number
  accessToken: string
  user: any
}

const authAdapter = createEntityAdapter<AuthData>()

const initialState = authAdapter.getInitialState()

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setAuthData(state, action) {
      getAuth(app)
        .currentUser?.getIdToken()
        .then((token) => {
          authAdapter.setOne(state, {
            id: 0,
            accessToken: action.payload.token,
            user: action.payload.user,
          })
        })
    },
  },
})

export const { setAuthData } = authSlice.actions

export default authSlice.reducer
