import { SortableItemProps, SortableList } from "@thaddeusjiang/react-sortable-list"
import { List } from "@mui/material"
import * as React from "react"
import { useEffect, useState } from "react"
import { SortableExperienceStep } from "../../../../app/data"
import "@thaddeusjiang/react-sortable-list/dist/index.css"
import { SortableExperienceStepListItem } from "./sortableExperienceStepListItem"

export const SortableRiddleList = (props: {
  steps: SortableExperienceStep[]
  setSteps: (items: SortableExperienceStep[]) => void
}) => {
  const [items, setItems] = useState<SortableExperienceStep[]>(props.steps)
  const [changedProps, setChangedProps] = useState(true)

  useEffect(() => {
    console.log("change from prop")
    setItems(props.steps)
    setChangedProps(true) //prevent loop with changed index trigger from SortableList
  }, [props.steps])

  useEffect(() => {
    console.log("sorted riddles changed")
    console.log(items)
    if (!changedProps) {
      const mapped = items.map((step, index) => {
        step.stepIndex = index
        return step
      })
      props.setSteps(mapped)
    } else {
      setChangedProps(false)
    }
  }, [items])

  return (
    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
      <SortableList items={items} setItems={setItems as React.Dispatch<React.SetStateAction<SortableItemProps[]>>}>
        {buildItems()}
      </SortableList>
    </List>
  )
}

export const buildItems =
  () =>
  ({ items }: { items: SortableItemProps[] }) => (
    <>
      {items.map((item: SortableItemProps, index) => (
        <SortableExperienceStepListItem key={item.id} item={item as SortableExperienceStep} index={index} />
      ))}
    </>
  )
