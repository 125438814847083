import { DisplayedRiddleData, SortableExperienceStep } from "../../../../app/data"
import { Button, CircularProgress } from "@mui/material"
import { RiddleDialog } from "../riddleDialog"
import * as React from "react"
import { useTranslation } from "react-i18next"
import { ExperienceFormText } from "../../../../i18"

export const RiddleSelect = (props: {
  loading: boolean
  riddles: DisplayedRiddleData[] | undefined
  onClick: () => void
  open: boolean
  onClose: (selectedRiddles: SortableExperienceStep[]) => void
  selectedRiddles: SortableExperienceStep[]
}) => {
  const { t } = useTranslation()

  return (
    <>
      {props.loading || !props.riddles ? (
        <CircularProgress />
      ) : (
        <>
          <Button variant="outlined" onClick={props.onClick}>
            {t(ExperienceFormText.riddleSelectButton)}
          </Button>
          <RiddleDialog
            open={props.open}
            onClose={props.onClose}
            riddles={props.riddles}
            selectedRiddles={props.selectedRiddles}
          />
        </>
      )}
    </>
  )
}
