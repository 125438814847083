import { Grid, InputLabel, Typography } from "@mui/material"
import { TextInput } from "../../../../app/utils/components/input/textInput"
import { IntroWithOrderIndex } from "../../../../app/data"
import { AddInputField, RemoveInputField } from "../../../../app/utils/components"
import { Language } from "../../../api/cityriddlerApi"
import { CityRiddlerFormLabel } from "../../../../app/themes"
import { TooltipIcon } from "../../../../app/utils/components/info/infoIcon"
import React from "react"

interface IntroDynamicInputFieldProperties {
  data: IntroWithOrderIndex[]
  name: string
  label: string
  infoText: string
  maxInputCount: number
  handleChange: any
  language: Language
  error: boolean
  required: boolean
  multiline: boolean
}

export const IntroDynamicInputField = (props: IntroDynamicInputFieldProperties) => {
  const { data, name, label, maxInputCount, handleChange, error, multiline, language } = props

  const blankData: IntroWithOrderIndex = {
    orderIndex: 0,
    text: "",
    language: language,
  }

  const isError = error && data.every((element) => Boolean(element.text))

  const handleAddInputField = () => {
    if (data.length < maxInputCount) {
      data.push(blankData)
      handleChange(data)
    }
  }

  const handleRemoveInputField = (index: number) => {
    if (data.length > 1) {
      data.splice(index, 1)
      handleChange(data)
    }
  }

  const onChange = (text: string, index: number) => {
    handleChange(data, { text: text, index: index })
  }

  return (
    <>
      <Grid item xs={12}>
        <CityRiddlerFormLabel required={props.required} focused={false} sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ display: "flex", alignItems: "center" }}>
            <TooltipIcon text={props.infoText} />
            {props.label}
          </Typography>
        </CityRiddlerFormLabel>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        {data.map((element, index) => {
          return (
            <Grid container item md={6} xs={12} key={`${name}-${index}`}>
              <Grid item xs={12}>
                <InputLabel htmlFor={`${name}-${index}`} required error={error && !data[index].text}>
                  {`${label} ${index + 1}:`}
                </InputLabel>
              </Grid>
              <Grid item container id={`${name}-${index}`} xs={12}>
                <Grid item container textAlign="center" xs={maxInputCount > 1 ? 10 : 12}>
                  <TextInput
                    id={`${name}-${index}`}
                    error={error && !data[index].text}
                    handleChange={onChange}
                    value={element.text}
                    multiline={multiline}
                    required={false}
                    index={index}
                    infoText={"Info Text"}
                  />
                </Grid>
                {maxInputCount > 1 && (
                  <Grid item container xs={1}>
                    <RemoveInputField index={index} onClick={handleRemoveInputField} />
                  </Grid>
                )}
              </Grid>
            </Grid>
          )
        })}
        {maxInputCount > 1 && (
          <Grid item container xs={1}>
            <AddInputField onClick={handleAddInputField} />
          </Grid>
        )}
      </Grid>
    </>
  )
}
