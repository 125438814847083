import { Grid } from "@mui/material"
import { DisplayName } from "./components/displayName"
import { Email } from "./components/email"
import { Password } from "./components/password"

export const UserSettings = () => {
  return (
    <Grid container item xs={10} sx={{ marginLeft: "10%" }}>
      <Grid item xs={12}>
        <Email />
      </Grid>
      <Grid item xs={12}>
        <DisplayName />
      </Grid>
      <Grid item xs={12}>
        <Password />
      </Grid>
    </Grid>
  )
}
