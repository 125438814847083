import { Language } from "../../features/api/cityriddlerApi"

export const availableLanguages: Language[] = [
  {
    id: 1,
    languageCode: "de",
    countryCode: "de",
  },
  {
    id: 2,
    languageCode: "en",
    countryCode: "en",
  },
]
