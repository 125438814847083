import AddCircleIcon from "@mui/icons-material/AddCircle"
import { Colors } from "../../colors"

interface AddInputFieldProperties {
  onClick: any
}

export const AddInputField = (props: AddInputFieldProperties) => {
  const { onClick } = props

  return (
    <AddCircleIcon
      sx={{
        color: Colors.Primary,
        fontSize: "45px",
        cursor: "pointer",
        "&:hover": { opacity: "80%" },
        margin: "auto",
      }}
      onClick={onClick}
    />
  )
}
