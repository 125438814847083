import { Card, CardContent, Typography } from "@mui/material"
import { Colors } from "../../../../app/colors"

export const PlayerCount = (props: { count: number }) => {
  const { count } = props
  return (
    <Card
      sx={{
        backgroundColor: Colors.TiffanyBlue,
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "20px",
      }}
      elevation={3}>
      <CardContent
        sx={{
          textAlign: "center",
        }}>
        <Typography variant="h1" color={Colors.Primary}>
          {count}
        </Typography>
        <Typography variant="h4" color={Colors.Primary}>
          Spieler
        </Typography>
      </CardContent>
    </Card>
  )
}
