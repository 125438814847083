import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { PriorityText, RiddleFormState } from "../../app/data"
import { RootState } from "../../app/store"
import { Category, Fact, InternationalText, Language, Location } from "../api/cityriddlerApi"
import { FactTypes } from "../../app/data/factModels"

const initialState: RiddleFormState = {
  id: null,
  isError: false,
  edit: false,
  riddleImageFile: null,
  locationImageFile: null,
  navigationImageFile: null,
  languages: [],
  title: [
    {
      text: "",
      language: {
        id: -1,
        languageCode: "",
        countryCode: "",
      },
    },
  ],
  description: [
    {
      text: "",
      language: {
        id: -1,
        languageCode: "",
        countryCode: "",
      },
    },
  ],
  solution: [
    {
      text: "",
      language: {
        id: -1,
        languageCode: "",
        countryCode: "",
      },
    },
  ],
  navigation: [
    {
      text: "",
      language: {
        id: -1,
        languageCode: "",
        countryCode: "",
      },
    },
  ],
  difficulty: "MEDIUM",
  hints: [],
  category: [],
  categoryIds: [],
  facts: [],
  locationImage: {
    url: "",
  },
  riddleImage: {
    url: "",
  },
  navigationImage: { url: "" },
  location: {
    facts: [],
    address: {
      street: "",
      streetNumber: "",
      zipCode: "",
      city: "",
      country: "",
    },
    gpsData: {
      latitude: 0,
      longitude: 0,
    },
    prominence: "STANDARD",
    metadata: {
      flagged: false,
      active: true,
      deleted: false,
      created: "",
      updated: "",
    },
    image: {
      url: "",
    },
  },
  solutionType: "TEXT",
  choices: [],
  flagged: false,
  active: true,
  flagNote: "",
}

const riddleFormSlice = createSlice({
  name: "riddleForm",
  initialState: initialState,
  reducers: {
    setIsError(state, action: PayloadAction<boolean>) {
      state.isError = action.payload
    },
    setRiddleType(state, action: PayloadAction<"MULTIPLE_CHOICE" | "NUMBER" | "TEXT">) {
      state.solutionType = action.payload
    },
    setLanguages(state, action: PayloadAction<Language[]>) {
      state.languages = [...action.payload]
    },
    addDataForLanguage(state, action: PayloadAction<Language>) {
      const language = action.payload
      state.title.push({ language: language, text: "" })
      state.description.push({ language: language, text: "" })
      state.solution?.push({ language: language, text: "" })
      state.navigation?.push({ language: language, text: "" })
      state.hints.push({ language: language, text: "", priority: 0 })
      state.choices.push({ language: language, text: "", priority: 0 })

      state.facts.push(
        { text: "", type: FactTypes.FunFact, language: language },
        { text: "", type: FactTypes.HistoricFact, language: language },
      )
    },
    removeDataForLanguage(state, action: PayloadAction<number>) {
      const languageId = Number(action.payload)

      const filteredTitle = state.title.filter((element) => {
        return element.language.id !== languageId
      })
      state.title = [...filteredTitle]

      const filteredDescription = state.description.filter((element) => {
        return element.language.id !== languageId
      })
      state.description = [...filteredDescription]

      if (state.solution) {
        const filteredSolution =
          state.solution?.filter((element) => {
            return element.language.id !== languageId
          }) ?? null
        state.solution = [...filteredSolution]
      }
      if (state.navigation) {
        const filteredNavigation =
          state.navigation?.filter((element) => {
            return element.language.id !== languageId
          }) ?? null
        state.navigation = [...filteredNavigation]
      }
      const filteredHints = state.hints.filter((element) => {
        return element.language.id !== languageId
      })
      state.solution = [...filteredHints]

      const filteredChoices = state.choices.filter((element) => {
        return element.language.id !== languageId
      })
      state.solution = [...filteredChoices]

      const filteredFacts = state.facts.filter((element) => {
        return element.language.id !== languageId
      })
      state.facts = [...filteredFacts]
    },
    setTitle(state, action: PayloadAction<InternationalText>) {
      const title = action.payload
      state.title.forEach((desc, index) => {
        if (desc.language.id === title.language.id) {
          state.title[index] = { ...title }
        }
      })
    },
    setDescription(state, action: PayloadAction<InternationalText>) {
      const description = action.payload
      state.description.forEach((desc, index) => {
        if (desc.language.id === description.language.id) {
          state.description[index] = { ...description }
        }
      })
    },
    setNavigation(state, action: PayloadAction<InternationalText>) {
      const navigation = action.payload
      state.navigation?.forEach((nav, index) => {
        if (nav.language.id === navigation.language.id) {
          state.navigation![index] = { ...navigation }
        }
      })
    },
    setSolution(state, action: PayloadAction<InternationalText>) {
      const solution = action.payload
      state.solution?.forEach((sol, index) => {
        if (sol.language.id === solution.language.id) {
          state.solution![index] = { ...solution }
        }
      })
    },
    setChoices(state, action: PayloadAction<PriorityText[]>) {
      const languageId = action.payload[0].language.id
      let updatedState = state.choices.filter((choice) => choice.language.id !== languageId)
      updatedState.push(...action.payload)
      state.choices = [...updatedState]
    },
    setHints(state, action: PayloadAction<PriorityText[]>) {
      const languageId = action.payload[0].language.id
      let updatedState = state.hints.filter((hint) => hint.language.id !== languageId)
      updatedState.push(...action.payload)
      state.hints = [...updatedState]
    },
    setFacts(state, action: PayloadAction<Fact[]>) {
      const languageId = action.payload[0].language.id
      let updatedState = state.facts.filter((fact) => fact.language.id !== languageId)
      updatedState.push(...action.payload)
      state.facts = [...updatedState]
    },
    setRiddleLocation(state, action: PayloadAction<Location>) {
      state.location = { ...action.payload }
    },
    setRiddleImageFile(state, action: PayloadAction<File>) {
      state.riddleImageFile = action.payload
    },
    setLocationImageFile(state, action: PayloadAction<File>) {
      state.locationImageFile = action.payload
    },
    setNavigationImageFile(state, action: PayloadAction<File>) {
      state.navigationImageFile = action.payload
    },
    setCategories(state, action: PayloadAction<Category[]>) {
      console.log([...action.payload])
      state.category = [...action.payload]
      state.categoryIds = action.payload.map((category) => category.id!)
    },
    setDifficulty(state, action: PayloadAction<"MEDIUM" | "LOW" | "HIGH">) {
      state.difficulty = action.payload
    },
    setFame(state, action: PayloadAction<"STANDARD" | "MAIN_SIGHT" | "HIDDEN_GEM">) {
      state.location.prominence = action.payload
    },
    setActive(state, action: PayloadAction<boolean>) {
      state.active = action.payload
    },
    setFlagged(state, action: PayloadAction<boolean>) {
      state.flagged = action.payload
    },
    setFlagNote(state, action: PayloadAction<string>) {
      state.flagNote = action.payload
    },
    setFormState: function (state, action: PayloadAction<RiddleFormState>) {
      state.id = action.payload.id
      state.title = [...action.payload.title]
      if (action.payload.solution) state.solution = [...action.payload.solution]
      state.solutionType = action.payload.solutionType
      state.languages = [...action.payload.languages]
      state.description = [...action.payload.description]
      state.choices = [...action.payload.choices]
      state.facts = [...action.payload.facts]
      state.hints = [...action.payload.hints]
      state.location = { ...action.payload.location }
      state.category = [...action.payload.category]
      state.categoryIds = [...action.payload.categoryIds]
      state.difficulty = action.payload.difficulty
      state.riddleImage = { ...action.payload.riddleImage }
      state.locationImage = { ...action.payload.locationImage }
      if (action.payload.navigationImage) state.navigationImage = { ...action.payload.navigationImage }
      if (action.payload.navigation) state.navigation = [...action.payload.navigation]
      state.active = action.payload.active
      state.flagged = action.payload.flagged
      state.flagNote = action.payload.flagNote
    },
    setInitialState(state) {
      state.title = [...initialState.title]
      if (initialState.solution) state.solution = [...initialState.solution]
      state.solutionType = initialState.solutionType
      state.languages = [...initialState.languages]
      state.description = [...initialState.description]
      state.choices = [...initialState.choices]
      state.facts = [...initialState.facts]
      state.hints = [...initialState.hints]
      state.location = { ...initialState.location }
      state.category = [...initialState.category]
      state.categoryIds = [...initialState.categoryIds]
      state.difficulty = initialState.difficulty
      state.riddleImage = { ...initialState.riddleImage }
      state.locationImage = { ...initialState.locationImage }
      if (initialState.navigationImage) state.navigationImage = { ...initialState.navigationImage }
      if (initialState.navigation) state.navigation = { ...initialState.navigation }
      state.active = initialState.active
      state.flagged = initialState.flagged
      state.flagNote = initialState.flagNote
    },
  },
})

export const {
  setIsError,
  setRiddleType,
  setLanguages,
  addDataForLanguage,
  removeDataForLanguage,
  setTitle,
  setDescription,
  setNavigation,
  setSolution,
  setChoices,
  setHints,
  setFacts,
  setRiddleLocation,
  setRiddleImageFile,
  setLocationImageFile,
  setNavigationImageFile,
  setCategories,
  setDifficulty,
  setFame,
  setActive,
  setFlagged,
  setFlagNote,
  setFormState,
  setInitialState,
} = riddleFormSlice.actions

export default riddleFormSlice.reducer

export const SelectFormState = (state: RootState) => state.riddleForm
export const SelectIsError = (state: RootState) => state.riddleForm.isError
export const SelectRiddleType = (state: RootState) => state.riddleForm.solutionType
export const SelectLanguages = (state: RootState) => state.riddleForm.languages
export const SelectRiddleTextInputFieldsData = createSelector(
  [
    (state: RootState) => state.riddleForm.title,
    (state: RootState) => state.riddleForm.description,
    (state: RootState) => state.riddleForm.navigation,
    (state: RootState) => state.riddleForm.solution,
    (state: RootState) => state.riddleForm.choices,
    (state: RootState) => state.riddleForm.hints,
    (state: RootState) => state.riddleForm.facts,
    (state: RootState, language: Language) => language,
  ],
  (title, description, navigation, solution, choices, hints, facts, language) => {
    return {
      title: title.find((tl) => tl.language.id === language.id),
      description: description.find((desc) => desc.language.id === language.id),
      navigation: navigation?.find((desc) => desc.language.id === language.id),
      solution: solution?.find((sol) => sol.language.id === language.id),
      choices: choices.filter((choice) => choice.language?.id === language.id),
      hints: hints.filter((hint) => hint.language?.id === language.id),
      facts: facts.filter((fact) => fact.language.id === language.id),
    }
  },
)
export const SelectRiddleLocation = (state: RootState) => state.riddleForm.location
export const SelectIsRiddleImageFileAvailable = (state: RootState) => state.riddleForm.riddleImageFile === null
export const SelectIsLocationImageFileAvailable = (state: RootState) => state.riddleForm.locationImageFile === null
export const SelectIsNavigationImageFileAvailable = (state: RootState) => state.riddleForm.navigationImageFile === null
export const SelectCategories = (state: RootState) => state.riddleForm.category
export const SelectDifficulty = (state: RootState) => state.riddleForm.difficulty
export const SelectFame = (state: RootState) => state.riddleForm.location.prominence
export const SelectRiddleImageUrl = (state: RootState) => state.riddleForm.riddleImage.url
export const SelectLocationImageUrl = (state: RootState) => state.riddleForm.locationImage.url
export const SelectNavigationImageUrl = (state: RootState) => state.riddleForm.navigationImage?.url ?? null
export const SelectActive = (state: RootState) => state.riddleForm.active
export const SelectFlagged = (state: RootState) => state.riddleForm.flagged
export const SelectFlagNote = (state: RootState) => state.riddleForm.flagNote
