import AddCircleIcon from "@mui/icons-material/AddCircle"
import { Colors } from "../../colors"

interface RemoveInputFieldProperties {
  index: number
  onClick: any
}

export const RemoveInputField = (props: RemoveInputFieldProperties) => {
  const { index, onClick } = props
  const handleClick = () => {
    onClick(index)
  }
  return (
    <AddCircleIcon
      sx={{
        color: Colors.Red,
        fontSize: "45px",
        transform: "rotate(45deg)",
        cursor: "pointer",
        "&:hover": { opacity: "80%" },
        margin: "auto",
        marginLeft: "0px",
      }}
      onClick={handleClick}
    />
  )
}
