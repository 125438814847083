import { FormControl } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { RiddleFormText } from "../../../i18"
import { SelectDifficulty, SelectIsError, setDifficulty } from "../riddleFormSlice"
import React, { ChangeEvent } from "react"
import { CrRadioGroup, CrRadioValue } from "../../../app/utils/components/input/radio"

export const RiddleDifficulty = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isFormError = useAppSelector(SelectIsError)
  const difficulty = useAppSelector(SelectDifficulty)
  const isError = isFormError && !difficulty

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setDifficulty((event.target as HTMLInputElement).value as "MEDIUM" | "LOW" | "HIGH"))
  }

  const items: CrRadioValue[] = [
    {
      value: "LOW",
      label: t(RiddleFormText.difficulty1),
    } as CrRadioValue,
    {
      value: "MEDIUM",
      label: t(RiddleFormText.difficulty2),
    } as CrRadioValue,
    {
      value: "HIGH",
      label: t(RiddleFormText.difficulty3),
    } as CrRadioValue,
  ]

  return (
    <FormControl error={isError} required sx={{ minWidth: "100%" }}>
      <CrRadioGroup
        label={t(RiddleFormText.difficulty)}
        required={true}
        infoText={`${t(RiddleFormText.difficultyInfo)}`}
        value={difficulty}
        onChange={handleChange}
        radioItems={items}
      />
    </FormControl>
  )
}
