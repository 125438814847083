import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { SelectIsEvent, setIsEvent } from "../experienceFormSlice"
import { ExperienceFormText } from "../../../i18"
import { Switch } from "../../../app/utils/components/input/switch"

export const ExperienceEvent = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isEvent = useAppSelector(SelectIsEvent)

  const onChange = (isEvent: boolean): void => {
    dispatch(setIsEvent(isEvent))
  }

  return (
    <>
      <Switch
        id="title"
        required={true}
        label={`${t(ExperienceFormText.experienceIsEvent)}`}
        handleChange={onChange}
        value={isEvent}
        infoText={`${t(ExperienceFormText.experienceIsEventInfo)}`}
      />
    </>
  )
}
