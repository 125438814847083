import { useState } from "react"
import { Footer } from "../../app/utils/components/footer/footer"
import { ForgottenPassword, Login, Register } from "./components"

export enum Page {
  login = "login",
  register = "register",
  forgottenPassword = "forgottenPassword",
}

export const Auth = () => {
  const [page, setPage] = useState<Page>(Page.login)

  const changeView = (page: Page) => {
    setPage(page)
  }

  return (
    <>
      {page === Page.login && <Login changeView={changeView} />}
      {page === Page.register && <Register changeView={changeView} />}
      {page === Page.forgottenPassword && <ForgottenPassword changeView={changeView} />}
      <Footer />
    </>
  )
}
