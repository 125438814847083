import React, { useEffect, useState } from "react"
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api"
import { CircularProgress } from "@mui/material"
import { Colors } from "../../../../app/colors"

const containerStyle = {
  width: "100%",
  height: "500px",
  referrerPolicy: "no-referrer-when-downgrade",
}

const options = {
  fullscreenControl: false,
  mapTypeControl: false,
  zoomControlOptions: {
    position: 19,
  },
  streetViewControl: false,
}

const libraries: (
  | "core"
  | "maps"
  | "places"
  | "geocoding"
  | "routes"
  | "marker"
  | "geometry"
  | "elevation"
  | "streetView"
  | "journeySharing"
  | "drawing"
  | "visualization"
)[] = ["core", "maps", "places", "routes", "marker", "geometry"]

interface TourMapProps {
  stops: google.maps.LatLng[] | null
}

export const TourMap = (props: TourMapProps) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY ?? "",
    libraries: libraries,
  })

  const { stops } = props

  const [currentPosition, setCurrentPosition] = useState<google.maps.LatLng | null>(null)
  const [map, setMap] = React.useState<google.maps.Map | null>(null)

  const directionsService = new google.maps.DirectionsService()
  const directionsDisplay = new google.maps.DirectionsRenderer()
  const routePolyline = React.useRef<google.maps.Polyline>()

  useEffect(() => {
    console.log("stops changed")
    if (map) fitMapToBounds(map)
    calcDirections()
  }, [stops])

  function fitMapToBounds(map: google.maps.Map) {
    console.log("fitMapToBounds")
    const bounds = new window.google.maps.LatLngBounds()
    stops?.forEach((stop) => {
      bounds.extend(stop)
    })
    map.fitBounds(bounds)
  }

  const onLoad = React.useCallback((map: google.maps.Map) => {
    console.log("onLoad")
    fitMapToBounds(map)
    setMap(map)
  }, [])

  const onUnmount = React.useCallback((map: google.maps.Map) => {
    console.log("onUnmount")
    setMap(null)
  }, [])

  // disable current location since the riddle locations are more importan
  // useEffect(() => {
  //   navigator?.geolocation.getCurrentPosition((position: GeolocationPosition) => {
  //     console.log("current position changed")
  //     const { longitude, latitude } = position.coords
  //     const latLng: google.maps.LatLng = new google.maps.LatLng({
  //       lat: latitude,
  //       lng: longitude,
  //     })
  //     console.log(latLng.toJSON())
  //     setCurrentPosition(latLng)
  //   })
  // }, [])

  //function that is calling the directions service
  const calcDirections = () => {
    if (stops && stops.length > 1) {
      console.log("Calc new directions")
      console.log(stops)
      const origin = stops[0]
      const destination = stops![stops!.length - 1]
      const waypoints = stops.slice(1, -1).map((stop) => {
        return { location: stop, stopover: true }
      })
      console.log(`origin ${origin}`)
      console.log(`destination ${destination}`)
      console.log(`waypoints ${waypoints}`)
      const promise = directionsService.route(
        {
          origin: origin,
          destination: destination,
          waypoints: waypoints,
          travelMode: google.maps.TravelMode.WALKING,
        },
        (result, status) => {
          console.log(result)
          if (status === google.maps.DirectionsStatus.OK && result) {
            console.log("got directions")
            console.log(result)

            directionsDisplay.setDirections(result)
            if (routePolyline.current) {
              routePolyline.current.setMap(null)
            }
            routePolyline.current = new google.maps.Polyline({
              path: result.routes[0].overview_path,
              strokeColor: Colors.Primary,
              strokeOpacity: 1.0,
              strokeWeight: 5,
            })
            routePolyline.current.setMap(map)
          } else {
            console.error(`error fetching directions ${result}`)
            window.alert(`Directions request failed due to ${status}`)
          }
        },
      )
    }
  }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={currentPosition ?? undefined}
      zoom={17}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={options}>
      {/*{directions !== null ? <DirectionsRenderer directions={directions} options={{ suppressMarkers: true }} /> : <></>}*/}
      {stops != null ? (
        stops.map((stop, index) => (
          <MarkerF
            key={`${stop.lat}-${stop.lat}-${index}`}
            // icon={locationDisplay}
            position={stop!}
            label={"" + (index + 1)}
          />
        ))
      ) : (
        <></>
      )}
    </GoogleMap>
  ) : (
    <CircularProgress />
  )
}
