import { Grid } from "@mui/material"
import { useAppSelector } from "../../../../app/hooks"
import { SelectLanguages } from "../../experienceFormSlice"
import { IntroTextInputFields } from "./introTextInputFields"

export const ExperienceTextInput = () => {
  const languages = useAppSelector(SelectLanguages)
  console.log(languages)
  return (
    <Grid container item xs={12} spacing={2}>
      {languages.map((language) => {
        return (
          <IntroTextInputFields
            key={`${language.id}-${language.languageCode}-${language.countryCode}`}
            language={language}
          />
        )
      })}
    </Grid>
  )
}
