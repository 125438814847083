import { Grid } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Navigation } from "../../app/utils/components"
import { RiddleMap } from "../../i18"
import { MapDisplay } from "./mapDisplay"

export const MapDisplayPage = () => {
  const { t } = useTranslation()
  return (
    <Grid container maxWidth="95%" spacing={2} sx={{ margin: "auto" }} display={{ xs: "flex", md: "flex" }}>
      <Navigation pageName={t(RiddleMap.pageName)} />
      <Grid container item xs={10} display={{ xs: "flex", md: "flex" }}>
        <MapDisplay />
      </Grid>
    </Grid>
  )
}
