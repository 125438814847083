import { useNavigate } from "react-router-dom"
import * as React from "react"
import { SyntheticEvent, useEffect, useState } from "react"
import { useJoinExperienceQuery } from "../api/cityriddlerApi"
import { mapExperienceToForm } from "../experienceForm/experienceMapper"
import { useTranslation } from "react-i18next"
import { TextInput } from "../../app/utils/components/input/textInput"
import { ExperienceFormText } from "../../i18"
import { CrLoadingOverlay } from "../../app/utils/components/overlay/crLoadingOverlay"
import { CircularProgress, Grid } from "@mui/material"
import { CityRiddlerSubmitButton } from "../../app/themes"

export const ExperienceByCodeForm = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [joinCode, setJoinCode] = useState<string>()
  const [loadExp, setLoadExp] = useState(false)
  const { data: experience, isLoading } = useJoinExperienceQuery(
    {
      joinCode: joinCode!,
      countJoin: false,
    },
    { skip: !loadExp },
  )

  useEffect(() => {
    if (experience) {
      console.log(experience)
      navigate(`/experienceForm`, {
        state: mapExperienceToForm(experience, true),
      })
    }
  }, [experience])

  const handleSubmit = async (event: SyntheticEvent) => {
    console.log("submit")
    event.preventDefault()
    setLoadExp(true)
  }

  const onChange = (text: string): void => {
    setJoinCode(text.toLowerCase())
  }

  return (
    <CrLoadingOverlay active={loadExp} label="Lade Abenteuer">
      <Grid container spacing={3} sx={{ marginTop: 0, marginBottom: 5 }}>
        <Grid item xs={3}>
          <TextInput
            id="joinCode"
            label={`${t(ExperienceFormText.experienceJoinCode)}`}
            handleChange={onChange}
            multiline={false}
            error={false}
            required={false}
            value={""}
            infoText={"Info Text"}
          />
        </Grid>
        <Grid item xs={3}>
          <CityRiddlerSubmitButton type="submit" variant="contained" disabled={isLoading} onClick={handleSubmit}>
            Lade {isLoading && <CircularProgress />}
          </CityRiddlerSubmitButton>
        </Grid>
      </Grid>
    </CrLoadingOverlay>
  )
}
