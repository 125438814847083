import { ClickAwayListener, Tooltip } from "@mui/material"
import InfoIcon from "@mui/icons-material/Info"
import React from "react"

interface TooltipIconProps {
  text: String
}

export const TooltipIcon = (props: TooltipIconProps): JSX.Element => {
  const [open, setOpen] = React.useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div style={{ margin: "5px" }}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={props.text}
          arrow>
          <InfoIcon onClick={handleTooltipOpen}></InfoIcon>
        </Tooltip>
      </div>
    </ClickAwayListener>
  )
}
