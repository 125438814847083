import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import type { AppDispatch, RootState } from "./store"
import { DependencyList, EffectCallback, useEffect, useRef } from "react"
import _ from "lodash"

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export function useDeepCompareEffect(callback: EffectCallback, dependencies?: DependencyList) {
  const currentDependenciesRef = useRef<DependencyList | undefined>()

  if (!_.isEqual(currentDependenciesRef.current, dependencies)) {
    currentDependenciesRef.current = dependencies
  }

  useEffect(callback, [currentDependenciesRef.current])
}
