import { SortableItem } from "@thaddeusjiang/react-sortable-list"
import { Divider, Grid, ListItem, ListItemText, Typography } from "@mui/material"
import * as React from "react"
import { SortableExperienceStep } from "../../../../app/data"
import { extractText } from "../../../../app/utils/components/text/internationalTextService"

export const SortableExperienceStepListItem = (props: { item: SortableExperienceStep; index: number }) => (
  <SortableItem id={props.item.id}>
    <>
      <ListItem alignItems="flex-start">
        <ListItemText
          primary={props.index + 1 + ": " + props.item.name}
          secondary={
            <Grid container>
              <Grid item xs={12}>
                <Typography>{extractText(props.item.riddle.description).text}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography color="text.primary">{props.item.address}</Typography>
              </Grid>
            </Grid>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  </SortableItem>
)

export const buildListItem =
  () =>
  ({ items }: { items: SortableExperienceStep[] }) => (
    <>
      {items.map((item: SortableExperienceStep, index: number) => (
        <SortableExperienceStepListItem key={item.id} item={item} index={index} />
      ))}
    </>
  )
