import {
  SelectIsError,
  SelectIsNavigationImageFileAvailable,
  SelectNavigationImageUrl,
  setNavigationImageFile,
} from "../riddleFormSlice"
import { FileInput } from "../../../app/utils/components/fileUpload"
import { RiddleFormText } from "../../../i18"
import { useTranslation } from "react-i18next"

export const NavigationImageInput = () => {
  const { t } = useTranslation()
  return (
    <FileInput
      errorSelector={SelectIsError}
      fileSelector={SelectIsNavigationImageFileAvailable}
      upload={setNavigationImageFile}
      urlSelector={SelectNavigationImageUrl}
      label={t(RiddleFormText.navigationImage)}
      infoText={t(RiddleFormText.navigationImageInfo)}
      required={false}
    />
  )
}
