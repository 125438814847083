import { Card, CardContent, CircularProgress, Typography } from "@mui/material"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { Colors } from "../../../../app/colors"
import { CitiyRiddlerDivider } from "../../../../app/themes"
import { AuthContext } from "../../../../firebase/authProvider"
import { ProfileText } from "../../../../i18"
import { useGetStatisticQuery, useGetUserStatisticsQuery, UserStatistic } from "../../../api/cityriddlerApi"

let voidConst: void

export const RiddleStatuses = () => {
  const { t } = useTranslation()
  const { user } = useContext(AuthContext)

  const { data: userData, isLoading: userIsLoading } = useGetUserStatisticsQuery(
    { userId: user?.uid! },
    { skip: !user || user!.admin },
  )
  const { data: adminData, isLoading: adminIsLoading } = useGetStatisticQuery(voidConst, {
    skip: !user || !user!.admin,
  })

  const getData = (): UserStatistic | undefined => {
    if (user) {
      if (user.admin) {
        return adminData
      } else {
        return userData
      }
    }
  }

  const calculateActiveCount = (isActive: boolean) => {
    const count =
      getData()?.riddles.filter((riddle) => {
        return riddle.metadata.active === isActive
      }).length ?? 0

    return count < 10 ? `0${count}` : count
  }

  return (
    <Card
      sx={{
        backgroundColor: Colors.TiffanyBlue,
        width: "20vw",
        height: "10.6vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "20px",
        padding: "0.7vw",
      }}
      elevation={3}>
      <CardContent
        sx={{
          textAlign: "left",
          width: "100%",
        }}>
        <Typography
          variant="h3"
          sx={{
            color: Colors.PrimaryDark,
            display: "inline",
            marginLeft: "1vw",
            marginRight: "4vw",
            fontSize: "2.5vw",
            fontWeight: "30",
          }}>
          {userIsLoading || adminIsLoading ? <CircularProgress /> : calculateActiveCount(true)}
        </Typography>
        <Typography
          variant="h3"
          sx={{
            color: Colors.PrimaryDark,
            display: "inline",
            marginRight: "4vw",
            fontSize: "2vw",
            fontWeight: "30",
          }}>
          {t(ProfileText.active)}
        </Typography>
        <CitiyRiddlerDivider />
        <Typography
          variant="h3"
          sx={{
            color: Colors.PrimaryDark,
            display: "inline",
            marginLeft: "1vw",
            marginRight: "4vw",
            fontSize: "2.5vw",
            fontWeight: "30",
          }}>
          {userIsLoading || adminIsLoading ? <CircularProgress /> : calculateActiveCount(false)}
        </Typography>
        <Typography
          variant="h3"
          sx={{
            color: Colors.PrimaryDark,
            display: "inline",
            marginRight: "4vw",
            fontSize: "2vw",
            fontWeight: "30",
          }}>
          {t(ProfileText.inactive)}
        </Typography>
      </CardContent>
    </Card>
  )
}
