import React, { ChangeEvent, SyntheticEvent, useEffect, useMemo, useState } from "react"
import { Experience, Riddle, useJoinExperienceQuery } from "../../../../features/api/cityriddlerApi"
import { MultiselectCheckbox, MultiselectOption } from "../checkboxInput/multiselectCheckbox"
import {
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material"
import { CrLoadingOverlay } from "../overlay/crLoadingOverlay"
import { TextInput } from "../input/textInput"
import { CityRiddlerSubmitButton } from "../../../themes"
import { extractText } from "../text/internationalTextService"
import { FetchBaseQueryError } from "@reduxjs/toolkit/query"
import { useDeepCompareEffect } from "../../../hooks"

type MenuItemOption = {
  value: string
  name: string
}

interface RiddleFilterProps {
  riddles: Riddle[] | undefined
  handleFilterChange: (filtered: Riddle[]) => void
}

const defaultCountry = "AT"
const defaultCity = "Vienna"
const defaultExcludedFlagNotes = [
  "hernals",
  "Schonbrunn",
  "Avenue",
  "umadum",
  "TEDx TU",
  "TEDx Volkstheater",
  "waldpark",
  "christmas",
  "callelibre",
]

export const RiddleFilter = (props: RiddleFilterProps) => {
  const [countryFilter, setCountryFilter] = React.useState<MenuItemOption | null>(null)
  const [cityFilter, setCityFilter] = React.useState<MenuItemOption | null>(null)
  const [flagNoteFilter, setFlagNoteFilter] = useState<MultiselectOption[]>([])

  const [countryOptions, setCountryOptions] = useState<MenuItemOption[]>([])
  const [cityOptions, setCityOptions] = useState<MenuItemOption[]>([])
  const [flagNoteOptions, setFlagNoteOptions] = useState<MultiselectOption[]>([])

  const [joinCode, setJoinCode] = useState<string>()
  const [loadExp, setLoadExp] = useState(false)
  const { data, isLoading, error } = useJoinExperienceQuery(
    {
      joinCode: joinCode!,
      countJoin: false,
    },
    { skip: !loadExp },
  )
  const [loadedExperience, setLoadedExperience] = useState<Experience | null>()

  const stableRiddles = useMemo(() => props.riddles, [props.riddles])

  useEffect(() => {
    console.log("data change")
    if (data) {
      setLoadExp(false)
      setLoadedExperience(data)
    }
  }, [data])

  useEffect(() => {
    if (error) setLoadExp(false)
    console.log(error)
  }, [error])

  const handleJoinCodeSubmit = async (event: SyntheticEvent) => {
    event.preventDefault()
    setLoadExp(true)
  }

  const resetJoinCode = () => {
    console.log("resetJoinCode")
    setJoinCode("")
    setLoadedExperience(null)
  }

  const onChangeJoinCode = (text: string): void => {
    setJoinCode(text.toLowerCase())
  }

  useDeepCompareEffect(() => {
    console.log("useDeepCompareEffect")
    if (props.riddles) calcFilter(props.riddles)
  }, [props.riddles])

  const calcFilter = (riddles: Riddle[]) => {
    console.log("calcFilter")
    const flagNoteList: string[] = []
    const countryList: string[] = []
    const cityList: string[] = []

    riddles.forEach((riddle: Riddle) => {
      const country = riddle.riddleMetadata.location.address?.country
      const city = riddle.riddleMetadata.location.address?.city
      const flagNote = riddle.metadata.flagNote

      if (flagNote && flagNote.length > 0 && !flagNoteList.includes(flagNote)) {
        flagNoteList.push(flagNote)
      }
      if (city && city.length > 0 && !cityList.includes(city)) {
        cityList.push(city)
      }
      if (country && country.length > 0 && !countryList.includes(country)) {
        countryList.push(country)
      }
    })

    const flagNoteOptions = flagNoteList.map((flagNote, index) => {
      return {
        id: index.toString(),
        name: flagNote,
      } as MultiselectOption
    })

    setFlagNoteOptions(flagNoteOptions)
    const defaultOptions = flagNoteOptions.filter((option) => !defaultExcludedFlagNotes.includes(option.name))
    setFlagNoteFilter(defaultOptions)

    const countryOptions = countryList.map((country, index) => {
      return {
        value: index.toString(),
        name: country,
      } as MenuItemOption
    })
    setCountryOptions(countryOptions)
    const defaultCountryOption = countryOptions.find((option) => option.name === defaultCountry)
    if (defaultCountryOption) setCountryFilter(defaultCountryOption)

    const cityOptions = cityList.map((city, index) => {
      return {
        value: index.toString(),
        name: city,
      } as MenuItemOption
    })
    setCityOptions(cityOptions)
    const defaultCityOption = cityOptions.find((option) => option.name === defaultCity)
    if (defaultCityOption) setCityFilter(defaultCityOption)
  }

  useEffect(() => {
    console.log("filter change")
    if (stableRiddles && (countryFilter || cityFilter || flagNoteFilter || loadedExperience)) {
      const filteredSteps = loadedExperience ? loadedExperience.steps.map((step) => step.riddle.id) : []
      console.log("pre filter: " + stableRiddles.length)
      const filtered = stableRiddles?.filter((riddle: Riddle) => {
        if (filteredSteps?.includes(riddle.id)) return false

        const country = riddle.riddleMetadata.location.address?.country
        const city = riddle.riddleMetadata.location.address?.city
        const flagNote = riddle.metadata.flagNote

        return (
          (country && countryFilter ? countryFilter?.name === country : true) &&
          (city && cityFilter ? cityFilter?.name === city : true) &&
          (flagNote && flagNoteFilter.length > 0
            ? flagNoteFilter.map((flagNote) => flagNote.name).includes(flagNote)
            : true)
        )
      })
      console.log("post filter: " + filtered.length ?? 0)
      props.handleFilterChange(filtered)
    }
  }, [countryFilter, cityFilter, flagNoteFilter, loadedExperience])

  const handleCountryFilterChange = (event: SelectChangeEvent) => {
    const selectedCountry = event.target.value as string
    const country = countryOptions.find((option) => option.value === selectedCountry)
    if (country) setCountryFilter(country)
  }

  const handleCityFilterChange = (event: SelectChangeEvent) => {
    const selectedCity = event.target.value as string
    const city = cityOptions.find((option) => option.value === selectedCity)
    if (city) setCityFilter(city)
    console.log("handleCityFilterChange")
  }

  const handleFlagNoteFilterChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const idAndName = event.target.name.split("-")
    const clicked = {
      id: idAndName[0],
      name: idAndName[1],
    }

    if (checked) {
      const newFilter = [...flagNoteFilter, clicked]
      setFlagNoteFilter(newFilter)
    } else {
      const removed = flagNoteFilter.filter((flagNote) => flagNote.id !== clicked.id)
      setFlagNoteFilter(removed)
    }
    console.log("handleFlagNoteFilterChange")
  }

  function extractExperienceName(): string {
    if (loadedExperience) {
      let expName: string
      if (
        loadedExperience.infos &&
        loadedExperience.infos.displayName &&
        loadedExperience.infos.displayName.length > 0
      ) {
        expName = extractText(loadedExperience.infos.displayName).text
      } else {
        expName = "Abenteuer " + loadedExperience.id!.toString()
      }
      return "Aktueller Filter: " + expName
    } else {
      return ""
    }
  }

  function extractError(): string {
    const queryError = error as FetchBaseQueryError
    return "error" + queryError.status || "unknown error"
  }

  return (
    <Grid container spacing={3} alignContent="center">
      <Grid item xs={6} container spacing={1} alignContent="center">
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="select-country">Land</InputLabel>
            <Select
              labelId="select-country-label"
              id="select-country"
              value={countryFilter?.value ?? "0"}
              label="Land"
              onChange={handleCountryFilterChange}>
              {countryOptions.map((option) => {
                return (
                  <MenuItem key={"country-option-" + option.value} value={option.value}>
                    {option.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="select-city">Stadt</InputLabel>
            <Select
              labelId="select-city-label"
              id="select-city"
              value={cityFilter?.value ?? "0"}
              label="Stadt"
              onChange={handleCityFilterChange}>
              {cityOptions.map((option) => {
                return (
                  <MenuItem key={"city-option-" + option.value} value={option.value}>
                    {option.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <CrLoadingOverlay active={loadExp} label="Lade Abenteuer">
            <Grid container spacing={2} alignItems="center" justifyContent="center">
              <Grid item xs={6}>
                <TextInput
                  id="joinCode"
                  label={"Filter by Experience"}
                  handleChange={onChangeJoinCode}
                  multiline={false}
                  error={false}
                  required={false}
                  value={joinCode ?? ""}
                  infoText={"Filter out riddles used by experience"}
                />
              </Grid>
              <Grid item xs={3}>
                <CityRiddlerSubmitButton
                  type="submit"
                  variant="contained"
                  disabled={isLoading}
                  onClick={handleJoinCodeSubmit}>
                  Lade {isLoading && <CircularProgress />}
                </CityRiddlerSubmitButton>
              </Grid>
              <Grid item xs={3}>
                <CityRiddlerSubmitButton type="submit" variant="contained" onClick={resetJoinCode}>
                  Reset
                </CityRiddlerSubmitButton>
              </Grid>
            </Grid>
          </CrLoadingOverlay>
        </Grid>
        {loadedExperience && (
          <Grid item xs={12}>
            <Typography>{extractExperienceName()}</Typography>
          </Grid>
        )}
        {error && (
          <Grid item xs={12}>
            <Typography>{extractError()}</Typography>
          </Grid>
        )}
      </Grid>
      <Grid item container xs={6}>
        <MultiselectCheckbox
          label={"Markierungen"}
          infoText={"Markierungen"}
          options={flagNoteOptions}
          selected={flagNoteFilter.map((option) => option.id)}
          space={4}
          gridSpacing={0}
          handleChange={handleFlagNoteFilterChange}
          error={false}
        />
      </Grid>
    </Grid>
  )
}
