import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { getAuth } from "firebase/auth"
import { app } from "../auth/firebase/firebaseSetup"

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_API,
    prepareHeaders: async (headers) => {
      const token = await getAuth(app).currentUser?.getIdToken()

      if (token) {
        headers.set("authorization", `Bearer ${token}`)
      }
      return headers
    },
  }),
  keepUnusedDataFor: 60 * 60, //1 hour, keepUnusedDataFor is in seconds
  tagTypes: [],
  endpoints: (builder) => ({}),
})
