import { Grid } from "@mui/material"
import { Navigation } from "../../app/utils/components"
import { ExperienceForm } from "./experienceForm"

export const ExperienceFormPage = () => {
  return (
    <Grid container maxWidth="95%" spacing={2} sx={{ margin: "auto" }} display={{ xs: "flex", md: "flex" }}>
      <Navigation pageName="Erstellen" />
      <Grid container item xs={10}>
        <ExperienceForm />
      </Grid>
    </Grid>
  )
}
