import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { SelectMaxJoins, setMaxJoins } from "../experienceFormSlice"
import { SelectIsError } from "../../riddleForm/riddleFormSlice"
import { ExperienceFormText } from "../../../i18"
import { NumberInput } from "../../../app/utils/components/input/numberInput"

export const ExperienceMaxJoins = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isFormError = useAppSelector(SelectIsError)
  const maxJoins = useAppSelector(SelectMaxJoins)
  const isError = isFormError && !maxJoins

  const onChange = (text: number): void => {
    dispatch(setMaxJoins(text))
  }

  return (
    <>
      <NumberInput
        id="maxJoins"
        error={isError}
        label={`${t(ExperienceFormText.experienceMaxJoins)}`}
        infoText={`${t(ExperienceFormText.experienceMaxJoins)}`}
        handleChange={onChange}
        value={maxJoins}
      />
    </>
  )
}
