import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"

import { Switch } from "../../../app/utils/components/input/switch"
import { SelectActive, setActive } from "../riddleFormSlice"
import { RiddleFormText } from "../../../i18"

export const RiddleActive = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const active = useAppSelector(SelectActive)

  const onChange = (active: boolean): void => {
    dispatch(setActive(active))
  }

  return (
    <>
      <Switch
        id="active"
        required={true}
        label={`${t(RiddleFormText.riddleActive)}`}
        infoText={`${t(RiddleFormText.riddleActiveInfo)}`}
        handleChange={onChange}
        value={active}
      />
    </>
  )
}
