import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ExperienceFormState, IntroWithOrderIndex } from "../../app/data"
import { RootState } from "../../app/store"
import { ExperienceStep, InternationalText, Language } from "../api/cityriddlerApi"

const initialState: ExperienceFormState = {
  id: null,
  steps: [],
  joinCode: "",
  needsPayment: true,
  event: false,
  introHeaders: [],
  introTexts: [],
  introVideoUrl: null,
  outroVideoUrl: null,
  displayName: [],
  descriptionShort: [],
  descriptionLong: [],
  coverImage: null,
  tourId: "tour_medium",
  maxJoins: null,
  usePoints: true,
  overviewImage: null,
  finishImage: null,
  forceOffline: false,
  isError: false,
  languages: [],
  overviewImageFile: null,
  finishImageFile: null,
  coverImageFile: null,
  minimized: false,
  edit: false,
}

const experienceFormSlice = createSlice({
  name: "experienceForm",
  initialState: initialState,
  reducers: {
    setIsError(state, action: PayloadAction<boolean>) {
      state.isError = action.payload
    },
    setSteps(state, action: PayloadAction<ExperienceStep[]>) {
      state.steps = [...action.payload]
    },
    setJoinCode(state, action: PayloadAction<string>) {
      state.joinCode = action.payload
    },
    setNeedsPayment(state, action: PayloadAction<boolean>) {
      state.needsPayment = action.payload
    },
    setIsEvent(state, action: PayloadAction<boolean>) {
      state.event = action.payload
    },
    setIntroHeaders(state, action: PayloadAction<IntroWithOrderIndex[]>) {
      const languageId = action.payload[0].language.id
      let updatedState = state.introHeaders.filter((header) => header.language.id !== languageId)
      updatedState.push(...action.payload)
      state.introHeaders = [...updatedState]
    },
    setIntroTexts(state, action: PayloadAction<IntroWithOrderIndex[]>) {
      const languageId = action.payload[0].language.id
      let updatedState = state.introTexts.filter((texts) => texts.language.id !== languageId)
      updatedState.push(...action.payload)
      state.introTexts = [...updatedState]
    },
    setIntroVideo(state, action: PayloadAction<string>) {
      state.introVideoUrl = action.payload
    },
    setOutroVideo(state, action: PayloadAction<string>) {
      state.outroVideoUrl = action.payload
    },
    setDisplayName(state, action: PayloadAction<InternationalText>) {
      const displayName = action.payload
      state.displayName?.forEach((name, index) => {
        if (name.language.id === displayName.language.id) {
          state.displayName![index] = { ...displayName }
        }
      })
    },
    setDescriptionShort(state, action: PayloadAction<InternationalText>) {
      const descriptionShort = action.payload
      state.descriptionShort?.forEach((desc, index) => {
        if (desc.language.id === descriptionShort.language.id) {
          state.descriptionShort![index] = { ...descriptionShort }
        }
      })
    },
    setDescriptionLong(state, action: PayloadAction<InternationalText>) {
      const descriptionLong = action.payload
      state.descriptionLong?.forEach((desc, index) => {
        if (desc.language.id === descriptionLong.language.id) {
          state.descriptionLong![index] = { ...descriptionLong }
        }
      })
    },
    setTourId(state, action: PayloadAction<string>) {
      state.tourId = action.payload
    },
    setMaxJoins(state, action: PayloadAction<number>) {
      state.maxJoins = action.payload
    },
    setUsePoints(state, action: PayloadAction<boolean>) {
      state.usePoints = action.payload
    },
    setCoverImageFile(state, action: PayloadAction<File>) {
      state.coverImageFile = action.payload
    },
    setOverviewImageFile(state, action: PayloadAction<File>) {
      state.overviewImageFile = action.payload
    },
    setFinishImageFile(state, action: PayloadAction<File>) {
      state.finishImageFile = action.payload
    },
    setForceOffline(state, action: PayloadAction<boolean>) {
      state.forceOffline = action.payload
    },
    setLanguages(state, action: PayloadAction<Language[]>) {
      state.languages = [...action.payload]
    },
    addDataForLanguage(state, action: PayloadAction<Language>) {
      const extendedLanguage = action.payload
      console.log("addDataForLanguage")
      console.log(extendedLanguage)
      state.introHeaders.push({
        language: extendedLanguage,
        text: "",
        orderIndex: 0,
      })
      state.introTexts.push({
        language: extendedLanguage,
        text: "",
        orderIndex: 0,
      })
      state.displayName?.push({
        language: extendedLanguage,
        text: "",
      })
      state.descriptionShort?.push({
        language: extendedLanguage,
        text: "",
      })
      state.descriptionLong?.push({
        language: extendedLanguage,
        text: "",
      })
    },
    removeDataForLanguage(state, action: PayloadAction<number>) {
      const languageId = Number(action.payload)
      console.log("removeDataForLanguage")
      const filteredHeaders = state.introHeaders.filter((element) => {
        return element.language.id !== languageId
      })
      state.introHeaders = [...filteredHeaders]

      const filteredTexts = state.introHeaders.filter((element) => {
        return element.language.id !== languageId
      })
      state.introTexts = [...filteredTexts]

      const filteredDisplayName = state.displayName?.filter((element) => {
        return element.language.id !== languageId
      })
      if (filteredDisplayName) state.displayName = [...filteredDisplayName]

      const filteredDescriptionShort = state.descriptionShort?.filter((element) => {
        return element.language.id !== languageId
      })
      if (filteredDescriptionShort) state.descriptionShort = [...filteredDescriptionShort]

      const filteredDescriptionLong = state.descriptionLong?.filter((element) => {
        return element.language.id !== languageId
      })
      if (filteredDescriptionLong) state.descriptionLong = [...filteredDescriptionLong]
    },
    setFormState(state, action: PayloadAction<ExperienceFormState>) {
      console.log(action.payload)
      state.steps = { ...action.payload.steps }
      state.joinCode = action.payload.joinCode
      state.needsPayment = action.payload.needsPayment
      state.event = action.payload.event
      state.introHeaders = [...action.payload.introHeaders]
      state.introTexts = [...action.payload.introTexts]
      state.introVideoUrl = action.payload.introVideoUrl
      state.outroVideoUrl = action.payload.outroVideoUrl
      if (action.payload.displayName) state.displayName = [...action.payload.displayName]
      if (action.payload.descriptionShort) state.descriptionShort = [...action.payload.descriptionShort]
      if (action.payload.descriptionLong) state.descriptionLong = [...action.payload.descriptionLong]
      state.outroVideoUrl = action.payload.outroVideoUrl
      state.tourId = action.payload.tourId
      state.maxJoins = action.payload.maxJoins
      state.usePoints = action.payload.usePoints
      if (action.payload.coverImage) state.coverImage = { ...action.payload.coverImage }
      if (action.payload.overviewImage) state.overviewImage = { ...action.payload.overviewImage }
      if (action.payload.finishImage) state.finishImage = { ...action.payload.finishImage }
      state.forceOffline = action.payload.forceOffline
    },
    setInitialState(state) {
      console.log(initialState)
      state.steps = { ...initialState.steps }
      state.joinCode = initialState.joinCode
      state.needsPayment = initialState.needsPayment
      state.event = initialState.event
      state.introHeaders = [...initialState.introHeaders]
      state.introTexts = [...initialState.introTexts]
      state.introVideoUrl = initialState.introVideoUrl
      state.outroVideoUrl = initialState.outroVideoUrl
      if (initialState.displayName) state.displayName = [...initialState.displayName]
      if (initialState.descriptionShort) state.descriptionShort = [...initialState.descriptionShort]
      if (initialState.descriptionLong) state.descriptionLong = [...initialState.descriptionLong]
      state.outroVideoUrl = initialState.outroVideoUrl
      state.tourId = initialState.tourId
      state.maxJoins = initialState.maxJoins
      state.usePoints = initialState.usePoints
      if (initialState.coverImage) state.coverImage = { ...initialState.coverImage }
      if (initialState.overviewImage) state.overviewImage = { ...initialState.overviewImage }
      if (initialState.finishImage) state.finishImage = { ...initialState.finishImage }
      state.forceOffline = initialState.forceOffline
    },
  },
})

export const {
  setIsError,
  setSteps,
  setJoinCode,
  setNeedsPayment,
  setIsEvent,
  setIntroHeaders,
  setIntroTexts,
  setIntroVideo,
  setOutroVideo,
  setDisplayName,
  setDescriptionShort,
  setDescriptionLong,
  setTourId,
  setMaxJoins,
  setUsePoints,
  setCoverImageFile,
  setOverviewImageFile,
  setFinishImageFile,
  setForceOffline,
  setLanguages,
  addDataForLanguage,
  removeDataForLanguage,
  setFormState,
  setInitialState,
} = experienceFormSlice.actions

export default experienceFormSlice.reducer

export const SelectFormState = (state: RootState) => state.experienceForm
export const SelectIsError = (state: RootState) => state.experienceForm.isError
export const SelectSteps = (state: RootState) => state.experienceForm.steps
export const SelectJoinCode = (state: RootState) => state.experienceForm.joinCode
export const SelectNeedsPayment = (state: RootState) => state.experienceForm.needsPayment
export const SelectIsEvent = (state: RootState) => state.experienceForm.event
export const SelectIntroVideo = (state: RootState) => state.experienceForm.introVideoUrl
export const SelectOutroVideo = (state: RootState) => state.experienceForm.outroVideoUrl
export const SelectTourId = (state: RootState) => state.experienceForm.tourId
export const SelectMaxJoins = (state: RootState) => state.experienceForm.maxJoins
export const SelectUsePoints = (state: RootState) => state.experienceForm.usePoints
export const SelectForceOffline = (state: RootState) => state.experienceForm.forceOffline
export const SelectLanguages = (state: RootState) => state.experienceForm.languages
export const SelectIntroTextInputFieldsData = createSelector(
  [
    (state: RootState) => state.experienceForm.introHeaders,
    (state: RootState) => state.experienceForm.introTexts,
    (state: RootState) => state.experienceForm.displayName,
    (state: RootState) => state.experienceForm.descriptionShort,
    (state: RootState) => state.experienceForm.descriptionLong,
    (state: RootState, language: Language) => language,
  ],
  (introHeaders, introTexts, displayName, descriptionShort, descriptionLong, language) => {
    return {
      introHeaders: introHeaders.filter((tl) => tl.language.id === language.id),
      introTexts: introTexts.filter((desc) => desc.language.id === language.id),
      displayName: displayName?.find((desc) => desc.language.id === language.id),
      descriptionShort: descriptionShort?.find((desc) => desc.language.id === language.id),
      descriptionLong: descriptionLong?.find((desc) => desc.language.id === language.id),
    }
  },
)
export const SelectIsOverviewImageFileAvailable = (state: RootState) => state.experienceForm.overviewImageFile === null
export const SelectIsFinishImageFileAvailable = (state: RootState) => state.experienceForm.finishImageFile === null
export const SelectIsCoverImageFileAvailable = (state: RootState) => state.experienceForm.coverImageFile === null
export const SelectOverviewImageUrl = (state: RootState) => state.experienceForm.overviewImage?.url ?? null
export const SelectFinishImageUrl = (state: RootState) => state.experienceForm.finishImage?.url ?? null
export const SelectCoverImageUrl = (state: RootState) => state.experienceForm.coverImage?.url ?? null
