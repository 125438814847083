import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { SelectTourId, setNeedsPayment, setTourId } from "../experienceFormSlice"
import { SelectIsError } from "../../riddleForm/riddleFormSlice"
import { ExperienceFormText } from "../../../i18"
import { FormControl } from "@mui/material"
import React, { ChangeEvent } from "react"
import { CrRadioGroup, CrRadioValue } from "../../../app/utils/components/input/radio"

export const ExperienceTourId = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isFormError = useAppSelector(SelectIsError)
  const tourId = useAppSelector(SelectTourId)
  const isError = isFormError && !tourId

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const tourIdValue = event.target.value
    dispatch(setTourId(tourIdValue))
    let needsPayment = true

    switch (tourIdValue) {
      case "tour_medium":
        needsPayment = true
        break
      case "tour_medium_50":
        needsPayment = true
        break
      case "tour_medium_100":
        needsPayment = false
        break
    }
    dispatch(setNeedsPayment(needsPayment))
  }

  const items: CrRadioValue[] = [
    {
      value: "tour_medium",
      label: t(ExperienceFormText.pay),
    } as CrRadioValue,
    {
      value: "tour_medium_50",
      label: t(ExperienceFormText.pay50),
    } as CrRadioValue,
    {
      value: "tour_medium_100",
      label: t(ExperienceFormText.pay100),
    } as CrRadioValue,
  ]

  return (
    <FormControl error={isError} required sx={{ minWidth: "100%" }}>
      <CrRadioGroup
        label={t(ExperienceFormText.experienceNeedsPayment)}
        required={true}
        infoText={`${t(ExperienceFormText.experienceNeedsPayment)}`}
        value={tourId}
        onChange={handleChange}
        radioItems={items}
      />
    </FormControl>
  )
}
