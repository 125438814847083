import { PriorityText, RiddleFormState } from "../../app/data"
import { InternationalText, Riddle } from "../api/cityriddlerApi"
import { createMetadata } from "../../app/service/metadataService"

export type PriorityTextApi = {
  text: InternationalText[]
  priority: number
}

export function mapPriorityTextToApi(texts: PriorityText[]): PriorityTextApi[] {
  const mappedTexts: PriorityTextApi[] = []
  if (texts && texts.length > 0) {
    texts.forEach((text) => {
      const mapped = mappedTexts.find((t) => t.priority === text.priority)

      if (mapped) {
        mapped.text.push(text)
      } else {
        mappedTexts.push({
          text: [text],
          priority: text.priority,
        } as PriorityTextApi)
      }
    })
  }
  return mappedTexts
}

export function mapPriorityTextToForm(texts: PriorityTextApi[]): PriorityText[] {
  const mappedTexts: PriorityText[] = []

  if (texts && texts.length > 0) {
    texts.forEach((text) => {
      text.text.forEach((t) => {
        mappedTexts.push({
          ...t,
          priority: text.priority,
        } as PriorityText)
      })
    })
  }
  return mappedTexts
}

export function mapFormToApi(
  formState: RiddleFormState,
  riddleImageUrl: string,
  locationImageUrl: string,
  navigationImageUrl?: string | null,
): Riddle {
  const {
    title,
    description,
    solution,
    navigation,
    difficulty,
    hints,
    categoryIds,
    facts,
    location,
    solutionType,
    choices,
  } = formState

  const metadata = createMetadata()
  let navigationImage
  if (navigationImageUrl) {
    navigationImage = {
      url: navigationImageUrl,
    }
  }
  const mappedHints = mapPriorityTextToApi(hints)
  const mappedChoices = mapPriorityTextToApi(choices)

  const updatedLocation = { ...location }
  updatedLocation.image = { url: locationImageUrl }

  return {
    title: title,
    description: description,
    navigation: navigation,
    hints: mappedHints,
    facts: facts,
    image: {
      url: riddleImageUrl,
    },
    navigationImage: navigationImage,
    metadata: metadata!,
    riddleSolution: {
      solution: solution,
      solutionAlternatives: [],
      choices: mappedChoices,
      solutionType: solutionType,
      needsValidation: true,
    },
    riddleMetadata: {
      difficulty: difficulty,
      categoryIds: [...categoryIds],
      locationId: updatedLocation.id,
      location: updatedLocation,
    },
  } as Riddle
}

export function mapRiddleToForm(riddle: Riddle, edit: boolean): RiddleFormState {
  return {
    id: riddle.id,
    title: riddle.title,
    description: riddle.description,
    solution: riddle.riddleSolution?.solution ?? [],
    navigation: riddle.navigation,
    difficulty: riddle.riddleMetadata.difficulty,
    hints: mapPriorityTextToForm(riddle.hints),
    category: riddle.riddleMetadata.categories!,
    categoryIds: [...riddle.riddleMetadata.categoryIds],
    facts: riddle.facts,
    image: { url: riddle.image.url },
    navigationImage: riddle.navigationImage,
    location: riddle.riddleMetadata.location,
    solutionType: riddle.riddleSolution?.solutionType ?? "TEXT",
    choices: mapPriorityTextToForm(riddle.riddleSolution?.choices ?? []),
    isError: false,
    riddleImageFile: null,
    locationImageFile: null,
    navigationImageFile: null,
    locationImage: riddle.riddleMetadata.location.image,
    riddleImage: riddle.image,
    active: riddle.metadata.active,
    flagged: riddle.metadata.flagged,
    flagNote: riddle.metadata.flagNote,
    languages: riddle.title.map((t) => t.language),
    edit: edit,
  } as RiddleFormState
}
