import { DataFields } from "../utils/components/dataTable/dataTable"
import { Image } from "./imageModel"
import { Category, Fact, InternationalText, Language, Location, Riddle } from "../../features/api/cityriddlerApi"

export enum RiddleTypes {
  MultipleChoice = "MULTIPLE_CHOICE",
  Number = "NUMBER",
  Text = "TEXT",
}

export interface RiddleFormData {
  id: number | null
  title: InternationalText[]
  description: InternationalText[]
  solution: InternationalText[] | null
  navigation: InternationalText[] | null
  difficulty: "LOW" | "MEDIUM" | "HIGH"
  hints: PriorityText[]
  category: Category[]
  categoryIds: number[]
  facts: Fact[]
  navigationImage: Image | null
  locationImage: Image
  riddleImage: Image
  location: Location
  solutionType: "MULTIPLE_CHOICE" | "NUMBER" | "TEXT"
  choices: PriorityText[]
  active: boolean
  flagged: boolean
  flagNote: string
}

export interface Priority {
  priority: number
}

export type PriorityText = Priority & InternationalText

export interface RiddleFormHelpers {
  isError: boolean
  edit: boolean
  riddleImageFile: File | null
  locationImageFile: File | null
  navigationImageFile: File | null
  languages: Language[]
}

export type RiddleFormState = RiddleFormData & RiddleFormHelpers

export interface Sort {
  empty: boolean
  sorted: boolean
  unsorted: boolean
}

export interface DisplayedRiddleData extends DataFields {
  id: string
  index: number
  name: string
  description: string
  address: string
  category: string
  flagged: boolean
  flagNote: string
  status: boolean
  riddle: Riddle
}

export interface RiddleListData extends DataFields {
  city: string
  usage: number
  status: 0 | 1
  delete: "delete"
  edit: "edit"
}
