import { Grid, List, ListItem, ListItemIcon, ListItemText } from "@mui/material"
import * as React from "react"
import ErrorIcon from "@mui/icons-material/Error"
import { CityRiddlerFormLabel } from "../../../themes"

interface ErrorsProps {
  errors: String[] | undefined
}

export const Errors = (props: ErrorsProps): JSX.Element => {
  return props.errors && props.errors.length > 0 ? (
    <Grid container spacing={0} sx={{ border: "2px solid red", padding: "5px" }}>
      <Grid item xs={12}>
        <CityRiddlerFormLabel id="errors" focused={false}>
          {props.errors.length} Fehlermeldunge(n)
        </CityRiddlerFormLabel>
      </Grid>

      <Grid container aria-labelledby="errors">
        <List
        // subheader={
        //   <ListSubheader component="div" id="errors">
        //     {props.errors.length} Fehlermeldunge(n)
        //   </ListSubheader>
        // }
        >
          {props.errors.map((error, index) => {
            return (
              <Grid item xs={12} key={"grid-error-" + index}>
                <ListItem key={"error-" + index}>
                  <ListItemIcon>
                    <ErrorIcon style={{ color: "red" }} />
                  </ListItemIcon>
                  <ListItemText>{error}</ListItemText>
                </ListItem>
              </Grid>
            )
          })}
        </List>
      </Grid>
    </Grid>
  ) : (
    <></>
  )
}
