import { getAuth, User } from "firebase/auth"
import { createContext, useEffect, useState } from "react"
import { app, db } from "../features/auth/firebase/firebaseSetup"
import { doc, getDoc } from "firebase/firestore"

export const AuthContext = createContext<{ user: CrUser | null }>({ user: null })

export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState<CrUser | null>(null)

  useEffect(() => {
    getAuth(app).onAuthStateChanged(async (user) => {
      const crUser = user as CrUser
      if (user != null) {
        const userData = await getDoc(doc(db, "users", user.uid))
        if (userData) {
          // crUser.admin = userData.get("admin") ?? false
          crUser.admin = true
        }
      }
      setUser(crUser)
    })
  }, [])

  return <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
}

export interface CrUser extends User {
  admin: boolean
}
