import { CrMetadata } from "../../features/api/cityriddlerApi"
import { format } from "date-fns"
import { auth } from "../../features/auth/firebase/firebaseSetup"

export function createMetadata(): CrMetadata | undefined {
  const createdTime = format(new Date(), "yyyy-MM-dd'T'HH:mm")
  const user = auth.currentUser

  if (user) {
    return {
      flagged: false,
      flagNote: "",
      active: true,
      deleted: false,
      created: createdTime,
      updated: createdTime,
      creator: {
        id: user.uid,
        firstName: user.displayName?.split(" ")[0] ? user.displayName?.split(" ")[0] : "riddle",
        lastName: user.displayName?.split(" ")[1] ? user.displayName?.split(" ")[1] : "creator",
        userName: user.displayName ? user.displayName : "riddle creator",
        email: user.email!,
        anonymous: user.isAnonymous,
        creator: true,
        active: true,
        created: format(new Date(user.metadata.creationTime!), "yyyy-MM-dd'T'HH:mm"),
        updated: format(new Date(user.metadata.creationTime!), "yyyy-MM-dd'T'HH:mm"),
      },
      creatorId: user.uid,
    }
  }
  return undefined
}

export function getTimeStamp(): string {
  return format(new Date(), "yyyy-MM-dd'T'HH:mm")
}
