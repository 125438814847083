import { Alert, AlertTitle } from "@mui/material"

export enum AlertTypes {
  Error = "error",
  Warning = "warning",
  Info = "info",
  Success = "success",
}

export interface AlertProps {
  alertType: AlertTypes
  alertMessage: string
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  isError: boolean
}

export const AlertPopup = (props: AlertProps) => {
  return (
    <Alert
      severity={props.alertType}
      onClick={props.onClick}
      sx={
        props.isError
          ? {
              cursor: "pointer",
              position: "fixed",
              top: "0%",
              left: "50%",
              transform: "translate(-50%, 0%)",
              borderRadius: "5px",
            }
          : { borderRadius: "5px" }
      }>
      <AlertTitle>{props.alertType}</AlertTitle>
      {props.alertMessage}
    </Alert>
  )
}
