import { FormControl, Grid, Radio, RadioGroup, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { CityRiddlerFormLabel } from "../../../../app/themes"
import { RiddleFormText } from "../../../../i18"
import { RiddleTypes } from "../../../../app/data"
import { SelectIsError, SelectRiddleType, setRiddleType } from "../../riddleFormSlice"
import { RiddleTypeSelectorField } from "./riddleTypeSelectorField"
import { TooltipIcon } from "../../../../app/utils/components/info/infoIcon"
import React from "react"

export const RiddleTypeSelect = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isFormError = useAppSelector(SelectIsError)
  const riddleType = useAppSelector(SelectRiddleType)
  const isError = isFormError && !riddleType
  const options = [
    {
      value: RiddleTypes.MultipleChoice,
      label: t(RiddleFormText.multipleChoice),
    },
    { value: RiddleTypes.Text, label: t(RiddleFormText.text) },
    { value: RiddleTypes.Number, label: t(RiddleFormText.number) },
  ]

  const onChange = (event: any) => {
    dispatch(setRiddleType(event.target.value))
  }

  return (
    <FormControl error={isError} sx={{ minWidth: "100%" }}>
      <Grid container item xs={12} spacing={3}>
        <Grid item xs={12}>
          <CityRiddlerFormLabel required focused={false} sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ display: "flex", alignItems: "center" }}>
              <TooltipIcon text={`${t(RiddleFormText.riddleTypeLabelInfo)}`} />
              {t(RiddleFormText.riddleTypeLabel)}
            </Typography>
          </CityRiddlerFormLabel>
        </Grid>
      </Grid>
      <RadioGroup aria-labelledby="riddleType" value={riddleType} onChange={onChange} sx={{ alignContent: "center" }}>
        <Grid container item spacing={1} sx={{ maxWidth: "80%", marginTop: "1vw" }}>
          {options.map((option) => {
            return (
              <Grid item xs={4} key={option.value}>
                <RiddleTypeSelectorField
                  key={option.value}
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                />
              </Grid>
            )
          })}
        </Grid>
      </RadioGroup>
    </FormControl>
  )
}
