import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { SelectOutroVideo, setOutroVideo } from "../experienceFormSlice"
import { SelectIsError } from "../../riddleForm/riddleFormSlice"
import { ExperienceFormText } from "../../../i18"
import { TextInput } from "../../../app/utils/components/input/textInput"

export const ExperienceOutroVideo = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isFormError = useAppSelector(SelectIsError)
  const outroVideoUrl = useAppSelector(SelectOutroVideo)
  const isError = isFormError && !outroVideoUrl

  const onChange = (text: string): void => {
    dispatch(setOutroVideo(text))
  }

  return (
    <>
      <TextInput
        id="outroVideo"
        required={false}
        error={isError}
        label={`${t(ExperienceFormText.experienceOutroVideo)}`}
        infoText={`${t(ExperienceFormText.experienceOutroVideoInfo)}`}
        handleChange={onChange}
        multiline={false}
        value={outroVideoUrl ?? ""}
      />
    </>
  )
}
