import { Button, Container, Grid, Typography } from "@mui/material"
import { ChevronLeft } from "@mui/icons-material/"
import { Colors } from "../../../../app/colors"
import { CheckboxInput, Input } from "../utils"
import { FormEvent, useState } from "react"
import { registerWithEmailAndPassword } from "../../firebase/firebaseSetup"
import { useModal } from "mui-modal-provider"
import { AlertPopup, AlertTypes } from "../../../../app/utils/components/alert/alert"
import { useNavigate } from "react-router-dom"
import { RegisterText } from "../../../../i18"
import { useTranslation } from "react-i18next"
import { Page } from "../../auth"

interface RegisterProps {
  changeView: any
}

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  city: "",
  phone: "",
  password: "",
  confirmPassword: "",
  userAgreement: false,
  newsletter: true,
}

export const Register = (props: RegisterProps) => {
  const { changeView } = props
  const { showModal } = useModal()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [userData, setUserData] = useState(initialState)
  const [passwordError, setPasswordError] = useState(false)
  const [confirmPasswordError, setConfirmPasswordError] = useState(false)

  const goToLoginPage = () => {
    changeView(Page.login)
  }

  const handleChange = (e: any) => {
    setUserData({ ...userData, [e.target.name]: e.target.value })

    if (e.target.name === "password" && passwordError) {
      passwordCheck(e)
    }
    if (e.target.name === "confirmPassword" && confirmPasswordError) {
      confirmPasswordCheck(e)
    }
  }

  const handleChangeCheckbox = (e: any) => {
    setUserData({ ...userData, [e.target.name]: e.target.checked })
  }

  const passwordCheck = (e: any) => {
    const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/

    if (e.target.value.length < 8 || !passwordPattern.test(e.target.value)) {
      setPasswordError(true)
    } else {
      setPasswordError(false)
    }

    if (userData.confirmPassword !== "" && e.target.value !== userData.confirmPassword) {
      setConfirmPasswordError(true)
    } else {
      setConfirmPasswordError(false)
    }
  }

  const confirmPasswordCheck = (e: any) => {
    if (e.target.value !== userData.password) {
      setConfirmPasswordError(true)
    } else {
      setConfirmPasswordError(false)
    }
  }

  const phoneNumberCheck = (e: any) => {
    const phoneNumberPattern = ""
  }

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    const result = await registerWithEmailAndPassword(userData)

    if (typeof result === "string") {
      const modal: any = showModal(AlertPopup, {
        alertType: AlertTypes.Error,
        alertMessage: result,
        onClick: () => {
          modal.destroy()
        },
        isError: true,
      })
    } else {
      navigate("/dashboard")
    }
  }

  return (
    <>
      <Container
        component="main"
        maxWidth="md"
        sx={{
          marginTop: "3%",
        }}>
        <Grid container spacing={1} alignItems="flex-end" sx={{ marginBottom: "30px" }}>
          <Grid item xs={12}>
            <ChevronLeft
              fontSize="large"
              onClick={goToLoginPage}
              sx={{
                cursor: "pointer",
                blockSize: "border-box",
                "&:hover": {
                  backgroundColor: "rgba(0,0,0,0.2)",
                },
              }}
            />
          </Grid>
          <Grid item textAlign="center" xs={12}>
            <Typography variant="h4" display="inline">
              {t(RegisterText.greetingPartOne)}
            </Typography>
            <Typography variant="subtitle1" display="inline">
              {t(RegisterText.greetingPartTwo)}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={3} component="form" onSubmit={onSubmit}>
          <Grid item md={6} sm={12} xs={12}>
            <Input
              name="firstName"
              label={t(RegisterText.firstNameFieldLabel)}
              type="text"
              handleChange={handleChange}
              required={true}
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Input
              name="lastName"
              label={t(RegisterText.lastNameFieldLabel)}
              type="text"
              handleChange={handleChange}
              required={true}
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Input
              name="email"
              label={t(RegisterText.emailFieldLabel)}
              type="email"
              handleChange={handleChange}
              required={true}
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Input
              name="city"
              label={t(RegisterText.cityFieldLabel)}
              type="text"
              handleChange={handleChange}
              helperText={t(RegisterText.cityFieldHelperText)}
              required={false}
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Input
              name="password"
              label={t(RegisterText.passwordFieldLabel)}
              type="password"
              handleChange={handleChange}
              onBlur={passwordCheck}
              error={passwordError}
              helperText={t(RegisterText.passwordFieldHelperText)}
              required={true}
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Input
              name="confirmPassword"
              label={t(RegisterText.confirmPasswordFieldLabel)}
              type="password"
              handleChange={handleChange}
              onBlur={confirmPasswordCheck}
              error={confirmPasswordError}
              helperText={t(RegisterText.confirmPasswordFieldHelperText)}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <CheckboxInput
              label={t(RegisterText.userAgreementFieldLabel)}
              name="userAgreement"
              helperTexts={[
                {
                  text: t(RegisterText.userAgreementFieldHelperTextPartOne),
                  type: "text",
                },
                {
                  text: t(RegisterText.userAgreementFieldHelperTextPartTwo),
                  type: "link",
                },
                {
                  text: t(RegisterText.userAgreementFieldHelperTextPartThree),
                  type: "text",
                },
                {
                  text: t(RegisterText.userAgreementFieldHelperTextPartFour),
                  type: "link",
                },
                {
                  text: t(RegisterText.userAgreementFieldHelperTextPartFive),
                  type: "text",
                },
              ]}
              checked={false}
              required={true}
              handleChange={handleChangeCheckbox}
            />
          </Grid>
          <Grid item xs={12}>
            <CheckboxInput
              label={t(RegisterText.newsLetterFieldLabel)}
              name="newsletter"
              helperTexts={[
                {
                  text: t(RegisterText.newsLetterFieldHelperText),
                  type: "text",
                },
              ]}
              checked={true}
              required={false}
              handleChange={handleChangeCheckbox}
            />
          </Grid>
          <Grid item>
            <Button
              disabled={passwordError || confirmPasswordError}
              sx={{
                background: Colors.Primary,
                "&:hover": {
                  background: Colors.PrimaryDark,
                },
              }}
              type="submit"
              fullWidth
              variant="contained">
              {t(RegisterText.submitButtonLabel)}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
