import { Switch } from "@mui/material"
import { styled } from "@mui/system"
import { Colors } from "../colors"

export const CityRiddlerSwitch = styled(Switch, {
  name: "CityRiddlerSwitch",
})({
  // minWidth: "100%",
  margin: "auto",
  size: "small",
  // variant: "outlined",
  "& .MuiOutlinedInput-root": {
    backgroundColor: Colors.BackgroundLight,
    borderRadius: "5px",
    "& fieldset": {
      borderColor: Colors.Primary,
    },
    "&:hover fieldset": {
      borderColor: Colors.Primary,
    },
    "&.Mui-focused fieldset": {
      borderColor: Colors.PrimaryDark,
    },
  },
  "& .MuiOutlinedInput-root.Mui-error": {
    "& fieldset": {
      borderColor: "#ef5350",
    },
    "&:hover fieldset": {
      borderColor: "#d32f2f",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#d32f2f",
    },
  },
  "& .MuiFormHelperText-root": {
    color: Colors.SecondaryDark,
  },
})
