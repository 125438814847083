import { useTranslation } from "react-i18next"
import {
  SelectIsOverviewImageFileAvailable,
  SelectOverviewImageUrl,
  setOverviewImageFile,
} from "../experienceFormSlice"
import { SelectIsError } from "../../riddleForm/riddleFormSlice"
import { ExperienceFormText } from "../../../i18"
import { FileInput } from "../../riddleForm/formComponents"

export const ExperienceOverviewImage = () => {
  const { t } = useTranslation()

  return (
    <FileInput
      errorSelector={SelectIsError}
      fileSelector={SelectIsOverviewImageFileAvailable}
      upload={setOverviewImageFile}
      urlSelector={SelectOverviewImageUrl}
      label={t(ExperienceFormText.experienceOverviewImage)}
      infoText={t(ExperienceFormText.experienceOverviewImageInfo)}
      required={false}
    />
  )
}
