import { Avatar, Grid, Typography } from "@mui/material"
import { useContext } from "react"
import { Colors } from "../../app/colors"
import { AuthContext } from "../../firebase/authProvider"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"

export const MobileProfile = () => {
  const { user } = useContext(AuthContext)
  return (
    <Grid
      container
      item
      xs={12}
      alignItems="center"
      alignContent="center"
      textAlign="center"
      spacing={2}
      position="relative">
      <Grid item xs={4}>
        <Avatar alt="Riddle Creator" sx={{ width: "25vw", height: "25vw" }} />
      </Grid>
      <Grid container item xs={4}>
        <Grid item xs={12}>
          <Typography
            variant="h3"
            sx={{
              color: Colors.Black,
              width: "40vw",
              fontSize: "10vw",
              fontWeight: "4vw",
              textAlign: "left",
            }}>
            {user?.displayName ? user.displayName.split(" ")[0] : "Riddle"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h3"
            sx={{
              color: Colors.Black,
              width: "40vw",
              fontSize: "10vw",
              fontWeight: "4vw",
              textAlign: "left",
            }}>
            {user?.displayName ? user.displayName.split(" ")[1] : "Creator"}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <EditOutlinedIcon
          sx={{
            color: Colors.Black,
            width: "10vw",
            height: "10vw",
            position: "absolute",
          }}
        />
      </Grid>
    </Grid>
  )
}
