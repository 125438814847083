import { Grid, IconButton, InputAdornment, InputLabel, TextField } from "@mui/material"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import { Colors } from "../../../../app/colors"

interface InputProperties {
  name: string
  handleChange: any
  label: string
  autoFocus?: boolean
  type: string
  handlePassword?: any
  half?: any
  error?: boolean
  onBlur?: any
  helperText?: string | null
  required: boolean
}

export const Input = (props: InputProperties) => {
  return (
    <Grid item xs={12} sm={props.half ? 6 : 12}>
      <InputLabel htmlFor={props.label}>{props.label}</InputLabel>
      <TextField
        sx={{
          "& .MuiOutlinedInput-root": {
            backgroundColor: Colors.BackgroundLight,
            "& fieldset": {
              borderColor: Colors.Primary,
            },
            "&:hover fieldset": {
              borderColor: Colors.Primary,
            },
            "&.Mui-focused fieldset": {
              borderColor: Colors.PrimaryDark,
            },
          },
          "& .MuiOutlinedInput-root.Mui-error": {
            "& fieldset": {
              borderColor: "#ef5350",
            },
            "&:hover fieldset": {
              borderColor: "#d32f2f",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#d32f2f",
            },
          },
          "& .MuiFormHelperText-root": {
            color: Colors.SecondaryDark,
          },
        }}
        size="small"
        name={props.name}
        onChange={props.handleChange}
        variant="outlined"
        required={props.required}
        fullWidth
        id={props.label}
        autoFocus={props.autoFocus}
        type={props.type}
        error={props.error}
        onBlur={props.onBlur}
        helperText={props.helperText}
        InputProps={
          props.handlePassword
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={props.handlePassword}>
                      {props.type === "password" ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }
            : null!
        }
      />
    </Grid>
  )
}

export default Input
