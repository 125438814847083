import { Button, ClickAwayListener, Grid, Grow, MenuItem, MenuList, Paper, Popper } from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import { useContext, useEffect, useRef, useState } from "react"
import { Colors } from "../../../colors"
import { Logout } from "../logout/logout"
import { NavLink } from "../navLink/navLink"
import { AuthContext } from "../../../../firebase/authProvider"

export const MobileMenu = () => {
  const [open, setOpen] = useState<boolean>(false)
  const anchorRef = useRef<HTMLButtonElement>(null)
  const { user } = useContext(AuthContext)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === "Escape") {
      setOpen(false)
    }
  }

  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus()
    }

    prevOpen.current = open
  }, [open])
  return (
    <div style={{ height: "100%", width: "100%", zIndex: 100 }}>
      <Grid item container xs={12}>
        <Grid item xs={6}>
          <Button
            ref={anchorRef}
            id="composition-button"
            aria-controls={open ? "composition-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}>
            <MenuIcon sx={{ color: Colors.Primary }} />
          </Button>
        </Grid>
      </Grid>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        style={{ height: "100%", width: "100%" }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
            }}>
            <Paper sx={{ height: "100%", backgroundColor: Colors.BackgroundGreen }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}>
                  <MenuItem onClick={handleClose}>
                    <NavLink label="Home" destination="dashboard" />
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <NavLink label="Profil" destination="profile" />
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <NavLink label="Neues Rätsel" destination="riddleForm" />
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <NavLink label="Rätsel" destination="riddles" />
                  </MenuItem>
                  {user?.admin && (
                    <MenuItem onClick={handleClose}>
                      <NavLink label="Neues Abenteuer" destination="experienceForm" />
                    </MenuItem>
                  )}
                  {user?.admin && (
                    <MenuItem onClick={handleClose}>
                      <NavLink label="Abenteuer" destination="experiences" />
                    </MenuItem>
                  )}
                  <MenuItem></MenuItem>
                  <MenuItem></MenuItem>
                  <MenuItem>
                    <Logout />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}
