import { apiSlice as api } from "./apiSlice"

export const addTagTypes = [
  "voucher",
  "user-riddle",
  "riddle",
  "purchase",
  "location",
  "feedback",
  "experience",
  "prebuilt",
  "city",
  "category",
  "order",
  "user",
  "user-statistic",
  "progress",
  "join",
  "admin",
] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getVoucherById: build.query<GetVoucherByIdApiResponse, GetVoucherByIdApiArg>({
        query: (queryArg) => ({ url: `/voucher/${queryArg.id}` }),
        providesTags: ["voucher"],
      }),
      updateVoucher: build.mutation<UpdateVoucherApiResponse, UpdateVoucherApiArg>({
        query: (queryArg) => ({ url: `/voucher/${queryArg.id}`, method: "PUT", body: queryArg.voucher }),
        invalidatesTags: ["voucher"],
      }),
      deleteVoucher: build.mutation<DeleteVoucherApiResponse, DeleteVoucherApiArg>({
        query: (queryArg) => ({ url: `/voucher/${queryArg.id}`, method: "DELETE" }),
        invalidatesTags: ["voucher"],
      }),
      updateUserRiddle: build.mutation<UpdateUserRiddleApiResponse, UpdateUserRiddleApiArg>({
        query: (queryArg) => ({
          url: `/user/${queryArg.userId}/riddle/${queryArg.riddleId}`,
          method: "PUT",
          body: queryArg.riddle,
        }),
        invalidatesTags: ["user-riddle"],
      }),
      deleteUserRiddle: build.mutation<DeleteUserRiddleApiResponse, DeleteUserRiddleApiArg>({
        query: (queryArg) => ({ url: `/user/${queryArg.userId}/riddle/${queryArg.riddleId}`, method: "DELETE" }),
        invalidatesTags: ["user-riddle"],
      }),
      getRiddleById: build.query<GetRiddleByIdApiResponse, GetRiddleByIdApiArg>({
        query: (queryArg) => ({ url: `/riddle/${queryArg.id}` }),
        providesTags: ["riddle"],
      }),
      updateRiddle: build.mutation<UpdateRiddleApiResponse, UpdateRiddleApiArg>({
        query: (queryArg) => ({ url: `/riddle/${queryArg.id}`, method: "PUT", body: queryArg.riddle }),
        invalidatesTags: ["riddle"],
      }),
      deleteRiddle: build.mutation<DeleteRiddleApiResponse, DeleteRiddleApiArg>({
        query: (queryArg) => ({ url: `/riddle/${queryArg.id}`, method: "DELETE" }),
        invalidatesTags: ["riddle"],
      }),
      getPurchaseById: build.query<GetPurchaseByIdApiResponse, GetPurchaseByIdApiArg>({
        query: (queryArg) => ({ url: `/purchase/${queryArg.id}` }),
        providesTags: ["purchase"],
      }),
      updatePurchase: build.mutation<UpdatePurchaseApiResponse, UpdatePurchaseApiArg>({
        query: (queryArg) => ({ url: `/purchase/${queryArg.id}`, method: "PUT", body: queryArg.purchase }),
        invalidatesTags: ["purchase"],
      }),
      deletePurchase: build.mutation<DeletePurchaseApiResponse, DeletePurchaseApiArg>({
        query: (queryArg) => ({ url: `/purchase/${queryArg.id}`, method: "DELETE" }),
        invalidatesTags: ["purchase"],
      }),
      getLocationById: build.query<GetLocationByIdApiResponse, GetLocationByIdApiArg>({
        query: (queryArg) => ({ url: `/location/${queryArg.id}` }),
        providesTags: ["location"],
      }),
      updateLocation: build.mutation<UpdateLocationApiResponse, UpdateLocationApiArg>({
        query: (queryArg) => ({ url: `/location/${queryArg.id}`, method: "PUT", body: queryArg.location }),
        invalidatesTags: ["location"],
      }),
      deleteLocation: build.mutation<DeleteLocationApiResponse, DeleteLocationApiArg>({
        query: (queryArg) => ({ url: `/location/${queryArg.id}`, method: "DELETE" }),
        invalidatesTags: ["location"],
      }),
      getFeedbackById: build.query<GetFeedbackByIdApiResponse, GetFeedbackByIdApiArg>({
        query: (queryArg) => ({ url: `/feedback/${queryArg.id}` }),
        providesTags: ["feedback"],
      }),
      updateFeedback: build.mutation<UpdateFeedbackApiResponse, UpdateFeedbackApiArg>({
        query: (queryArg) => ({ url: `/feedback/${queryArg.id}`, method: "PUT", body: queryArg.feedback }),
        invalidatesTags: ["feedback"],
      }),
      updateExperience: build.mutation<UpdateExperienceApiResponse, UpdateExperienceApiArg>({
        query: (queryArg) => ({ url: `/experience/${queryArg.id}`, method: "PUT", body: queryArg.experience }),
        invalidatesTags: ["experience"],
      }),
      updatePrebuiltExperience: build.mutation<UpdatePrebuiltExperienceApiResponse, UpdatePrebuiltExperienceApiArg>({
        query: (queryArg) => ({
          url: `/experience/prebuilt/${queryArg.id}`,
          method: "PUT",
          body: queryArg.prebuiltExperience,
        }),
        invalidatesTags: ["prebuilt"],
      }),
      getCityById: build.query<GetCityByIdApiResponse, GetCityByIdApiArg>({
        query: (queryArg) => ({ url: `/city/${queryArg.id}` }),
        providesTags: ["city"],
      }),
      updateCity: build.mutation<UpdateCityApiResponse, UpdateCityApiArg>({
        query: (queryArg) => ({ url: `/city/${queryArg.id}`, method: "PUT", body: queryArg.cityData }),
        invalidatesTags: ["city"],
      }),
      deleteCity: build.mutation<DeleteCityApiResponse, DeleteCityApiArg>({
        query: (queryArg) => ({ url: `/city/${queryArg.id}`, method: "DELETE" }),
        invalidatesTags: ["city"],
      }),
      getCategoryById: build.query<GetCategoryByIdApiResponse, GetCategoryByIdApiArg>({
        query: (queryArg) => ({ url: `/category/${queryArg.id}` }),
        providesTags: ["category"],
      }),
      updateCategory: build.mutation<UpdateCategoryApiResponse, UpdateCategoryApiArg>({
        query: (queryArg) => ({ url: `/category/${queryArg.id}`, method: "PUT", body: queryArg.category }),
        invalidatesTags: ["category"],
      }),
      deleteCategory: build.mutation<DeleteCategoryApiResponse, DeleteCategoryApiArg>({
        query: (queryArg) => ({ url: `/category/${queryArg.id}`, method: "DELETE" }),
        invalidatesTags: ["category"],
      }),
      getAllVouchersPaged: build.query<GetAllVouchersPagedApiResponse, GetAllVouchersPagedApiArg>({
        query: (queryArg) => ({ url: `/voucher`, params: { page: queryArg.page, size: queryArg.size } }),
        providesTags: ["voucher"],
      }),
      createVoucher: build.mutation<CreateVoucherApiResponse, CreateVoucherApiArg>({
        query: (queryArg) => ({ url: `/voucher`, method: "POST", body: queryArg.voucher }),
        invalidatesTags: ["voucher"],
      }),
      addVoucherUsage: build.mutation<AddVoucherUsageApiResponse, AddVoucherUsageApiArg>({
        query: (queryArg) => ({ url: `/voucher/${queryArg.id}/usage`, method: "POST", body: queryArg.voucherUsage }),
        invalidatesTags: ["voucher"],
      }),
      getAllRiddlesPaged: build.query<GetAllRiddlesPagedApiResponse, GetAllRiddlesPagedApiArg>({
        query: (queryArg) => ({ url: `/riddle`, params: { page: queryArg.page, size: queryArg.size } }),
        providesTags: ["riddle"],
      }),
      createRiddle: build.mutation<CreateRiddleApiResponse, CreateRiddleApiArg>({
        query: (queryArg) => ({ url: `/riddle`, method: "POST", body: queryArg.riddle }),
        invalidatesTags: ["riddle"],
      }),
      getAllPurchasesPaged: build.query<GetAllPurchasesPagedApiResponse, GetAllPurchasesPagedApiArg>({
        query: (queryArg) => ({ url: `/purchase`, params: { page: queryArg.page, size: queryArg.size } }),
        providesTags: ["purchase"],
      }),
      createPurchase: build.mutation<CreatePurchaseApiResponse, CreatePurchaseApiArg>({
        query: (queryArg) => ({ url: `/purchase`, method: "POST", body: queryArg.purchase }),
        invalidatesTags: ["purchase"],
      }),
      createNewOrder: build.mutation<CreateNewOrderApiResponse, CreateNewOrderApiArg>({
        query: (queryArg) => ({ url: `/order`, method: "POST", params: { order: queryArg.order } }),
        invalidatesTags: ["order"],
      }),
      getAllLocationsPaged: build.query<GetAllLocationsPagedApiResponse, GetAllLocationsPagedApiArg>({
        query: (queryArg) => ({ url: `/location`, params: { page: queryArg.page, size: queryArg.size } }),
        providesTags: ["location"],
      }),
      createLocation: build.mutation<CreateLocationApiResponse, CreateLocationApiArg>({
        query: (queryArg) => ({ url: `/location`, method: "POST", body: queryArg.location }),
        invalidatesTags: ["location"],
      }),
      getAllFeedback: build.query<GetAllFeedbackApiResponse, GetAllFeedbackApiArg>({
        query: (queryArg) => ({ url: `/feedback`, params: { page: queryArg.page, size: queryArg.size } }),
        providesTags: ["feedback"],
      }),
      createFeedback: build.mutation<CreateFeedbackApiResponse, CreateFeedbackApiArg>({
        query: (queryArg) => ({ url: `/feedback`, method: "POST", body: queryArg.feedback }),
        invalidatesTags: ["feedback"],
      }),
      getAllExperiences: build.query<GetAllExperiencesApiResponse, GetAllExperiencesApiArg>({
        query: (queryArg) => ({ url: `/experience`, params: { page: queryArg.page, size: queryArg.size } }),
        providesTags: ["experience"],
      }),
      createExperience: build.mutation<CreateExperienceApiResponse, CreateExperienceApiArg>({
        query: (queryArg) => ({ url: `/experience`, method: "POST", body: queryArg.experience }),
        invalidatesTags: ["experience"],
      }),
      deleteExperience: build.mutation<DeleteExperienceApiResponse, DeleteExperienceApiArg>({
        query: (queryArg) => ({
          url: `/experience`,
          method: "DELETE",
          params: { experienceId: queryArg.experienceId },
        }),
        invalidatesTags: ["experience"],
      }),
      addProgressToExperience: build.mutation<AddProgressToExperienceApiResponse, AddProgressToExperienceApiArg>({
        query: (queryArg) => ({
          url: `/experience/${queryArg.experienceId}/progress/`,
          method: "POST",
          body: queryArg.experienceProgress,
        }),
        invalidatesTags: ["experience"],
      }),
      getAllPrebuiltExperiences: build.query<GetAllPrebuiltExperiencesApiResponse, GetAllPrebuiltExperiencesApiArg>({
        query: () => ({ url: `/experience/prebuilt` }),
        providesTags: ["prebuilt"],
      }),
      createPrebuiltExperience: build.mutation<CreatePrebuiltExperienceApiResponse, CreatePrebuiltExperienceApiArg>({
        query: (queryArg) => ({
          url: `/experience/prebuilt`,
          method: "POST",
          params: { prebuiltExp: queryArg.prebuiltExp },
        }),
        invalidatesTags: ["prebuilt"],
      }),
      deletePrebuiltExperience: build.mutation<DeletePrebuiltExperienceApiResponse, DeletePrebuiltExperienceApiArg>({
        query: (queryArg) => ({
          url: `/experience/prebuilt`,
          method: "DELETE",
          params: { prebuiltExperienceId: queryArg.prebuiltExperienceId },
        }),
        invalidatesTags: ["prebuilt"],
      }),
      getAllCities: build.query<GetAllCitiesApiResponse, GetAllCitiesApiArg>({
        query: (queryArg) => ({ url: `/city`, params: { page: queryArg.page, size: queryArg.size } }),
        providesTags: ["city"],
      }),
      createCity: build.mutation<CreateCityApiResponse, CreateCityApiArg>({
        query: (queryArg) => ({ url: `/city`, method: "POST", body: queryArg.cityData }),
        invalidatesTags: ["city"],
      }),
      getAllCategories: build.query<GetAllCategoriesApiResponse, GetAllCategoriesApiArg>({
        query: (queryArg) => ({ url: `/category`, params: { page: queryArg.page, size: queryArg.size } }),
        providesTags: ["category"],
      }),
      createCategory: build.mutation<CreateCategoryApiResponse, CreateCategoryApiArg>({
        query: (queryArg) => ({ url: `/category`, method: "POST", body: queryArg.category }),
        invalidatesTags: ["category"],
      }),
      setGeneratorMode: build.mutation<SetGeneratorModeApiResponse, SetGeneratorModeApiArg>({
        query: (queryArg) => ({
          url: `/admin/generator/mode`,
          method: "POST",
          params: { mode: queryArg.mode, fixedExperienceName: queryArg.fixedExperienceName },
        }),
        invalidatesTags: ["experience"],
      }),
      isVoucherValid: build.query<IsVoucherValidApiResponse, IsVoucherValidApiArg>({
        query: (queryArg) => ({ url: `/voucher/${queryArg.code}/valid` }),
        providesTags: ["voucher"],
      }),
      getVoucherByCode: build.query<GetVoucherByCodeApiResponse, GetVoucherByCodeApiArg>({
        query: (queryArg) => ({ url: `/voucher/code/${queryArg.code}` }),
        providesTags: ["voucher"],
      }),
      getAllUsersPaged: build.query<GetAllUsersPagedApiResponse, GetAllUsersPagedApiArg>({
        query: (queryArg) => ({ url: `/user`, params: { page: queryArg.page, size: queryArg.size } }),
        providesTags: ["user"],
      }),
      getUserStatistics: build.query<GetUserStatisticsApiResponse, GetUserStatisticsApiArg>({
        query: (queryArg) => ({ url: `/user/${queryArg.userId}/statistic` }),
        providesTags: ["user-statistic"],
      }),
      getUserRiddles: build.query<GetUserRiddlesApiResponse, GetUserRiddlesApiArg>({
        query: (queryArg) => ({
          url: `/user/${queryArg.userId}/riddle`,
          params: { fake: queryArg.fake, pageable: queryArg.pageable },
        }),
        providesTags: ["user-riddle"],
      }),
      getExperiencesOfUser: build.query<GetExperiencesOfUserApiResponse, GetExperiencesOfUserApiArg>({
        query: (queryArg) => ({ url: `/user/${queryArg.userId}/experience` }),
        providesTags: ["user"],
      }),
      getUserById: build.query<GetUserByIdApiResponse, GetUserByIdApiArg>({
        query: (queryArg) => ({ url: `/user/${queryArg.id}` }),
        providesTags: ["user"],
      }),
      getAllProgress: build.query<GetAllProgressApiResponse, GetAllProgressApiArg>({
        query: (queryArg) => ({ url: `/progress`, params: { page: queryArg.page, size: queryArg.size } }),
        providesTags: ["progress"],
      }),
      validatePartnerVoucher: build.query<ValidatePartnerVoucherApiResponse, ValidatePartnerVoucherApiArg>({
        query: (queryArg) => ({
          url: `/partner/voucher/${queryArg.voucherCode}`,
          params: { partnerId: queryArg.partnerId, experienceId: queryArg.experienceId, amount: queryArg.amount },
        }),
        providesTags: ["voucher"],
      }),
      getAllJoins: build.query<GetAllJoinsApiResponse, GetAllJoinsApiArg>({
        query: (queryArg) => ({ url: `/joins`, params: { page: queryArg.page, size: queryArg.size } }),
        providesTags: ["join"],
      }),
      searchFeedback: build.query<SearchFeedbackApiResponse, SearchFeedbackApiArg>({
        query: (queryArg) => ({
          url: `/feedback/search`,
          params: { riddleId: queryArg.riddleId, locationId: queryArg.locationId, experienceId: queryArg.experienceId },
        }),
        providesTags: ["feedback"],
      }),
      getExperienceById: build.query<GetExperienceByIdApiResponse, GetExperienceByIdApiArg>({
        query: (queryArg) => ({ url: `/experience/${queryArg.experienceId}` }),
        providesTags: ["experience"],
      }),
      createExperienceForPrebuiltExperience: build.query<
        CreateExperienceForPrebuiltExperienceApiResponse,
        CreateExperienceForPrebuiltExperienceApiArg
      >({
        query: (queryArg) => ({ url: `/experience/prebuilt/${queryArg.id}/experience` }),
        providesTags: ["prebuilt"],
      }),
      joinExperience: build.query<JoinExperienceApiResponse, JoinExperienceApiArg>({
        query: (queryArg) => ({
          url: `/experience/joinCode/${queryArg.joinCode}`,
          params: { countJoin: queryArg.countJoin },
        }),
        providesTags: ["join"],
      }),
      generateExperience: build.query<GenerateExperienceApiResponse, GenerateExperienceApiArg>({
        query: (queryArg) => ({
          url: `/experience/generate`,
          params: {
            categoryIds: queryArg.categoryIds,
            difficultyIds: queryArg.difficultyIds,
            timeLimitInMin: queryArg.timeLimitInMin,
            userUid: queryArg.userUid,
            startLat: queryArg.startLat,
            startLon: queryArg.startLon,
            endLat: queryArg.endLat,
            endLon: queryArg.endLon,
          },
        }),
        providesTags: ["experience"],
      }),
      getCityByGps: build.query<GetCityByGpsApiResponse, GetCityByGpsApiArg>({
        query: (queryArg) => ({
          url: `/city/search`,
          params: { latitude: queryArg.latitude, longitude: queryArg.longitude },
        }),
        providesTags: ["city"],
      }),
      getStatistic: build.query<GetStatisticApiResponse, GetStatisticApiArg>({
        query: () => ({ url: `/admin/statistic` }),
        providesTags: ["admin"],
      }),
      reloadDistances: build.query<ReloadDistancesApiResponse, ReloadDistancesApiArg>({
        query: () => ({ url: `/admin/reloadDistances` }),
        providesTags: ["admin"],
      }),
    }),
    overrideExisting: false,
  })
export { injectedRtkApi as cityriddlerApi }
export type GetVoucherByIdApiResponse = /** status 200 successful operation */ Voucher
export type GetVoucherByIdApiArg = {
  id: number
}
export type UpdateVoucherApiResponse = /** status 200 successful operation */ Voucher
export type UpdateVoucherApiArg = {
  id: number
  voucher: Voucher
}
export type DeleteVoucherApiResponse = unknown
export type DeleteVoucherApiArg = {
  id: number
}
export type UpdateUserRiddleApiResponse = /** status 200 successful operation */ Riddle
export type UpdateUserRiddleApiArg = {
  userId: number
  riddleId: number
  riddle: Riddle
}
export type DeleteUserRiddleApiResponse = unknown
export type DeleteUserRiddleApiArg = {
  userId: string
  riddleId: number
}
export type GetRiddleByIdApiResponse = /** status 200 successful operation */ Riddle
export type GetRiddleByIdApiArg = {
  id: number
}
export type UpdateRiddleApiResponse = /** status 200 successful operation */ Riddle
export type UpdateRiddleApiArg = {
  id: number
  riddle: Riddle
}
export type DeleteRiddleApiResponse = unknown
export type DeleteRiddleApiArg = {
  id: number
}
export type GetPurchaseByIdApiResponse = /** status 200 successful operation */ Purchase
export type GetPurchaseByIdApiArg = {
  id: number
}
export type UpdatePurchaseApiResponse = /** status 200 successful operation */ Purchase
export type UpdatePurchaseApiArg = {
  id: number
  purchase: Purchase
}
export type DeletePurchaseApiResponse = unknown
export type DeletePurchaseApiArg = {
  id: number
}
export type GetLocationByIdApiResponse = /** status 200 successful operation */ Location
export type GetLocationByIdApiArg = {
  id: number
}
export type UpdateLocationApiResponse = /** status 200 successful operation */ Location
export type UpdateLocationApiArg = {
  id: number
  location: Location
}
export type DeleteLocationApiResponse = unknown
export type DeleteLocationApiArg = {
  id: number
}
export type GetFeedbackByIdApiResponse = /** status 200 successful operation */ Feedback
export type GetFeedbackByIdApiArg = {
  id: number
}
export type UpdateFeedbackApiResponse = /** status 200 successful operation */ Feedback
export type UpdateFeedbackApiArg = {
  id: number
  feedback: Feedback
}
export type UpdateExperienceApiResponse = /** status 200 successful operation */ Experience
export type UpdateExperienceApiArg = {
  id: number
  experience: Experience
}
export type UpdatePrebuiltExperienceApiResponse = /** status 200 successful operation */ PrebuiltExperience
export type UpdatePrebuiltExperienceApiArg = {
  id: number
  prebuiltExperience: PrebuiltExperience
}
export type GetCityByIdApiResponse = /** status 200 successful operation */ CityData
export type GetCityByIdApiArg = {
  id: number
}
export type UpdateCityApiResponse = /** status 200 successful operation */ CityData
export type UpdateCityApiArg = {
  id: number
  cityData: CityData
}
export type DeleteCityApiResponse = unknown
export type DeleteCityApiArg = {
  id: number
}
export type GetCategoryByIdApiResponse = /** status 200 successful operation */ Category
export type GetCategoryByIdApiArg = {
  id: number
}
export type UpdateCategoryApiResponse = /** status 200 successful operation */ Category
export type UpdateCategoryApiArg = {
  id: number
  category: Category
}
export type DeleteCategoryApiResponse = unknown
export type DeleteCategoryApiArg = {
  id: number
}
export type GetAllVouchersPagedApiResponse = /** status 200 successful operation */ PageVoucher
export type GetAllVouchersPagedApiArg = {
  page?: number
  size?: number
}
export type CreateVoucherApiResponse = /** status 201 Voucher created */ Voucher
export type CreateVoucherApiArg = {
  voucher: Voucher
}
export type AddVoucherUsageApiResponse = unknown
export type AddVoucherUsageApiArg = {
  id: number
  voucherUsage: VoucherUsage
}
export type GetAllRiddlesPagedApiResponse = /** status 200 successful operation */ PageRiddle
export type GetAllRiddlesPagedApiArg = {
  page?: number
  size?: number
}
export type CreateRiddleApiResponse = /** status 201 Riddle created */ Riddle
export type CreateRiddleApiArg = {
  riddle: Riddle
}
export type GetAllPurchasesPagedApiResponse = /** status 200 successful operation */ PagePurchase
export type GetAllPurchasesPagedApiArg = {
  page?: number
  size?: number
}
export type CreatePurchaseApiResponse = /** status 201 Purchase created */ Purchase
export type CreatePurchaseApiArg = {
  purchase: Purchase
}
export type CreateNewOrderApiResponse = /** status 201 Order created */ Order
export type CreateNewOrderApiArg = {
  order: Order
}
export type GetAllLocationsPagedApiResponse = /** status 200 successful operation */ PageLocation
export type GetAllLocationsPagedApiArg = {
  page?: number
  size?: number
}
export type CreateLocationApiResponse = /** status 201 Location created */ Location
export type CreateLocationApiArg = {
  location: Location
}
export type GetAllFeedbackApiResponse = /** status 200 successful operation */ PageFeedback
export type GetAllFeedbackApiArg = {
  page?: number
  size?: number
}
export type CreateFeedbackApiResponse = /** status 201 Feedback created */ Feedback
export type CreateFeedbackApiArg = {
  feedback: Feedback
}
export type GetAllExperiencesApiResponse = /** status 200 successful operation */ PageMinExperience
export type GetAllExperiencesApiArg = {
  page: number
  size: number
}
export type CreateExperienceApiResponse = unknown
export type CreateExperienceApiArg = {
  experience: Experience
}
export type DeleteExperienceApiResponse = unknown
export type DeleteExperienceApiArg = {
  experienceId: number
}
export type AddProgressToExperienceApiResponse = /** status 200 successful operation */ ExperienceProgress
export type AddProgressToExperienceApiArg = {
  experienceId: number
  experienceProgress: ExperienceProgress
}
export type GetAllPrebuiltExperiencesApiResponse = /** status 200 successful operation */ PrebuiltExperience[]
export type GetAllPrebuiltExperiencesApiArg = void
export type CreatePrebuiltExperienceApiResponse = unknown
export type CreatePrebuiltExperienceApiArg = {
  prebuiltExp: PrebuiltExperience
}
export type DeletePrebuiltExperienceApiResponse = unknown
export type DeletePrebuiltExperienceApiArg = {
  prebuiltExperienceId: number
}
export type GetAllCitiesApiResponse = /** status 200 successful operation */ PageCityData
export type GetAllCitiesApiArg = {
  page?: number
  size?: number
}
export type CreateCityApiResponse = /** status 201 City created */ CityData
export type CreateCityApiArg = {
  cityData: CityData
}
export type GetAllCategoriesApiResponse = /** status 200 successful operation */ PageCategory
export type GetAllCategoriesApiArg = {
  page?: number
  size?: number
}
export type CreateCategoryApiResponse = /** status 201 Category created */ Category
export type CreateCategoryApiArg = {
  category: Category
}
export type SetGeneratorModeApiResponse = /** status 200 successful operation */ boolean
export type SetGeneratorModeApiArg = {
  mode: "NORMAL" | "FIXED"
  fixedExperienceName?: string
}
export type IsVoucherValidApiResponse = /** status 200 successful operation */ boolean
export type IsVoucherValidApiArg = {
  code: string
}
export type GetVoucherByCodeApiResponse = /** status 200 successful operation */ Voucher
export type GetVoucherByCodeApiArg = {
  code: string
}
export type GetAllUsersPagedApiResponse = /** status 200 successful operation */ PageCrUser
export type GetAllUsersPagedApiArg = {
  page?: number
  size?: number
}
export type GetUserStatisticsApiResponse = /** status 200 successful operation */ UserStatistic
export type GetUserStatisticsApiArg = {
  userId: string
}
export type GetUserRiddlesApiResponse = /** status 200 successful operation */ PageRiddle
export type GetUserRiddlesApiArg = {
  userId: string
  fake?: boolean
  pageable?: Pageable
}
export type GetExperiencesOfUserApiResponse = /** status 200 successful operation */ Experience[]
export type GetExperiencesOfUserApiArg = {
  userId: string
}
export type GetUserByIdApiResponse = /** status 200 successful operation */ CrUser
export type GetUserByIdApiArg = {
  id: string
}
export type GetAllProgressApiResponse = /** status 200 successful operation */ PageExperienceProgress
export type GetAllProgressApiArg = {
  page: number
  size: number
}
export type ValidatePartnerVoucherApiResponse = /** status 200 successful operation */ boolean
export type ValidatePartnerVoucherApiArg = {
  voucherCode: string
  partnerId: number
  experienceId: number
  amount?: number
}
export type GetAllJoinsApiResponse = /** status 200 successful operation */ PageExperienceJoin
export type GetAllJoinsApiArg = {
  page: number
  size: number
}
export type SearchFeedbackApiResponse = /** status 200 successful operation */ Feedback[]
export type SearchFeedbackApiArg = {
  riddleId?: number
  locationId?: number
  experienceId?: number
}
export type GetExperienceByIdApiResponse = /** status 200 successful operation */ Experience
export type GetExperienceByIdApiArg = {
  experienceId: number
}
export type CreateExperienceForPrebuiltExperienceApiResponse = /** status 200 successful operation */ Experience
export type CreateExperienceForPrebuiltExperienceApiArg = {
  id: number
}
export type JoinExperienceApiResponse = /** status 200 successful operation */ Experience
export type JoinExperienceApiArg = {
  joinCode: string
  countJoin?: boolean
}
export type GenerateExperienceApiResponse = /** status 200 successful operation */ Experience
export type GenerateExperienceApiArg = {
  categoryIds: number[]
  difficultyIds: number[]
  timeLimitInMin: number
  userUid: string
  startLat: number
  startLon: number
  endLat?: number
  endLon?: number
}
export type GetCityByGpsApiResponse = /** status 200 successful operation */ CityData
export type GetCityByGpsApiArg = {
  latitude: number
  longitude: number
}
export type GetStatisticApiResponse = /** status 200 successful operation */ UserStatistic
export type GetStatisticApiArg = void
export type ReloadDistancesApiResponse = /** status 200 successful operation */ boolean
export type ReloadDistancesApiArg = void
export type VoucherUsage = {
  id?: number
  userId: string
  used?: string
}
export type Voucher = {
  id?: number
  code: string
  active: boolean
  oneTime: boolean
  free: boolean
  price: number
  discountPercentage: string
  tourId: string
  validFrom: string
  validTo: string
  usage: VoucherUsage[]
  adminOnly: boolean
  note: string
}
export type Language = {
  id: number
  languageCode?: string
  countryCode?: string
}
export type InternationalText = {
  id?: number
  text: string
  language: Language
  audioUrl?: string
}
export type Hint = {
  id?: number
  text: InternationalText[]
  priority: number
}
export type CrUser = {
  id?: string
  firstName?: string
  lastName?: string
  userName: string
  email?: string
  anonymous: boolean
  creator: boolean
  active: boolean
  created: string
  updated: string
}
export type CrMetadata = {
  flagged: boolean
  flagNote?: string
  active: boolean
  deleted: boolean
  created: string
  updated: string
  creator?: CrUser
  creatorId?: string
}
export type Fact = {
  id?: number
  text: string
  type: "FUN_FACT" | "HISTORY_FACT"
  source?: string
  sourceUrl?: string
  textAudioUrl?: string
  language: Language
  metadata?: CrMetadata
}
export type Image = {
  id?: number
  url: string
  blurHash?: string
}
export type RiddleRating = {
  id?: number
  rating: number
  user: string
}
export type RiddleRatingOverview = {
  average: number
  ratings: RiddleRating[]
}
export type SolutionChoice = {
  id?: number
  priority: number
  text: InternationalText[]
}
export type RiddleSolution = {
  solution: InternationalText[]
  solutionAlternatives: InternationalText[]
  choices: SolutionChoice[]
  solutionType?: "MULTIPLE_CHOICE" | "NUMBER" | "TEXT"
  needsValidation: boolean
}
export type Address = {
  id?: number
  street: string
  streetNumber: string
  zipCode: string
  city: string
  country: string
}
export type GpsData = {
  latitude: number
  longitude: number
}
export type Location = {
  id?: number
  facts: Fact[]
  address?: Address
  gpsData: GpsData
  prominence: "MAIN_SIGHT" | "STANDARD" | "HIDDEN_GEM"
  metadata: CrMetadata
  image: Image
}
export type Category = {
  id?: number
  name: string
  active: boolean
  public: boolean
}
export type RiddleMetadata = {
  difficulty: "LOW" | "MEDIUM" | "HIGH"
  location: Location
  locationId: number
  categories?: Category[]
  categoryIds: number[]
}
export type Riddle = {
  id?: number
  title: InternationalText[]
  description: InternationalText[]
  navigation: InternationalText[]
  hints: Hint[]
  facts: Fact[]
  image: Image
  navigationImage?: Image
  metadata: CrMetadata
  ratings?: RiddleRatingOverview
  riddleSolution?: RiddleSolution
  riddleMetadata: RiddleMetadata
}
export type Purchase = {
  id?: number
  experienceId: string
  transactionId: string
  productId: string
  article: string
  userId: string
  platform: "IOS" | "ANDROID" | "WEB_SHOP"
  usedVoucher?: Voucher
  usedVoucherId?: number
  transactionDate?: string
}
export type Feedback = {
  id?: number
  text?: string
  rating: number
  timeStamp: string
  type: "LOCATION" | "RIDDLE" | "EXPERIENCE"
  riddleId?: number
  locationId?: number
  experienceId?: number
  userId?: string
  checked: boolean
}
export type Suggestion = {
  id?: number
  name: string
  description?: InternationalText[]
  location: Location
  metadata: CrMetadata
}
export type Partner = {
  id?: number
  name: string
  voucher: string
  voucherHeaderText: InternationalText[]
  voucherInfoText: InternationalText[]
  image: Image
  location: Location
  metadata: CrMetadata
}
export type ExperienceStep = {
  id?: number
  riddle: Riddle
  stepIndex: number
  suggestions: Suggestion[]
  partner: Partner[]
  timeStamp: string
}
export type ExperienceInfo = {
  displayName: InternationalText[]
  descriptionShort: InternationalText[]
  descriptionLong: InternationalText[]
  coverImage?: Image
}
export type ExperienceMetadata = {
  timeStamp: string
  prebuildId?: number
  generationTime?: number
  joinCount?: number
  maxJoins?: number
  tourId: string
  confirmCode: string
}
export type IntroPage = {
  id?: number
  orderIndex: number
  image: Image
  introHeader: InternationalText[]
  introText: InternationalText[]
}
export type FeatureFlags = {
  usePoints: boolean
  overviewImage?: Image
  finishImage?: Image
  needsPayment: boolean
  event: boolean
  intro: IntroPage[]
  introVideoUrl?: string
  outroVideoUrl?: string
  forceOffline: boolean
}
export type Experience = {
  id?: number
  steps: ExperienceStep[]
  start: Location
  userId: string
  joinCode: string
  infos: ExperienceInfo
  metadata: ExperienceMetadata
  featureFlags: FeatureFlags
}
export type PrebuiltExperience = {
  id?: number
  name: InternationalText[]
  subname: InternationalText[]
  header: Image
  start?: Location
  startId: number
  durationInMin: number
  price?: number
  description: InternationalText[]
  included: InternationalText[]
  public: boolean
  riddleCount: number
  experienceId: number
  priority: number
  qualifier: string
  tourId: string
  metadata: CrMetadata
}
export type PresetInfo = {
  displayName: InternationalText[]
  descriptionShort: InternationalText[]
  descriptionLong: InternationalText[]
  coverImage: Image
}
export type ExperiencePreset = {
  id?: number
  info: PresetInfo
  paymentId: string
  riddleCount: number
}
export type CityData = {
  id?: number
  center: GpsData
  descriptionShort: InternationalText[]
  cityName: InternationalText[]
  coverImage: Image
  presets: ExperiencePreset[]
  experienceCount: number
}
export type SortObject = {
  direction?: string
  nullHandling?: string
  ascending?: boolean
  property?: string
  ignoreCase?: boolean
}
export type PageableObject = {
  offset?: number
  sort?: SortObject[]
  paged?: boolean
  unpaged?: boolean
  pageNumber?: number
  pageSize?: number
}
export type PageVoucher = {
  totalPages?: number
  totalElements?: number
  size?: number
  content?: Voucher[]
  number?: number
  sort?: SortObject[]
  first?: boolean
  last?: boolean
  numberOfElements?: number
  pageable?: PageableObject
  empty?: boolean
}
export type PageRiddle = {
  totalPages?: number
  totalElements?: number
  size?: number
  content?: Riddle[]
  number?: number
  sort?: SortObject[]
  first?: boolean
  last?: boolean
  numberOfElements?: number
  pageable?: PageableObject
  empty?: boolean
}
export type PagePurchase = {
  totalPages?: number
  totalElements?: number
  size?: number
  content?: Purchase[]
  number?: number
  sort?: SortObject[]
  first?: boolean
  last?: boolean
  numberOfElements?: number
  pageable?: PageableObject
  empty?: boolean
}
export type Order = {
  id?: number
  shopOrderId: string
  email: string
  article: string
  experienceId?: number
  orderDate: string
}
export type PageLocation = {
  totalPages?: number
  totalElements?: number
  size?: number
  content?: Location[]
  number?: number
  sort?: SortObject[]
  first?: boolean
  last?: boolean
  numberOfElements?: number
  pageable?: PageableObject
  empty?: boolean
}
export type PageFeedback = {
  totalPages?: number
  totalElements?: number
  size?: number
  content?: Feedback[]
  number?: number
  sort?: SortObject[]
  first?: boolean
  last?: boolean
  numberOfElements?: number
  pageable?: PageableObject
  empty?: boolean
}
export type MinExperience = {
  id?: number
  start: Location
  userId: string
  joinCode: string
  infos?: ExperienceInfo
  metadata: ExperienceMetadata
  featureFlags: FeatureFlags
}
export type PageMinExperience = {
  totalPages?: number
  totalElements?: number
  size?: number
  content?: MinExperience[]
  number?: number
  sort?: SortObject[]
  first?: boolean
  last?: boolean
  numberOfElements?: number
  pageable?: PageableObject
  empty?: boolean
}
export type ExperienceProgress = {
  id?: number
  userId: string
  timeStamp?: string
  stepId: number
  progress: "SOLVED" | "HINT_USED" | "SKIPPED" | "STARTED"
}
export type PageCityData = {
  totalPages?: number
  totalElements?: number
  size?: number
  content?: CityData[]
  number?: number
  sort?: SortObject[]
  first?: boolean
  last?: boolean
  numberOfElements?: number
  pageable?: PageableObject
  empty?: boolean
}
export type PageCategory = {
  totalPages?: number
  totalElements?: number
  size?: number
  content?: Category[]
  number?: number
  sort?: SortObject[]
  first?: boolean
  last?: boolean
  numberOfElements?: number
  pageable?: PageableObject
  empty?: boolean
}
export type PageCrUser = {
  totalPages?: number
  totalElements?: number
  size?: number
  content?: CrUser[]
  number?: number
  sort?: SortObject[]
  first?: boolean
  last?: boolean
  numberOfElements?: number
  pageable?: PageableObject
  empty?: boolean
}
export type UserRiddleStatistic = {
  riddleId: number
  dailyUsage: {
    [key: string]: number
  }
  totalUsage: number
}
export type UserExperienceStatistic = {
  experienceId: number
  dailyUsage: {
    [key: string]: number
  }
  totalUsage: number
}
export type UserStatistic = {
  riddles: Riddle[]
  experiences: Experience[]
  riddleStatistics: {
    [key: string]: UserRiddleStatistic
  }
  experienceStatistics: {
    [key: string]: UserExperienceStatistic
  }
  totalDailyRiddleUsage: {
    [key: string]: number
  }
  totalDailyExperienceUsage: {
    [key: string]: number
  }
}
export type Pageable = {
  page?: number
  size?: number
  sort?: string[]
}
export type PageExperienceProgress = {
  totalPages?: number
  totalElements?: number
  size?: number
  content?: ExperienceProgress[]
  number?: number
  sort?: SortObject[]
  first?: boolean
  last?: boolean
  numberOfElements?: number
  pageable?: PageableObject
  empty?: boolean
}
export type ExperienceJoin = {
  id?: number
  userId: string
  timeStamp?: string
}
export type PageExperienceJoin = {
  totalPages?: number
  totalElements?: number
  size?: number
  content?: ExperienceJoin[]
  number?: number
  sort?: SortObject[]
  first?: boolean
  last?: boolean
  numberOfElements?: number
  pageable?: PageableObject
  empty?: boolean
}
export const {
  useGetVoucherByIdQuery,
  useUpdateVoucherMutation,
  useDeleteVoucherMutation,
  useUpdateUserRiddleMutation,
  useDeleteUserRiddleMutation,
  useGetRiddleByIdQuery,
  useUpdateRiddleMutation,
  useDeleteRiddleMutation,
  useGetPurchaseByIdQuery,
  useUpdatePurchaseMutation,
  useDeletePurchaseMutation,
  useGetLocationByIdQuery,
  useUpdateLocationMutation,
  useDeleteLocationMutation,
  useGetFeedbackByIdQuery,
  useUpdateFeedbackMutation,
  useUpdateExperienceMutation,
  useUpdatePrebuiltExperienceMutation,
  useGetCityByIdQuery,
  useUpdateCityMutation,
  useDeleteCityMutation,
  useGetCategoryByIdQuery,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
  useGetAllVouchersPagedQuery,
  useCreateVoucherMutation,
  useAddVoucherUsageMutation,
  useGetAllRiddlesPagedQuery,
  useCreateRiddleMutation,
  useGetAllPurchasesPagedQuery,
  useCreatePurchaseMutation,
  useCreateNewOrderMutation,
  useGetAllLocationsPagedQuery,
  useCreateLocationMutation,
  useGetAllFeedbackQuery,
  useCreateFeedbackMutation,
  useGetAllExperiencesQuery,
  useCreateExperienceMutation,
  useDeleteExperienceMutation,
  useAddProgressToExperienceMutation,
  useGetAllPrebuiltExperiencesQuery,
  useCreatePrebuiltExperienceMutation,
  useDeletePrebuiltExperienceMutation,
  useGetAllCitiesQuery,
  useCreateCityMutation,
  useGetAllCategoriesQuery,
  useCreateCategoryMutation,
  useSetGeneratorModeMutation,
  useIsVoucherValidQuery,
  useGetVoucherByCodeQuery,
  useGetAllUsersPagedQuery,
  useGetUserStatisticsQuery,
  useGetUserRiddlesQuery,
  useGetExperiencesOfUserQuery,
  useGetUserByIdQuery,
  useGetAllProgressQuery,
  useValidatePartnerVoucherQuery,
  useGetAllJoinsQuery,
  useSearchFeedbackQuery,
  useGetExperienceByIdQuery,
  useCreateExperienceForPrebuiltExperienceQuery,
  useJoinExperienceQuery,
  useGenerateExperienceQuery,
  useGetCityByGpsQuery,
  useGetStatisticQuery,
  useReloadDistancesQuery,
  usePrefetch,
} = injectedRtkApi
