import * as React from "react"
import { SyntheticEvent, useMemo, useState } from "react"
import { Box, CircularProgress, Grid, Tab, Tabs, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { CityRiddlerSubmitButton } from "../../app/themes"
import { RiddleFormErrors, RiddleFormText } from "../../i18"
import { mapFormToApi } from "./riddleMapper"
import {
  AddressInput,
  RiddleCategories,
  RiddleDifficulty,
  RiddleLanguageOptions,
  RiddleTextInput,
  RiddleTypeSelect,
  uploadImage,
} from "./formComponents"
import { RiddleFame } from "./formComponents/riddleFame"
import { SelectFormState, setFormState, setInitialState, setIsError } from "./riddleFormSlice"
import { CityRiddlerButton } from "../../app/themes/cityRiddlerButton"
import { a11yProps, CrFormTabPanel } from "../../app/utils/components/crFormTabPanel/crFormTabPanel"
import { Riddle, useCreateRiddleMutation, useUpdateRiddleMutation } from "../api/cityriddlerApi"
import { RiddleActive } from "./formComponents/riddleActive"
import { RiddleFlagNote } from "./formComponents/riddleFlagNote"
import { RiddleFlagged } from "./formComponents/riddleFlagged"
import { RiddleImageInput } from "./formComponents/riddleImageInput"
import { LocationImageInput } from "./formComponents/locationImageInput"
import { NavigationImageInput } from "./formComponents/navigationImageInput"
import { Errors } from "../../app/utils/components/error/errors"
import { Colors } from "../../app/colors"

export const RiddleForm = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { state } = useLocation()
  const formState = useAppSelector(SelectFormState)
  const [postFormData] = useCreateRiddleMutation()
  const [editRiddle] = useUpdateRiddleMutation()
  const [value, setValue] = React.useState(0)
  const [errors, setErrors] = useState<String[]>()

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const clickNext = (event: React.SyntheticEvent) => {
    setValue(value + 1)
  }

  useMemo(() => {
    if (state) {
      dispatch(setFormState(state))
      setIsEdit(state.edit)
    }
  }, [state])

  const validateFormData = () => {
    let isError = false
    let errors: String[] = []

    if (!formState.solutionType) {
      isError = true
      errors = [...errors, t(RiddleFormErrors.solutionType)!]
    }
    if (formState.solutionType === "MULTIPLE_CHOICE" && (!formState.choices || formState.choices.length < 2)) {
      isError = true
      errors = [...errors, t(RiddleFormErrors.multipleChoice)!]
    }
    if (formState.languages.length === 0) {
      isError = true
      errors = [...errors, t(RiddleFormErrors.languages)!]
    }
    if (formState.location.gpsData.latitude === 0 || formState.location.gpsData.longitude === 0) {
      isError = true
      errors = [...errors, t(RiddleFormErrors.gpsData)!]
    }
    if (!formState.riddleImageFile && !formState.riddleImage.url) {
      isError = true
      errors = [...errors, t(RiddleFormErrors.riddleImage)!]
    }
    if (!formState.locationImageFile && !formState.locationImage.url) {
      isError = true
      errors = [...errors, t(RiddleFormErrors.locationImage)!]
    }
    if (formState.categoryIds.length === 0) {
      isError = true
      errors = [...errors, t(RiddleFormErrors.categories)!]
    }
    if (!formState.difficulty) {
      isError = true
      errors = [...errors, t(RiddleFormErrors.difficulty)!]
    }
    if (!formState.location.prominence) {
      isError = true
      errors = [...errors, t(RiddleFormErrors.prominence)!]
    }

    dispatch(setIsError(isError))
    setErrors(errors)
    return isError
  }

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault()
    setLoading(true)
    const isError = validateFormData()
    if (!isError) {
      let riddleImageUrl: string | null = null
      if (formState.riddleImageFile) {
        const riddleUpload = await uploadImage(formState.riddleImageFile!)
        if (riddleUpload) {
          const { url } = riddleUpload
          riddleImageUrl = url
        }
      }
      let locationImageUrl: string | null = null
      if (formState.locationImageFile) {
        const locationUpload = await uploadImage(formState.locationImageFile!)
        if (locationUpload) {
          const { url } = locationUpload
          locationImageUrl = url
        }
      }
      let navigationImageUrl: string | null = null
      if (formState.navigationImageFile) {
        const navigationUpload = await uploadImage(formState.navigationImageFile!)
        if (navigationUpload) {
          const { url } = navigationUpload
          navigationImageUrl = url
        }
      }
      if (isEdit) {
        const mappedData = mapFormToApi(
          formState,
          riddleImageUrl ? riddleImageUrl.valueOf() : formState.riddleImage.url,
          locationImageUrl ? locationImageUrl.valueOf() : formState.locationImage.url,
          navigationImageUrl ? navigationImageUrl.valueOf() : formState.navigationImage?.url,
        )
        await editRiddle({
          riddle: { ...mappedData, id: state.id },
          id: state.id,
        })
      } else {
        let mappedData: Riddle
        mappedData = mapFormToApi(formState, riddleImageUrl!, locationImageUrl!, navigationImageUrl)
        await postFormData({ riddle: mappedData })
      }
      window.history.replaceState(undefined, "/riddleForm")
      dispatch(setInitialState())
      setLoading(false)
      navigate("/dashboard")
    } else {
      setLoading(false)
    }
  }

  return (
    <Grid container component="form" spacing={6} onSubmit={handleSubmit} sx={{ marginTop: 0, marginBottom: 5 }}>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            TabIndicatorProps={{
              style: {
                height: 5,
                backgroundColor: Colors.SecondaryLight,
              },
            }}
            sx={{
              textColor: Colors.BackgroundLight,
              backgroundColor: Colors.PrimaryLight,
            }}
            value={value}
            onChange={handleChange}
            aria-label="riddle form tabs"
            variant="fullWidth">
            <Tab label="Rätsel" {...a11yProps(0)} />
            <Tab label="Standort" {...a11yProps(1)} />
            <Tab label="Über das Rätsel" {...a11yProps(2)} />
          </Tabs>
        </Box>

        <CrFormTabPanel value={value} index={0}>
          <Grid container spacing={3} sx={{ marginTop: 0, marginBottom: 5 }}>
            <Grid item xs={12}>
              <RiddleTypeSelect />
            </Grid>

            <Grid item xs={12}>
              <RiddleLanguageOptions />
            </Grid>

            <Grid item container spacing={12}>
              <Grid item xs={12}>
                <RiddleTextInput />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <div>
                <div>
                  <CityRiddlerButton variant="contained" onClick={clickNext}>
                    {t(RiddleFormText.nextButton)}
                  </CityRiddlerButton>
                </div>
                <div>
                  <Typography variant="caption">{t(RiddleFormText.requiredInfo)}</Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </CrFormTabPanel>

        <CrFormTabPanel value={value} index={1}>
          <Grid container spacing={3} sx={{ marginTop: 0, marginBottom: 5 }}>
            <Grid item xs={12}>
              <AddressInput />
            </Grid>

            <Grid item xs={12}>
              <RiddleImageInput />
            </Grid>

            <Grid item xs={12}>
              <LocationImageInput />
            </Grid>

            <Grid item xs={12}>
              <NavigationImageInput />
            </Grid>

            <Grid item xs={12}>
              <div>
                <div>
                  <CityRiddlerButton variant="contained" onClick={clickNext}>
                    {t(RiddleFormText.nextButton)}
                  </CityRiddlerButton>
                </div>
                <div>
                  <Typography variant="caption">{t(RiddleFormText.requiredInfo)}</Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </CrFormTabPanel>

        <CrFormTabPanel value={value} index={2}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <RiddleCategories />
            </Grid>

            <Grid item xs={12}>
              <RiddleDifficulty />
            </Grid>

            <Grid item xs={12}>
              <RiddleFame />
            </Grid>

            <Grid item xs={6}>
              <RiddleFlagNote />
            </Grid>

            <Grid item xs={6}>
              <RiddleFlagged />
            </Grid>

            <Grid item xs={6}>
              <RiddleActive />
            </Grid>

            <Grid item xs={12}>
              <Errors errors={errors} />
            </Grid>

            <Grid item xs={12}>
              <div>
                <div>
                  <CityRiddlerSubmitButton type="submit" variant="contained" disabled={loading}>
                    {t(RiddleFormText.submitButton)} {loading && <CircularProgress />}
                  </CityRiddlerSubmitButton>
                </div>
                <div>
                  <Typography variant="caption">{t(RiddleFormText.requiredInfo)}</Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </CrFormTabPanel>
      </Box>
    </Grid>
  )
}
