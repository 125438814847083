import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { SelectJoinCode, setJoinCode } from "../experienceFormSlice"
import { SelectIsError } from "../../riddleForm/riddleFormSlice"
import { ExperienceFormText } from "../../../i18"
import { TextInput } from "../../../app/utils/components/input/textInput"

export const ExperienceJoinCode = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isFormError = useAppSelector(SelectIsError)
  const joinCode = useAppSelector(SelectJoinCode)
  const isError = isFormError && !joinCode

  const onChange = (text: string): void => {
    dispatch(setJoinCode(text))
  }

  return (
    <>
      <TextInput
        id="joinCode"
        required={true}
        error={isError}
        label={`${t(ExperienceFormText.experienceJoinCode)}`}
        infoText={t(ExperienceFormText.experienceJoinCodeInfo)}
        handleChange={onChange}
        multiline={false}
        value={joinCode}
      />
    </>
  )
}
