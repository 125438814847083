import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import { Grid } from "@mui/material"
import { updateEmail } from "firebase/auth"
import { SyntheticEvent, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { CityRiddlerFormLabel, CityRiddlerSubmitButton, CityRiddlerTextField } from "../../../../../../app/themes"
import { AuthContext } from "../../../../../../firebase/authProvider"
import { ProfileText } from "../../../../../../i18"

export const Email = () => {
  const { t } = useTranslation()
  const { user } = useContext(AuthContext)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [email, setEmail] = useState<string>(user?.email ?? "")

  const handleClick = () => {
    setIsEdit(!isEdit)
  }

  const handleChange = (event: any) => {
    setEmail(event.target.value)
  }

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault()
    handleClick()
    if (user) {
      await updateEmail(user, email)
    }
  }

  const handleReset = (event: SyntheticEvent) => {
    handleClick()
    setEmail(user?.email ?? "")
  }

  return (
    <Grid item container component="form" onSubmit={handleSubmit} onReset={handleReset} spacing={1}>
      <Grid item xs={12}>
        <CityRiddlerFormLabel>{t(ProfileText.emailLabel)}</CityRiddlerFormLabel>
      </Grid>
      <Grid item xs={8}>
        <CityRiddlerTextField
          placeholder={user?.email ?? ""}
          value={email}
          onChange={handleChange}
          size="small"
          disabled={!isEdit}
        />
      </Grid>
      {isEdit ? (
        <>
          <Grid item xs={2}>
            <CityRiddlerSubmitButton type="submit" variant="contained">
              {t(ProfileText.edit)}
            </CityRiddlerSubmitButton>
          </Grid>
          <Grid item xs={2}>
            <CityRiddlerSubmitButton type="reset" variant="contained">
              {t(ProfileText.cancel)}
            </CityRiddlerSubmitButton>
          </Grid>
        </>
      ) : (
        <Grid item xs={4}>
          <EditOutlinedIcon
            onClick={handleClick}
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
              height: "2vw",
              width: "2vw",
              zIndex: "100",
            }}
          />
        </Grid>
      )}
    </Grid>
  )
}
