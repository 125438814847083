import React, { useEffect, useState } from "react"
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api"
import { CircularProgress } from "@mui/material"
import Geocode from "react-geocode"
import locationDisplay from "../../../../images/LocationHoover.png"

const containerStyle = {
  width: "85%",
  height: "100%",
  referrerPolicy: "no-referrer-when-downgrade",
}

const options = {
  fullscreenControl: false,
  mapTypeControl: false,
  zoomControlOptions: {
    position: 19,
  },
  streetViewControl: false,
}

const libraries: (
  | "core"
  | "maps"
  | "places"
  | "geocoding"
  | "routes"
  | "marker"
  | "geometry"
  | "elevation"
  | "streetView"
  | "journeySharing"
  | "drawing"
  | "visualization"
)[] = ["core", "maps", "places", "routes", "marker", "geometry"]

interface CrGoogleMapProps {
  onMapClick: (result: google.maps.GeocoderResult, marker: google.maps.LatLng) => void
  marker: google.maps.LatLng | null
}

function CrGoogleMap(props: CrGoogleMapProps) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY ?? "",
    libraries: libraries,
  })

  const [clickedMarker, setClickedMarker] = useState<google.maps.LatLng | null>()
  const [addressMarker, setAddressMarker] = useState<google.maps.LatLng | null>()

  const [currentPosition, setCurrentPosition] = useState<google.maps.LatLng | null>(null)
  const [map, setMap] = React.useState<google.maps.Map | null>(null)

  console.log(props.marker?.toJSON())

  useEffect(() => {
    if (props.marker != null) {
      console.log("set initial marker")
      setAddressMarker(props.marker)
      setClickedMarker(null)
      map?.panTo(props.marker)
    }
  }, [props.marker])

  const onMapClickIntern = async (event: google.maps.MapMouseEvent) => {
    if (event.latLng) {
      Geocode.setLocationType("ROOFTOP")
      const allResults: google.maps.GeocoderResult[] = (
        await Geocode.fromLatLng(
          event.latLng.lat().toString(),
          event.latLng.lng().toString(),
          process.env.REACT_APP_GOOGLE_API_KEY,
        )
      ).results
      const filteredResults = allResults.filter((result: google.maps.GeocoderResult) =>
        result.types.some((type) => type === "street_address"),
      )
      const results = filteredResults.length > 0 ? filteredResults : allResults
      console.log(results)
      const address = results[0]
      props.onMapClick(address, event.latLng)
      setClickedMarker(event.latLng)
      setAddressMarker(null)
    }
  }

  const onLoad = React.useCallback((map: google.maps.Map) => {
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(() => {
    setMap(null)
  }, [])

  useEffect(() => {
    navigator?.geolocation.getCurrentPosition((position: GeolocationPosition) => {
      const { longitude, latitude } = position.coords
      const latLng: google.maps.LatLng = new google.maps.LatLng({
        lat: latitude,
        lng: longitude,
      })
      console.log(latLng.toJSON())
      setCurrentPosition(latLng)
    })
  }, [])

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={currentPosition ?? undefined}
      zoom={17}
      onClick={onMapClickIntern}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={options}>
      {clickedMarker != null ? <MarkerF position={clickedMarker!} icon={locationDisplay} /> : <></>}
      {addressMarker != null ? <MarkerF position={addressMarker!} icon={locationDisplay} /> : <></>}
    </GoogleMap>
  ) : (
    <CircularProgress />
  )
}

export default React.memo(CrGoogleMap)
