import { CircularProgress, Grid } from "@mui/material"
import { useContext, useState } from "react"
import { AuthContext } from "../../../../firebase/authProvider"
import { PlayerGraph } from "./playerGraph"
import { RiddleSelector } from "./riddleSelector"
import { extractText } from "../../../../app/utils/components/text/internationalTextService"
import { useGetStatisticQuery, useGetUserStatisticsQuery, UserStatistic } from "../../../api/cityriddlerApi"

let voidConst: void

export const RiddleStatistics = () => {
  const { user } = useContext(AuthContext)
  const [selectedRiddle, setSelectedRiddle] = useState<number>(-1)

  const { data: userData, isLoading: userIsLoading } = useGetUserStatisticsQuery(
    { userId: user?.uid! },
    { skip: !user || user!.admin },
  )
  const { data: adminData, isLoading: adminIsLoading } = useGetStatisticQuery(voidConst, {
    skip: !user || !user!.admin,
  })

  const getData = (): UserStatistic | undefined => {
    if (user) {
      if (user.admin) {
        return adminData
      } else {
        return userData
      }
    }
  }

  const calculateAllRiddlesData = () => {
    const dataMap = new Map<String, number>()
    if (getData()) {
      const indexMap = getData()!.riddleStatistics
      const riddleStatistics = new Map(Object.entries(indexMap))

      riddleStatistics.forEach((statistic, index) => {
        const dailyUsage = new Map(Object.entries(statistic.dailyUsage))

        dailyUsage.forEach((value, key) => {
          if (dataMap.has(key)) {
            dataMap.set(key, (dataMap.get(key) ?? 0) + value)
          } else {
            dataMap.set(key, value)
          }
        })
      })
      return new Map(Array.from(dataMap.entries()).sort())
    }
    return dataMap
  }

  return (
    <>
      {(userIsLoading || adminIsLoading) && <CircularProgress />}
      {getData() && (
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <RiddleSelector
              selected={selectedRiddle}
              options={
                getData()?.riddles.map((riddle) => {
                  return {
                    id: riddle.id!!.toString(),
                    name: extractText(riddle.title).text,
                  }
                }) ?? []
              }
              riddleStateSetter={setSelectedRiddle}
            />
          </Grid>
          <Grid item xs={12}>
            <PlayerGraph
              statistic={selectedRiddle !== -1 ? getData()?.riddleStatistics[selectedRiddle] : undefined}
              allData={calculateAllRiddlesData()}
            />
          </Grid>
          {/*  <Grid item container xs={12}>*/}
          {/*    {!selectedRiddle && (*/}
          {/*      <>*/}
          {/*        <Grid item xs={7}>*/}
          {/*          <RiddleRevenues*/}
          {/*            riddles={getData().riddleStatistics.map((riddle, index) => {*/}
          {/*              return {*/}
          {/*                name: extractText(getData().riddles[index].title).text,*/}
          {/*                totalRevenue: riddle.totalRevenue,*/}
          {/*              }*/}
          {/*            })}*/}
          {/*          />*/}
          {/*        </Grid>*/}
          {/*        <Grid item xs={5}></Grid>*/}
          {/*      </>*/}
          {/*    )}*/}
          {/*    {selectedRiddle && (*/}
          {/*      <>*/}
          {/*        <Grid item xs={6}></Grid>*/}
          {/*        <Grid item xs={6}></Grid>*/}
          {/*      </>*/}
          {/*    )}*/}
          {/*  </Grid>*/}
        </Grid>
      )}
    </>
  )
}
