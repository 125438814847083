import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage"
import { jwtDecode } from "jwt-decode"
import uuid4 from "uuid4"
import { app } from "../../../../features/auth/firebase/firebaseSetup"
import { getAuth } from "firebase/auth"

export const storage = getStorage(app)

export async function uploadImage(file: File) {
  const token = await getAuth(app).currentUser?.getIdToken()

  if (token) {
    const decodedToken: any = jwtDecode(token)
    const userId = decodedToken?.user_id
    const generatedId = uuid4()
    const storageRef = ref(storage, `riddles/${userId}/${generatedId}`)
    const image = await uploadBytes(storageRef, file)
    const url = await getDownloadURL(storageRef)
    return { image, url, generatedId }
  }
  return null
}
