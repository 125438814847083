import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit"
import { apiSlice } from "../features/api/apiSlice"
import authReducer from "../features/auth/authSlice"
import riddlesReducer from "../features/riddles/riddlesSlice"
import riddleFormReducer from "../features/riddleForm/riddleFormSlice"
import experienceFormReducer from "../features/experienceForm/experienceFormSlice"
import experiencesReducer from "../features/experiences/experiencesSlice"

export const store = configureStore({
  reducer: {
    auth: authReducer,
    riddles: riddlesReducer,
    riddleForm: riddleFormReducer,
    experienceForm: experienceFormReducer,
    experiences: experiencesReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(apiSlice.middleware),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
