import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom"
import "./App.css"
import { Auth } from "./features/auth/auth"
import { Dashboard } from "./features/dashboard/dashboard"
import { MapDisplayPage } from "./features/mapDisplay"
import { RiddleStatisticsPage } from "./features/profile/components/riddleStatistics"
import { ProfilePage } from "./features/profile/profilePage"
import { RiddleFormPage } from "./features/riddleForm/riddleFormPage"
import { Riddles } from "./features/riddles/riddles"
import { AuthContext, AuthProvider } from "./firebase/authProvider"
import { ProtectedRoute } from "./ProtectedRoute"
import { Experiences } from "./features/experiences/experiences"
import { usePrefetch } from "./features/api/cityriddlerApi"
import { getAuth } from "firebase/auth"
import { app } from "./features/auth/firebase/firebaseSetup"
import { useContext } from "react"
import { ExperienceFormPage } from "./features/experienceForm/experienceFormPage"

function App() {
  const { user } = useContext(AuthContext)
  const prefetchRiddles = usePrefetch("getAllRiddlesPaged")
  const prefetchExperiences = usePrefetch("getAllExperiences")

  getAuth(app).onAuthStateChanged(async (user) => {
    console.log(user)
    if (user) {
      console.log("prefetch")
      prefetchRiddles({})
      prefetchExperiences({ page: 0, size: 10 })
    }
  })

  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route path="auth" element={<Auth />} />
          <Route
            path="dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="profile"
            element={
              <ProtectedRoute>
                <ProfilePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="profile/riddlestatistics"
            element={
              <ProtectedRoute>
                <RiddleStatisticsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="riddleForm"
            element={
              <ProtectedRoute>
                <RiddleFormPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="riddles"
            element={
              <ProtectedRoute>
                <Riddles />
              </ProtectedRoute>
            }
          />
          {/*{user?.admin && (*/}
          <Route
            path="experiences"
            element={
              <ProtectedRoute>
                <Experiences />
              </ProtectedRoute>
            }
          />
          {/*)}*/}
          {/*{user?.admin && (*/}
          <Route
            path="experienceForm"
            element={
              <ProtectedRoute>
                <ExperienceFormPage />
              </ProtectedRoute>
            }
          />
          {/*)}*/}
          <Route
            path="mapdisplay"
            element={
              <ProtectedRoute>
                <MapDisplayPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="help"
            element={
              <ProtectedRoute>
                <></>
              </ProtectedRoute>
            }
          />
        </Routes>
        {/*<AuthVerify />*/}
      </Router>
    </AuthProvider>
  )
}

export default App
