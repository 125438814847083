import LoadingOverlay from "react-loading-overlay-ts"
import * as React from "react"

interface CrLoadingOverlayProperties {
  active: boolean
  label: string
  children?: React.ReactNode
}

export const CrLoadingOverlay = (props: CrLoadingOverlayProperties) => {
  return (
    <LoadingOverlay
      active={props.active}
      spinner
      text={props.label}
      styles={{
        spinner: (base) => ({
          ...base,
          // width: '100px',
          "& svg circle": {
            stroke: "rgba(253, 167, 64, 1)",
          },
        }),
        overlay: (base) => ({
          ...base,
          background: "rgba(93, 218, 221, 0.8)",
        }),
      }}>
      {props.children}
    </LoadingOverlay>
  )
}
