import React, { useEffect, useState } from "react"
import { Grid, Typography } from "@mui/material"
import { CityRiddlerSwitch } from "../../../themes/cityRiddlerSwitch"
import { TooltipIcon } from "../info/infoIcon"
import { CityRiddlerFormLabel } from "../../../themes"

interface SwitchProps {
  id: string
  handleChange: any
  value: boolean
  required: boolean
  label?: string
  index?: any
  infoText: string
}

export const Switch = (props: SwitchProps) => {
  const { id, handleChange, value, required, label, index } = props
  const [switchValue, setSwitchValue] = useState<boolean>(value ?? false)

  const onChange = (event: any) => {
    setSwitchValue(event.target.value)
  }

  useEffect(() => {
    setSwitchValue(value)
  }, [value])

  return (
    <>
      {label && (
        <Grid item xs={12}>
          <CityRiddlerFormLabel
            htmlFor={id}
            required={required}
            focused={false}
            sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ display: "flex", alignItems: "center" }}>
              <TooltipIcon text={props.infoText} />
              {label}
            </Typography>
          </CityRiddlerFormLabel>
        </Grid>
      )}
      <CityRiddlerSwitch
        id={id}
        onChange={onChange}
        defaultChecked={switchValue}
        value={switchValue}></CityRiddlerSwitch>
    </>
  )
}
