import { useTranslation } from "react-i18next"
import { ExperienceFormText } from "../../../i18"
import { FileInput } from "../../riddleForm/formComponents"
import {
  SelectCoverImageUrl,
  SelectIsCoverImageFileAvailable,
  SelectIsError,
  setCoverImageFile,
} from "../experienceFormSlice"
import React from "react"

export const ExperienceCoverImage = () => {
  const { t } = useTranslation()

  return (
    <FileInput
      errorSelector={SelectIsError}
      fileSelector={SelectIsCoverImageFileAvailable}
      upload={setCoverImageFile}
      urlSelector={SelectCoverImageUrl}
      label={t(ExperienceFormText.experienceCoverImage)}
      infoText={t(ExperienceFormText.experienceCoverImageInfo)}
      required={false}
    />
  )
}
