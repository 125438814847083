import { Grid, InputLabel, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import useDebounce from "../../../../app/hooks/useDebounce"
import { CityRiddlerFormLabel, CityRiddlerTextField } from "../../../../app/themes"
import { RiddleFormText } from "../../../../i18"
import { SelectIsError, SelectRiddleLocation, setRiddleLocation } from "../../riddleFormSlice"
import MapsHelper from "./mapsHelper"
import CrGoogleMap from "./crMap"
import Geocode from "react-geocode"
import { CrMetadata } from "../../../api/cityriddlerApi"
import { createMetadata } from "../../../../app/service/metadataService"
import { TooltipIcon } from "../../../../app/utils/components/info/infoIcon"

export const AddressInput = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isFormError = useAppSelector(SelectIsError)
  const riddleLocation = useAppSelector(SelectRiddleLocation)
  const [locationMetadata] = useState<CrMetadata | null>(riddleLocation.metadata)
  const [street, setStreet] = useState<string | null>(riddleLocation.address!.street)
  const [zip, setZip] = useState<string | null>(riddleLocation.address!.zipCode)
  const [streetNumber, setStreetNumber] = useState<string | null>(riddleLocation.address!.streetNumber)
  const [city, setCity] = useState<string | null>(riddleLocation.address!.city)
  const [country, setCountry] = useState<string | null>(riddleLocation.address!.country)
  const [lat, setLat] = useState<number | null>(riddleLocation.gpsData.latitude)
  const [lng, setLng] = useState<number | null>(riddleLocation.gpsData.longitude)
  const [marker, setMarker] = useState<google.maps.LatLng | null>()
  const [formattedAddress, setFormattedAddress] = useState<string | null>()
  const debouncedStreetNumber = useDebounce(streetNumber, 500)
  const debouncedStreet = useDebounce(street, 500)
  const debouncedZip = useDebounce(zip, 500)
  const debouncedCity = useDebounce(city, 500)
  const debouncedLat = useDebounce(lat, 500)
  const debouncedLon = useDebounce(lng, 500)
  const debouncedCountry = useDebounce(country, 500)
  const isError = isFormError && (!riddleLocation.gpsData.latitude || !riddleLocation.gpsData.longitude)

  useEffect(() => {
    const getGoogleData = async () => {
      console.log("getGoogleData")
      dispatch(
        setRiddleLocation({
          address: {
            street: street ?? "",
            streetNumber: streetNumber ?? "",
            zipCode: zip ?? "",
            city: city ?? "",
            country: country ?? "",
          },
          gpsData: {
            latitude: lat ?? -1,
            longitude: lng ?? -1,
          },
          prominence: riddleLocation.prominence,
          facts: [],
          image: { url: "" },
          metadata: locationMetadata ?? createMetadata()!,
        }),
      )
    }

    if ((debouncedStreet && debouncedStreetNumber) || debouncedZip) {
      getGoogleData()
    }
  }, [
    debouncedStreet,
    debouncedZip,
    debouncedStreetNumber,
    debouncedCountry,
    debouncedCity,
    debouncedLat,
    debouncedLon,
  ])

  const onChange = async (event: any) => {
    console.log("onChange")
    switch (event.target.id) {
      case "street": {
        setStreet(event.target.value)
        break
      }
      case "streetNumber": {
        setStreetNumber(event.target.value)
        break
      }
      case "zip": {
        setZip(event.target.value)
        break
      }
      case "city": {
        setCity(event.target.value)
        break
      }
      case "country": {
        setCountry(event.target.value)
        break
      }
      default: {
        break
      }
    }

    if (street && streetNumber && zip) {
      const address = `${street} ${streetNumber}, ${zip}`
      const result = (await Geocode.fromAddress(address, process.env.REACT_APP_GOOGLE_API_KEY)).results[0]
      const { lat, lng } = result.geometry.location
      setFormattedAddress(result.formatted_address)
      // const lat = location.lat().toString()
      // const lng = location.lng().toString()
      console.log(lat, lng)
      setLat(lat)
      setLng(lng)
      setMarker(result.geometry.location)
    }
  }

  const onMapClick = async (result: google.maps.GeocoderResult, marker: google.maps.LatLng) => {
    console.log("onMapClick")
    const { street, streetNumber, zipCode, city, country } = MapsHelper.extractAddress(result)

    console.log(street, streetNumber, zipCode, city, country)
    console.log(marker.lat(), marker.lng())
    const lat = marker.lat()
    const lng = marker.lng()

    dispatch(
      setRiddleLocation({
        address: {
          street: street ?? "",
          streetNumber: streetNumber ?? "",
          zipCode: zipCode ?? "",
          city: city ?? "",
          country: country ?? "",
        },
        gpsData: {
          latitude: lat,
          longitude: lng,
        },
        prominence: riddleLocation.prominence,
        facts: [],
        image: { url: "" },
        metadata: locationMetadata ?? createMetadata()!,
      }),
    )

    if (street) setStreet(street)
    if (streetNumber) setStreetNumber(streetNumber)
    if (zipCode) setZip(zipCode)
    if (city) setCity(city)
    if (country) setCountry(country)
    if (lat) setLat(lat)
    if (lng) setLng(lng)
  }

  return (
    <>
      <Grid container item xs={12} spacing={3}>
        <Grid item xs={12}>
          <CityRiddlerFormLabel required focused={false} sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ display: "flex", alignItems: "center" }}>
              <TooltipIcon text={`${t(RiddleFormText.googleInfo)}`} />
              {t(RiddleFormText.google)}
            </Typography>
          </CityRiddlerFormLabel>
        </Grid>
      </Grid>
      <Grid item container xs={12} spacing={2}>
        <Grid item md={6} xs={12} sx={{ minHeight: "300px" }}>
          <CrGoogleMap
            onMapClick={onMapClick}
            marker={lat != null && lng != null ? new google.maps.LatLng(lat, lng) : null}
          />
        </Grid>
        <Grid item container md={6} xs={12} spacing={4}>
          <Grid item xs={6}>
            <InputLabel htmlFor="street" required error={isError}>
              {t(RiddleFormText.address)}
            </InputLabel>
            <CityRiddlerTextField value={street} id="street" onChange={onChange} size="small" error={isError} />
          </Grid>
          <Grid item xs={3}>
            <InputLabel htmlFor="streetNumber" required error={isError}>
              {t(RiddleFormText.streetNumber)}
            </InputLabel>
            <CityRiddlerTextField
              value={streetNumber}
              id="streetNumber"
              onChange={onChange}
              size="small"
              error={isError}
            />
          </Grid>
          <Grid item xs={3}>
            <InputLabel htmlFor="zip" required error={isError}>
              {t(RiddleFormText.zip)}
            </InputLabel>
            <CityRiddlerTextField value={zip} id="zip" onChange={onChange} size="small" error={isError} />
          </Grid>
          <Grid item xs={6}>
            <InputLabel htmlFor="city" required error={isError}>
              {t(RiddleFormText.city)}
            </InputLabel>
            <CityRiddlerTextField
              value={city}
              disabled={true}
              id="city"
              onChange={onChange}
              size="small"
              error={isError}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel htmlFor="country" required error={isError}>
              {t(RiddleFormText.country)}
            </InputLabel>
            <CityRiddlerTextField
              value={country}
              disabled={true}
              id="country"
              onChange={onChange}
              size="small"
              error={isError}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel htmlFor="gps-lat" required error={isError}>
              Latitude
            </InputLabel>
            <CityRiddlerTextField
              value={lat}
              id="gps-lat"
              onChange={onChange}
              size="small"
              error={isError}
              disabled={true}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel htmlFor="gps-lng" required error={isError}>
              Longitude
            </InputLabel>
            <CityRiddlerTextField
              value={lng}
              id="gps-lng"
              onChange={onChange}
              size="small"
              error={isError}
              disabled={true}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
