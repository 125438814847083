import { Button, Container, Grid, Typography } from "@mui/material"
import { Colors } from "../../../../app/colors"
import { Input } from "../utils"
import { FormEvent, useState } from "react"
import { logInWithEmailAndPassword } from "../../firebase/firebaseSetup"
import { useModal } from "mui-modal-provider"
import { AlertPopup, AlertTypes } from "../../../../app/utils/components/alert/alert"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { ForgottenPasswordText, LoginText } from "../../../../i18"
import { Page } from "../../auth"

const initialState = {
  email: "",
  password: "",
}

interface LoginProps {
  changeView: any
}

export const Login = (props: LoginProps) => {
  const { changeView } = props
  const { showModal } = useModal()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [userData, setUserData] = useState(initialState)

  const goToRegisterPage = () => {
    changeView(Page.register)
  }

  const goToForgottenPasswordPage = () => {
    changeView(Page.forgottenPassword)
  }

  const handleChange = (e: any) => {
    setUserData({ ...userData, [e.target.name]: e.target.value })
  }

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()

    const result = await logInWithEmailAndPassword(userData.email, userData.password)

    if (typeof result === "string") {
      const modal: any = showModal(AlertPopup, {
        alertType: AlertTypes.Error,
        alertMessage: result,
        onClick: () => {
          modal.destroy()
        },
        isError: true,
      })
    } else {
      navigate("/dashboard")
    }
  }

  return (
    <>
      <Container
        component="main"
        maxWidth="md"
        sx={{
          marginTop: "16%",
        }}>
        <Grid container spacing={1} alignItems="flex-end" sx={{ marginBottom: "30px" }}>
          <Grid item>
            <Typography variant="h4">{t(LoginText.greetingPartOne)}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">{t(LoginText.greetingPartTwo)}</Typography>
          </Grid>
        </Grid>

        <form onSubmit={onSubmit}>
          <Grid container spacing={3}>
            <Input
              name="email"
              label={t(LoginText.emailFieldLabel)}
              type="email"
              handleChange={handleChange}
              required={true}
            />
            <Input
              name="password"
              label={t(LoginText.passwordFieldLabel)}
              type="password"
              handleChange={handleChange}
              required={true}
            />
            <Grid item container spacing={2} alignItems="flex-end">
              <Grid item>
                <Button
                  sx={{
                    background: Colors.Primary,
                    "&:hover": {
                      background: Colors.PrimaryDark,
                    },
                  }}
                  type="submit"
                  fullWidth
                  variant="contained">
                  {t(LoginText.loginButtonLabel)}
                </Button>
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    color: Colors.Black,
                    cursor: "pointer",
                    textDecoration: "underline",
                    "&:hover": {
                      textDecoration: "none",
                    },
                  }}
                  variant="subtitle1"
                  onClick={goToForgottenPasswordPage}>
                  {t(ForgottenPasswordText.link)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="flex-end" sx={{ marginTop: "15px" }}>
            <Grid item>
              <Typography variant="subtitle1">{t(LoginText.newUserQuestion)}</Typography>
            </Grid>
            <Grid item onClick={goToRegisterPage}>
              <Typography
                sx={{
                  color: Colors.Primary,
                  cursor: "pointer",
                  textDecoration: "underline",
                  "&:hover": {
                    textDecoration: "none",
                  },
                }}
                variant="subtitle1">
                {t(LoginText.newUserRegistrationLink)}
              </Typography>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  )
}
