import { Footer } from "../../app/utils/components"
import { DesktopDashboard, MobileDashboard } from "./components"

export const Dashboard = () => {
  return (
    <>
      <DesktopDashboard />
      <MobileDashboard />
      <Footer />
    </>
  )
}
