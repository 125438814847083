import { Card, Grid, Typography } from "@mui/material"
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js"
import annotationPlugin, { LineAnnotationOptions } from "chartjs-plugin-annotation"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { Colors } from "../../../../app/colors"
import { _DeepPartialObject } from "chart.js/dist/types/utils"
import { Line } from "react-chartjs-2"
import { DailyUsage } from "../../../../app/data"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, annotationPlugin, ChartDataLabels)

export const UsageChart = (props: { usageData: DailyUsage[] }) => {
  const { usageData } = props

  const labels = usageData.map((element) => element.label)
  const values = usageData.map((element) => element.data)
  const data = {
    labels,
    datasets: [
      {
        label: "usage",
        data: values,
        barPercentage: 0.5,
        backgroundColor: Colors.Primary,
        borderRadius: 40,
        borderSkipped: false,
      },
    ],
  }
  const summ = values.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
  const average = (summ / values.length).toFixed(3)

  const annotation: _DeepPartialObject<{ type: "line" }> & LineAnnotationOptions = {
    type: "line",
    borderColor: Colors.TiffanyBlue,
    borderDash: [6, 6],
    borderDashOffset: 0,
    borderWidth: 3,
    yMin: average,
    yMax: average,
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      annotation: {
        annotations: {
          annotation,
        },
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      y: {
        display: false,
      },
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
      },
    },
  }

  return (
    <Card sx={{ borderRadius: "20px" }} elevation={3}>
      <Grid container>
        <Grid item container xs={8}>
          <Grid item xs={12}>
            <Typography variant="h4" sx={{ paddingTop: "15px", paddingLeft: "10px" }}>
              Nutzer
            </Typography>
          </Grid>
          <Grid container item xs={12} sx={{ justifyContent: "center" }}>
            <Grid item xs={11}>
              <Line options={options} data={data} />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={4}
          sx={{
            backgroundColor: Colors.BackgroundGreen,
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Grid item container xs={12} sx={{ marginTop: "15%", marginLeft: "8%" }}>
            <Grid
              item
              xs={3}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Typography
                sx={{
                  height: "25px",
                  width: "25px",
                  backgroundColor: Colors.Primary,
                  borderRadius: "50%",
                  display: "inline-block",
                }}></Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="h5">{summ}</Typography>
              <Typography fontSize="13px" color="#0009">
                GESAMT
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} sx={{ marginBottom: "15%", marginLeft: "8%" }}>
            <Grid
              item
              xs={3}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Typography
                sx={{
                  height: "25px",
                  width: "25px",
                  backgroundColor: Colors.TiffanyBlue,
                  borderRadius: "50%",
                  display: "inline-block",
                }}></Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="h5">
                {Number(average).toFixed(2) === "NaN" ? Number(0).toFixed(2) : Number(average).toFixed(2)}
              </Typography>
              <Typography fontSize="13px" color="#0009">
                DURCHSCHNITT
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}
