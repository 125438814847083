import { Grid } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Navigation } from "../../../../app/utils/components"
import { ProfileText } from "../../../../i18"
import { RiddleStatistics } from "./riddleStatistics"

export const RiddleStatisticsPage = () => {
  const { t } = useTranslation()
  return (
    <Grid container maxWidth="95%" spacing={2} sx={{ margin: "auto" }} display={{ xs: "flex", md: "flex" }}>
      <Navigation pageName={t(ProfileText.statistics)} />
      <Grid container item xs={10}>
        <RiddleStatistics />
      </Grid>
    </Grid>
  )
}
