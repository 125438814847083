import { Grid } from "@mui/material"
import _ from "lodash"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { RootState } from "../../../../app/store"
import { CityRiddlerFormInputLabel } from "../../../../app/themes"
import { TextInput } from "../../../../app/utils/components/input/textInput"
import { RiddleFormText } from "../../../../i18"
import {
  SelectIsError,
  SelectRiddleTextInputFieldsData,
  setChoices,
  setDescription,
  setFacts,
  setHints,
  setNavigation,
  setSolution,
  setTitle,
} from "../../riddleFormSlice"
import { HintsInputField } from "./hintsInputField"
import { InternationalText, Language } from "../../../api/cityriddlerApi"
import { FactTypes } from "../../../../app/data/factModels"
import { PriorityText } from "../../../../app/data"

interface RiddleTextInputFieldsProperties {
  language: Language
  multiSelect: boolean
}

export const RiddleTextInputFields = (props: RiddleTextInputFieldsProperties) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { language, multiSelect } = props
  const isFormError = useAppSelector(SelectIsError)
  const { title, description, navigation, solution, choices, hints, facts } = useAppSelector((state: RootState) =>
    SelectRiddleTextInputFieldsData(state, language),
  )

  const funFact = facts.find((fact) => {
    return fact.type === FactTypes.FunFact
  })
  const historicFact = facts.find((fact) => {
    return fact.type === FactTypes.HistoricFact
  })

  const handleTitleChange = (value: string) => {
    console.log("handleTitleChange")
    let updatedTitle = _.cloneDeep(title!)
    updatedTitle.text = value
    dispatch(setTitle(updatedTitle))
  }

  const handleDescriptionChange = (value: string) => {
    console.log("handleDescriptionChange")
    let updatedDescription = _.cloneDeep(description!)
    updatedDescription.text = value
    dispatch(setDescription(updatedDescription))
  }

  const handleSolutionChange = (value: string) => {
    console.log("handleSolutionChange")
    let updatedSolution = _.cloneDeep(solution!)
    updatedSolution.text = value
    dispatch(setSolution(updatedSolution))
  }

  const handleNavigationChange = (value: string) => {
    console.log("handleNavigationChange")
    if (navigation) {
      let updatedNavigation = _.cloneDeep(navigation)
      updatedNavigation.text = value
      dispatch(setNavigation(updatedNavigation))
    } else {
      let newNavigation = {
        text: value,
        language: language,
      } as InternationalText
      dispatch(setNavigation(newNavigation))
    }
  }

  const handleChoicesChange = (data: PriorityText[], input?: { text: string; index: number }) => {
    console.log("handleChoicesChange")
    let choices = _.cloneDeep(data)
    console.log(choices)
    if (!input) {
      choices.forEach((choice, index) => (choice.priority = index))
    } else {
      choices.forEach((choice, index) => {
        choice.priority = index
        if (index === input.index && choice.language.id === language.id) {
          choice.text = input.text
        }
      })
    }
    console.log(choices)
    dispatch(setChoices(choices))
  }

  const handleHintsChange = (data: PriorityText[], input?: { text: string; index: number }) => {
    console.log("handleHintsChange")
    let hints = _.cloneDeep(data)
    console.log(hints)
    if (!input) {
      hints.forEach((hint, index) => (hint.priority = index))
    } else {
      hints.forEach((hint, index) => {
        hint.priority = index
        if (index === input.index && hint.language.id === language.id) {
          hint.text = input.text
        }
      })
    }
    console.log(hints)
    dispatch(setHints(hints))
  }

  const onFactChange = (text: string, type: string) => {
    let updatedFacts = _.cloneDeep(facts)

    updatedFacts.forEach((fact) => {
      if (fact.type === type) {
        fact.text = text
      }
    })

    dispatch(setFacts(updatedFacts))
  }

  const getLanguageName = (language: Language) => {
    switch (language.id) {
      case 1:
        return t(RiddleFormText.german)
      case 2:
        return t(RiddleFormText.english)
    }
  }

  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={12}>
        <CityRiddlerFormInputLabel htmlFor="riddleText" required error={isFormError}>
          {getLanguageName(language) + `:`}
        </CityRiddlerFormInputLabel>
      </Grid>
      <Grid container item id="riddleText" xs={12} spacing={2}>
        <Grid item md={6} xs={12}>
          <TextInput
            id={`${language.id}-name`}
            error={isFormError && !title?.text}
            required={true}
            value={title?.text ? title.text : ""}
            multiline={false}
            handleChange={handleTitleChange}
            label={`${t(RiddleFormText.riddleName)}:`}
            infoText={`${t(RiddleFormText.riddleNameInfo)}:`}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextInput
            id={`${language.id}-question`}
            error={isFormError && !description?.text}
            required={true}
            value={description?.text ? description.text : ""}
            multiline={false}
            handleChange={handleDescriptionChange}
            label={`${t(RiddleFormText.question)}:`}
            infoText={`${t(RiddleFormText.questionInfo)}:`}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextInput
            id={`${language.id}-solution`}
            error={isFormError && !description?.text}
            required={true}
            value={solution?.text ? solution.text : ""}
            multiline={false}
            handleChange={handleSolutionChange}
            label={`${t(RiddleFormText.solution)}:`}
            infoText={`${t(RiddleFormText.solutionInfo)}:`}
          />
        </Grid>
        {multiSelect && (
          <Grid container item xs={12} spacing={2}>
            <HintsInputField
              name="choices"
              label={t(RiddleFormText.answer)}
              handleChange={handleChoicesChange}
              data={choices}
              error={isFormError}
              maxInputCount={4}
              language={language}
              infoText={t(RiddleFormText.answerInfo)}
              required={false}
            />
          </Grid>
        )}
        <Grid container item xs={12} spacing={2}>
          <HintsInputField
            infoText={t(RiddleFormText.hintInfo)}
            required={false}
            name="hints"
            label={t(RiddleFormText.hint)}
            handleChange={handleHintsChange}
            data={hints}
            error={isFormError}
            maxInputCount={4}
            language={language}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <TextInput
            id={`${language.id}-${FactTypes.HistoricFact}`}
            error={isFormError && !historicFact?.text}
            required={true}
            value={historicFact?.text ?? ""}
            multiline={true}
            handleChange={onFactChange}
            label={`${t(RiddleFormText.historicFact)}:`}
            infoText={`${t(RiddleFormText.historicFactInfo)}:`}
            index={FactTypes.HistoricFact}
            rows={4}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <TextInput
            id={`${language.id}-${FactTypes.FunFact}`}
            error={isFormError && !funFact?.text}
            required={false}
            value={funFact?.text ?? ""}
            multiline={true}
            handleChange={onFactChange}
            label={`${t(RiddleFormText.funFact)}:`}
            infoText={`${t(RiddleFormText.funFactInfo)}:`}
            index={FactTypes.FunFact}
            rows={4}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <TextInput
            id={`${language.id}-navigation`}
            error={isFormError && !navigation?.text}
            required={false}
            value={navigation?.text ? navigation.text : ""}
            multiline={true}
            handleChange={handleNavigationChange}
            label={`${t(RiddleFormText.navigation)}:`}
            infoText={`${t(RiddleFormText.navigationInfo)}:`}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
