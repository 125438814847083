import AddCircleIcon from "@mui/icons-material/AddCircle"
import { useNavigate } from "react-router-dom"
import { Colors } from "../../../colors"

interface AddCircleIconButtonProperties {
  destination: string
  fromPage: string
}

export const AddCircleIconButton = (props: AddCircleIconButtonProperties) => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate(`/${props.destination}`)
  }
  return (
    <AddCircleIcon
      sx={{
        color: Colors.Primary,
        fontSize: "75px",
        cursor: "pointer",
        "&:hover": { opacity: "80%" },
      }}
      onClick={handleClick}
    />
  )
}
