import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { SelectForceOffline, setForceOffline } from "../experienceFormSlice"
import { ExperienceFormText } from "../../../i18"
import { Switch } from "../../../app/utils/components/input/switch"

export const ExperienceForceOffline = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const forceOffline = useAppSelector(SelectForceOffline)

  const onChange = (forceOffline: boolean): void => {
    dispatch(setForceOffline(forceOffline))
  }

  return (
    <>
      <Switch
        id="title"
        required={true}
        label={`${t(ExperienceFormText.experienceForceOffline)}`}
        infoText={t(ExperienceFormText.experienceForceOfflineInfo)}
        handleChange={onChange}
        value={forceOffline}
      />
    </>
  )
}
