import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { SelectNeedsPayment, setNeedsPayment } from "../experienceFormSlice"
import { ExperienceFormText } from "../../../i18"
import { Switch } from "../../../app/utils/components/input/switch"

export const ExperienceNeedsPayment = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const needsPayment = useAppSelector(SelectNeedsPayment)

  const onChange = (needsPayment: boolean): void => {
    dispatch(setNeedsPayment(needsPayment))
  }

  return (
    <>
      <Switch
        id="title"
        required={true}
        label={`${t(ExperienceFormText.experienceNeedsPayment)}`}
        infoText={t(ExperienceFormText.experienceNeedsPaymentInfo)}
        handleChange={onChange}
        value={needsPayment}
      />
    </>
  )
}
