import { Grid } from "@mui/material"
import { NavigationMenu } from "../../../../features/dashboard/components/utils"
import { Logout } from "../logout/logout"
import { MobileMenu } from "../mobileMenu/mobileMenu"

interface NavigationProperties {
  pageName: string
}

export const Navigation = (props: NavigationProperties) => {
  const { pageName } = props
  return (
    <>
      <Grid item xs={12} display={{ xs: "none", md: "flex" }}>
        <Logout />
      </Grid>
      <Grid item xs={2} display={{ xs: "none", md: "block" }}>
        <NavigationMenu pageName={pageName} />
      </Grid>
      <Grid container item xs={12} display={{ xs: "flex", md: "none" }}>
        <MobileMenu />
      </Grid>
    </>
  )
}
