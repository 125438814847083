import { Grid } from "@mui/material"
import logo from "../../../../images/logo_petrol.png"

export const MobileFooter = () => {
  return (
    <Grid
      container
      sx={{
        marginTop: "5%",
        maxWidth: "95%",
        marginLeft: "auto",
        marginRight: "auto",
      }}
      display={{ xs: "flex", md: "none" }}>
      <Grid container item sm={12} justifyContent="center">
        <img src={logo} alt="Logo" />
      </Grid>
    </Grid>
  )
}
