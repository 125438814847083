import { ChevronLeft } from "@mui/icons-material"
import { Button, Grid, Typography } from "@mui/material"
import { sendPasswordResetEmail } from "firebase/auth"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Colors } from "../../../../app/colors"
import { Input } from "../../../../app/utils/components"
import { ForgottenPasswordText, LoginText } from "../../../../i18"
import { Page } from "../../auth"
import { auth } from "../../firebase/firebaseSetup"

interface ForgottenPasswordProperties {
  changeView: any
}

export const ForgottenPassword = (props: ForgottenPasswordProperties) => {
  const { changeView } = props
  const { t } = useTranslation()
  const [email, setEmail] = useState("")

  const handleChange = (event: any) => {
    setEmail(event.target.value)
  }

  const goToLoginPage = () => {
    changeView(Page.login)
  }

  const onSubmit = () => {
    sendPasswordResetEmail(auth, email).then(() => {
      changeView(Page.login)
    })
  }

  return (
    <Grid
      container
      component={"form"}
      onSubmit={onSubmit}
      maxWidth="sm"
      sx={{
        marginTop: "16%",
        marginBottom: "16%",
        marginLeft: "auto",
        marginRight: "auto",
      }}
      spacing={1}>
      <Grid item xs={12}>
        <ChevronLeft
          fontSize="large"
          onClick={goToLoginPage}
          sx={{
            cursor: "pointer",
            blockSize: "border-box",
            "&:hover": {
              backgroundColor: "rgba(0,0,0,0.2)",
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4">{t(ForgottenPasswordText.label)}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Input
          name="email"
          label={t(LoginText.emailFieldLabel)}
          type="email"
          handleChange={handleChange}
          required={true}
        />
      </Grid>
      <Grid item>
        <Button
          sx={{
            background: Colors.Primary,
            "&:hover": {
              background: Colors.PrimaryDark,
            },
          }}
          type="submit"
          fullWidth
          variant="contained">
          {t(ForgottenPasswordText.submit)}
        </Button>
      </Grid>
    </Grid>
  )
}
