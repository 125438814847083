import {
  SelectIsError,
  SelectIsLocationImageFileAvailable,
  SelectLocationImageUrl,
  setLocationImageFile,
} from "../riddleFormSlice"
import { FileInput } from "../../../app/utils/components/fileUpload"
import { RiddleFormText } from "../../../i18"
import { useTranslation } from "react-i18next"

export const LocationImageInput = () => {
  const { t } = useTranslation()

  return (
    <FileInput
      errorSelector={SelectIsError}
      fileSelector={SelectIsLocationImageFileAvailable}
      upload={setLocationImageFile}
      urlSelector={SelectLocationImageUrl}
      label={t(RiddleFormText.locationImage)}
      infoText={t(RiddleFormText.locationImageInfo)}
      required={true}
    />
  )
}
