export default class MapsHelper {
  static extractAddress(result: google.maps.GeocoderResult): {
    street: string
    streetNumber: string
    zipCode: string
    city: string
    country: string
  } {
    let street, streetNumber, zipCode, city, country
    for (let i = 0; i < result.address_components.length; i++) {
      for (let j = 0; j < result.address_components[i].types.length; j++) {
        switch (result.address_components[i].types[j]) {
          case "route":
            street = result.address_components[i].long_name
            break
          case "street_number":
            streetNumber = result.address_components[i].long_name
            break
          case "postal_code":
            zipCode = result.address_components[i].long_name
            break
          case "locality":
            city = result.address_components[i].long_name
            break
          case "country":
            country = result.address_components[i].long_name
            break
        }
      }
    }
    return {
      street: street ?? "",
      streetNumber: streetNumber ?? "",
      zipCode: zipCode ?? "",
      city: city ?? "",
      country: country ?? "",
    }
  }
}
