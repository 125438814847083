import { Grid } from "@mui/material"
import { useState } from "react"
import { CreatorInfo, CreatorSettings } from "./components"

export const Profile = () => {
  const [showOptions, setShowOptions] = useState<boolean>(false)

  return (
    <>
      <Grid item xs={12}>
        <CreatorInfo showOptions={showOptions} setShowOptions={setShowOptions} />
      </Grid>
      <Grid item xs={12}>
        <CreatorSettings showOptions={showOptions} setShowOptions={setShowOptions} />
      </Grid>
    </>
  )
}
