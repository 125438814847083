import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { SelectUsePoints, setUsePoints } from "../experienceFormSlice"
import { ExperienceFormText } from "../../../i18"
import { Switch } from "../../../app/utils/components/input/switch"

export const ExperienceUsePoints = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const usePoints = useAppSelector(SelectUsePoints)

  const onChange = (usePoints: boolean): void => {
    dispatch(setUsePoints(usePoints))
  }

  return (
    <>
      <Switch
        id="title"
        required={true}
        label={`${t(ExperienceFormText.experienceUsePoints)}`}
        infoText={t(ExperienceFormText.experienceUsePointsInfo)}
        handleChange={onChange}
        value={usePoints}
      />
    </>
  )
}
