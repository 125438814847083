import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { CityRiddlerFormLabel } from "../../../app/themes"
import { RiddleFormText } from "../../../i18"
import { SelectFame, SelectIsError, setFame } from "../riddleFormSlice"
import React, { ChangeEvent } from "react"
import { TooltipIcon } from "../../../app/utils/components/info/infoIcon"

export const RiddleFame = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isFormError = useAppSelector(SelectIsError)
  const fame = useAppSelector(SelectFame)
  const isError = isFormError && !fame

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setFame((event.target as HTMLInputElement).value as "STANDARD" | "MAIN_SIGHT" | "HIDDEN_GEM"))
  }

  return (
    <FormControl error={isError} required sx={{ minWidth: "100%" }}>
      <Grid container item xs={12} spacing={3}>
        <Grid item xs={12}>
          <CityRiddlerFormLabel required focused={false} sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ display: "flex", alignItems: "center" }}>
              <TooltipIcon text={`${t(RiddleFormText.fameInfo)}`} />
              {t(RiddleFormText.fame)}
            </Typography>
          </CityRiddlerFormLabel>
        </Grid>
        <Grid item xs={12}>
          <RadioGroup row aria-labelledby="fame" value={fame} onChange={handleChange}>
            <Grid container item xs={12} textAlign="center">
              <Grid item xs={3}></Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  labelPlacement="top"
                  value={"MAIN_SIGHT"}
                  control={<Radio />}
                  label={
                    <>
                      <Typography>{t(RiddleFormText.prominenz1)}</Typography>
                      <Typography>{t(RiddleFormText.prominenz1Example)}</Typography>
                    </>
                  }
                />
              </Grid>

              <Grid item xs={2}>
                <FormControlLabel
                  labelPlacement="top"
                  value={"STANDARD"}
                  control={<Radio />}
                  label={
                    <>
                      <Typography>{t(RiddleFormText.prominenz2)}</Typography>
                      <Typography>{t(RiddleFormText.prominenz2Example)}</Typography>
                    </>
                  }
                />
              </Grid>

              <Grid item xs={2}>
                <FormControlLabel
                  labelPlacement="top"
                  value={"HIDDEN_GEM"}
                  control={<Radio />}
                  label={
                    <>
                      <Typography>{t(RiddleFormText.prominenz3)}</Typography>
                      <Typography>{t(RiddleFormText.prominenz3Example)}</Typography>
                    </>
                  }
                />
              </Grid>

              <Grid item xs={3}></Grid>
            </Grid>
          </RadioGroup>
        </Grid>
      </Grid>
    </FormControl>
  )
}
