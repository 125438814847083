import { Grid } from "@mui/material"
import { getAuth, signOut } from "firebase/auth"
import { app } from "../../../../features/auth/firebase/firebaseSetup"
import { Colors } from "../../../colors"
import { NavLink } from "../navLink/navLink"

export const Logout = () => {
  const onClick = async () => {
    const auth = getAuth(app)
    await signOut(auth)
    // localStorage.setItem("profile", "")
  }

  return (
    <Grid container textAlign="center">
      <Grid item container xs={12} textAlign="right">
        <NavLink onClick={onClick} label="Log Out" destination="auth" color={Colors.Primary} />
      </Grid>
    </Grid>
  )
}
