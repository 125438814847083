import { Line } from "react-chartjs-2"
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js"
import { Colors } from "../../../../app/colors"
import { Card, CardContent, Grid, Typography } from "@mui/material"
import { UserRiddleStatistic } from "../../../api/cityriddlerApi"

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend)

interface PlayerGraphProperties {
  statistic?: UserRiddleStatistic
  allData: Map<String, number>
}

export const PlayerGraph = (props: PlayerGraphProperties) => {
  const { statistic, allData } = props

  let labels
  let values

  if (statistic) {
    labels = Object.keys(statistic.dailyUsage)
    values = Object.values(statistic.dailyUsage)
  } else {
    labels = Array.from(allData.keys())
    values = Array.from(allData.values())
  }

  const data = {
    labels,
    datasets: [
      {
        label: "usage",
        data: values,
        barPercentage: 0.5,
        backgroundColor: Colors.Primary,
        borderColor: Colors.Primary,
        borderRadius: 40,
        borderSkipped: false,
      },
    ],
  }
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      y: {
        display: true,
        beginAtZero: true,
        // grid: {
        //   drawBorder: false,
        // },
        ticks: {
          padding: 20,
        },
      },
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          padding: 20,
        },
      },
    },
  }

  return (
    <Card sx={{ borderRadius: "20px", maxWidth: "90%" }} elevation={3}>
      <CardContent>
        <Grid container spacing={7}>
          <Grid item container xs={12}>
            <Grid item xs={9}>
              <Typography variant="h4" sx={{ marginTop: "0.4vw", marginLeft: "2vw" }}>
                Nutzung
              </Typography>
            </Grid>
            <Grid item container xs={3}>
              <Grid item>
                <Typography
                  sx={{
                    marginTop: "1vw",
                    marginRight: "1vw",
                    height: "25px",
                    width: "25px",
                    backgroundColor: Colors.Primary,
                    borderRadius: "50%",
                    display: "inline-block",
                  }}
                />
              </Grid>
              <Grid item>
                <Typography fontSize="13px" color="#0009" sx={{ marginTop: "1.2vw" }}>
                  GESAMT
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Line id="RiddleStatistics" key={"RiddleStatistics"} options={options} data={data} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
