import { Checkbox, Grid, InputLabel, Typography } from "@mui/material"
import * as React from "react"
import { ChangeEvent, useState } from "react"
import { Colors } from "../../../colors"

interface helperText {
  text: string
  type: "link" | "text"
  route?: string
}

interface CheckboxProps {
  label?: string
  name: string
  helperTexts?: helperText[]
  checked: boolean
  required: boolean
  handleChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void
}

export const CheckboxInput = (props: CheckboxProps) => {
  const [checked, setChecked] = useState(props.checked)

  const onClickLink = () => {}
  const onClick = () => {
    setChecked((previous) => !previous)
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <InputLabel>{props.label}</InputLabel>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          my: "auto",
        }}>
        <Checkbox
          onClick={onClick}
          name={props.name}
          id={props.name}
          onChange={props.handleChange}
          checked={checked}
          required={props.required}
          sx={{
            "& .MuiButtonBase-root": { padding: "0px" },
            "& .MuiSvgIcon-root": { fontSize: 32 },
            "&.Mui-checked": {
              color: Colors.Primary,
            },
          }}
        />
        {props.helperTexts ? (
          props.helperTexts.map((helperText) => {
            if (helperText.type === "text") {
              return (
                <Typography
                  key={helperText.text}
                  display="inline"
                  sx={{
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.6)",
                  }}>
                  {helperText.text}
                </Typography>
              )
            } else if (helperText.type === "link") {
              return (
                <Typography
                  key={helperText.text}
                  onClick={onClickLink}
                  display="inline"
                  sx={{
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.6)",
                    cursor: "pointer",
                    textDecoration: "underline",
                    "&:hover": {
                      textDecoration: "none",
                    },
                  }}>
                  {helperText.text}
                </Typography>
              )
            } else {
              return <></>
            }
          })
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  )
}
