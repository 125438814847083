import {
  SelectIsError,
  SelectIsRiddleImageFileAvailable,
  SelectRiddleImageUrl,
  setRiddleImageFile,
} from "../riddleFormSlice"
import { FileInput } from "../../../app/utils/components/fileUpload"
import { RiddleFormText } from "../../../i18"
import { useTranslation } from "react-i18next"

export const RiddleImageInput = () => {
  const { t } = useTranslation()
  return (
    <FileInput
      errorSelector={SelectIsError}
      fileSelector={SelectIsRiddleImageFileAvailable}
      upload={setRiddleImageFile}
      urlSelector={SelectRiddleImageUrl}
      label={t(RiddleFormText.riddleImage)}
      infoText={t(RiddleFormText.riddleImageInfo)}
      required={true}
    />
  )
}
